import { Button } from "UIKit";
import { useTranslation } from "react-i18next";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import { XIcon } from "Assets/Icons";

interface VideoPlayerProps {
  url: string;
  title?: string;
  width?: string;
  height?: string;
  onLoad?: () => void;
  onError?: (e: Error) => void;
  closeVideo?: () => void;
  onVideoProgress?: ReactPlayerProps["onProgress"];
  isPlaying?: boolean;
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  url,
  title,
  width = "100%",
  height = "100",
  isPlaying,
  onLoad,
  onError,
  closeVideo,
  onVideoProgress,
}: VideoPlayerProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col">
      <div className="flex justify-center items-center">
        <ReactPlayer
          playing={isPlaying}
          controls
          url={url}
          width={width}
          height={height}
          stopOnUnmount
          onProgress={onVideoProgress}
          onLoad={onLoad}
          onStart={onLoad}
          onError={onError}
        />
      </div>

      {title || closeVideo ? (
        <div className="flex justify-between my-6 items-start">
          <span className="font-semibold text-sm lg:text-base w-1/2">{title}</span>
          {closeVideo ? (
            <Button onClick={closeVideo} color="grey" data-testid="hide-video">
              <span className="text-sm">{t("EdPortal_videoPlayer_button")}</span>
              <XIcon className="ml-2" height={16} width={16} />
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};
