import {
  ProcessingInstruction,
  makeProcessNode,
  makeShouldProcessNode,
} from "./makeHtmlToReactNodeParser";

export const makeDefaultEolasProcessingInstructions = () => {
  const eolasDefaultProcessingInstructions: ProcessingInstruction[] = [
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h1" }),
      processNode: makeProcessNode({ tagName: "h1", classNameToAdd: "text-2xl my-4" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h2" }),
      processNode: makeProcessNode({ tagName: "h2", classNameToAdd: "text-xl my-3" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h3" }),
      processNode: makeProcessNode({ tagName: "h3", classNameToAdd: "text-lg" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h4" }),
      processNode: makeProcessNode({ tagName: "h4", classNameToAdd: "text-lg font-semibold" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h5" }),
      processNode: makeProcessNode({ tagName: "h5", classNameToAdd: "text-base font-semibold" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "h6" }),
      processNode: makeProcessNode({ tagName: "h6", classNameToAdd: "text-sm font-semibold" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "ul" }),
      processNode: makeProcessNode({ tagName: "ul", classNameToAdd: "list-disc px-8 text-sm" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "p" }),
      processNode: makeProcessNode({ tagName: "p", classNameToAdd: "text-sm my-2 my-0.5" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "li" }),
      processNode: makeProcessNode({ tagName: "li", classNameToAdd: "text-sm my-0.5" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "table" }),
      processNode: makeProcessNode({ tagName: "table", classNameToAdd: "my-2" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "td" }),
      processNode: makeProcessNode({ tagName: "td", classNameToAdd: "text-sm my-0.5" }),
    },
    {
      shouldProcessNode: makeShouldProcessNode({ tagName: "a" }),
      processNode: makeProcessNode({
        tagName: "a",
        classNameToAdd: "text-sm text-blue-500 hover:text-blue-700 underline",
      }),
    },
  ];

  return eolasDefaultProcessingInstructions;
};
