import { mapToContentItem } from "../helpers";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

interface UseContentItem {
  mainSectionId: string;
  contentId: string;
  enabled?: boolean;
}
const useFetchContentItem = ({ contentId, enabled = true }: UseContentItem) => {
  const { file, isFetching } = useGetFile({ id: contentId, options: { isEnabled: enabled } });

  return {
    contentItem: file ? mapToContentItem(file) : undefined,
    isContentItemLoading: isFetching,
  };
};

export default useFetchContentItem;
