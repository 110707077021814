import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "UIKit";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import ContentItemTile from "../ContentItemTile/ContentItemTile";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { ContentItem, isFlashCardContentItem, SupportedFileType } from "@eolas-medical/core";
import { useShareFile } from "shared/hooks/useShareFile";
import { useRemoveFavouriteModal } from "Components/RemoveFavouriteModal/hooks/useRemoveFavouriteModal";
import { SelectFileToggleProps } from "UIKit/Button/SelectFileToggle";
import { observer } from "mobx-react-lite";
import { IsCopyableStatus } from "./types";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { useHandleClickContent } from "./hooks/useHandleClickContent";

interface ContentItemInstanceProps {
  contentItem: ContentItem;
  isAdminMode: boolean;
  flashcardsHaveSpecialties?: boolean;
  "data-testid"?: string;
  isDraggable?: boolean;
  isCopyableStatus?: IsCopyableStatus;
  isCopyable?: boolean;
  isSearchResult?: boolean;
}

const ContentItemInstance = observer(
  ({
    contentItem,
    isAdminMode,
    flashcardsHaveSpecialties = false,
    "data-testid": dataTestId,
    isDraggable = false,
    isSearchResult = false,
    ...rest
  }: ContentItemInstanceProps & Partial<SelectFileToggleProps>) => {
    const { flags } = useLaunchDarkly();
    const { t } = useTranslation();
    const [modal, setModal] = useState<React.ReactNode>(null);
    const handleModalChange = (modal: React.ReactNode) => {
      setModal(modal);
    };
    const { contentItemConfig, ...restContextRepositoryContext } = useContentRepositoryContext();

    /*
     * TODO: separate out this component from knowledge content and have it consume an EolasFile
     * Do NOT build anymore mapping EolasFile data via the mapToContentItem function
     * Any further functionality / new data should be derived from an EolasFile
     */

    const isSponsoredSlotItem =
      restContextRepositoryContext.contextDomain === "knowledge" &&
      (restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlots" ||
        restContextRepositoryContext.knowledgeContentDomain === "sponsoredSlotsContent");

    const { contextDomain } = restContextRepositoryContext;

    const { file: eolasFile } = useGetFile({
      id: contextDomain === "spaceOrg" ? contentItem.id : null,
    });

    const hideExpiryDate =
      (Boolean(flags[LDFlagNames.HIDE_EXPIRY_DATE_ON_GCR_TILE]) && !isAdminMode) ||
      contentItemConfig?.shouldHideExpiryDate;
    const expiryDate = hideExpiryDate ? undefined : contentItem.expiryDate;

    const {
      isFavouriteModalOpen,
      showRemoveFavouriteModal,
      closeFavouriteModal,
      removeFavouriteModal,
    } = useRemoveFavouriteModal();

    const { isShareFileEnabled, isShareModalOpen, closeShareModal, handleOnShareFile, shareModal } =
      useShareFile();

    const shouldShowShareButton = isShareFileEnabled && contentItemConfig?.shouldAllowSharing;

    const isModalOpen = !!modal || isShareModalOpen || isFavouriteModalOpen;

    const handleCloseModal = () => {
      if (isShareModalOpen) {
        closeShareModal();
        return;
      }
      if (isFavouriteModalOpen) {
        closeFavouriteModal();
        return;
      }
      setModal(null);
    };

    const { handleClickItem } = useHandleClickContent({ id: contentItem.id, isAdminMode });

    const isFlashcard = isFlashCardContentItem(contentItem);

    const contentItemFileType = useMemo<SupportedFileType>(() => {
      if (contentItem.media && typeof contentItem.media.type === "string") {
        return contentItem.media.type as SupportedFileType;
      }

      if (contentItem.type === "EOLAS_EDITOR") {
        return "eolas-editor";
      }

      if (isFlashcard) {
        return "flashcard";
      }

      // Default case, ensure "link" is part of SupportedFileType
      return "link";
    }, [contentItem, isFlashcard]);

    return (
      <>
        <Modal open={!!isModalOpen} onClose={handleCloseModal}>
          {modal || shareModal || removeFavouriteModal}
        </Modal>
        <div id={contentItem.id} data-testid="content-section-list-item">
          <ContentItemTile
            eolasFile={eolasFile}
            isSearchResult={isSearchResult}
            item={contentItem}
            isInAdminMode={isAdminMode}
            contentType={contentItemFileType}
            description={isFlashcard ? contentItem.description : null}
            date={isFlashcard ? contentItem.createdAt : expiryDate}
            dateLabel={isFlashcard ? t("general_date") : t("repository_item_expiry")}
            imageUrl={isFlashcard ? contentItem.url : null}
            onClick={() => handleClickItem(contentItem)}
            data-testid={dataTestId}
            isDraggable={isDraggable}
            flashcardsHaveSpecialties={flashcardsHaveSpecialties}
            onShare={shouldShowShareButton ? () => handleOnShareFile(contentItem) : undefined}
            showRemoveFavouriteModal={showRemoveFavouriteModal}
            shouldAllowFavourite={contentItemConfig?.shouldAllowFavourites}
            shouldTruncateDescription={!isSponsoredSlotItem}
            shouldShowDate={!isSponsoredSlotItem}
            handleModalChange={handleModalChange}
            handleCloseModal={handleCloseModal}
            {...rest}
          />
        </div>
      </>
    );
  },
);

export default ContentItemInstance;
