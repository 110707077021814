import React from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

export type TitleLevel = 1 | 2 | 3 | 4 | 5 | 5.5 | 6 | 7 | 8 | 9;

export interface TitleProps extends React.ComponentPropsWithoutRef<"h1"> {
  level: TitleLevel;
}

const titleStyles: Record<TitleLevel, string> = {
  "1": "text-7xl overflow-ellipsis", // Heading 1 in Figma
  "2": "text-6xl overflow-ellipsis", // Heading 2 in Figma
  "3": "text-5xl overflow-ellipsis font-medium", // Heading 3 in Figma
  "4": "text-4xl overflow-ellipsis font-medium", // Title 1 in Figma
  "5": "text-3xl overflow-ellipsis", // Title 2 in Figma
  "5.5": "text-2xl overflow-ellipsis font-semibold", // Main Section Tile titles
  "6": "text-lg md:text-2xl overflow-ellipsis", // Subtitle 1 in Figma
  "7": "text-xl overflow-ellipsis", // Subtitle 2 in Figma
  "8": "text-lg overflow-ellipsis", // Subtitle 3 in Figma
  "9": "text-base overflow-ellipsis", // Subtitle 4 in Figma
};

export const Title: React.FC<TitleProps> = ({ level, className, ...props }: TitleProps) => {
  const element = `h${Math.min(Math.floor(level), 6)}`;

  const titleClass = className ? tw(`${titleStyles[level]} ${className}`) : titleStyles[level];

  return React.createElement(element, { className: titleClass, ...props });
};
