import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { PageTitle, InnerPageWrapper } from "UIKit";
import { RouteComponentProps } from "react-router-dom";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import KnowledgeSectionList from "./components/KnowledgeSectionList/KnowledgeSectionList";
import useKnowledgeSections from "modules/mainSections/data/useKnowledgeSections";
import GridContainer from "UIKit/GridContainer/GridContainer";

const variants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

export const Knowledge: React.FC<RouteComponentProps> = observer(() => {
  const { t } = useTranslation();
  const { knowledgeSections } = useKnowledgeSections();

  return (
    <InnerPageWrapper>
      <div className="mt-8 mb-6 space-y-10">
        <PageTitle
          subTitle={t("pageSubTitle_the")}
          data-testid="knowledge-title"
          title={t("knowledge_title")}
        />
        <motion.div
          className="mt-8 w-full"
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.3 }}
        >
          <MasterSearch />
        </motion.div>
      </div>

      <GridContainer>
        <KnowledgeSectionList knowledgeSectionList={knowledgeSections} />
      </GridContainer>
    </InnerPageWrapper>
  );
});
