import { userStore } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

import { sendZapierRequest } from "shared/functions/sendZapierRequest";
import { webStore } from "Stores/WebStore";

const BASE_URL = "https://hooks.zapier.com/hooks/catch/19312552/2z1lihr/";
export const useRequestAnalytics = () => {
  const {
    isLoading,
    isError,
    isSuccess,
    mutate: requestAnalyticsZap,
  } = useMutation({
    mutationKey: ["requestAnalyticsZap"],
    mutationFn: ({
      spaceOrgId,
      spaceOrgName,
      orgAdminSpaceId,
    }: {
      spaceOrgId: string;
      spaceOrgName: string;
      orgAdminSpaceId?: string;
    }) =>
      sendZapierRequest({
        type: "POST",
        baseUrl: BASE_URL,
        body: {
          spaceOrgId,
          spaceOrgName,
          orgAdminSpaceId,
          userId: userStore.userID,
          email: userStore.userData.email,
        },
      }),
    onSuccess: (_, args) => {
      webStore.setRequestedAnalytics(args.spaceOrgId);
    },
  });

  return {
    requestAnalyticsZap,
    isLoading,
    isError,
    isSuccess,
  };
};
