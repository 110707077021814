import { Button, ButtonProps } from "./Button";
import { Text } from "../Typography/Text";

export const AddButton: React.FC<ButtonProps> = ({
  className = "",
  "data-testid": dataTestId = "add-new-subSection",
  children,
  ...props
}: ButtonProps) => {
  return (
    <Button
      size="lg"
      iconLeft="AddCircleIcon"
      className={`group sm:self-center ${className}`}
      data-testid={dataTestId}
      {...props}
    >
      <Text level={1} className="ml-2">
        {children}
      </Text>
    </Button>
  );
};
