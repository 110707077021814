import { isDrugPrep, makeControlledDrugLabel, GeneralPreparation } from "@eolas-medical/core";
import { removeTags } from "modules/bnf/helpers";
import { BnfPreparationStrings } from "./BnfPreparation.strings";
import { Text } from "UIKit";
import BnfPill from "../BnfPill/BnfPill";
import BnfContentElement from "../BnfContentElement/BnfContentElement";
import { Pack } from "./Pack";

const PACK_BASE_KEY = "eolas_pack";

const BnfPreparation = ({ prep }: { prep: GeneralPreparation }) => {
  const controlledDrugLabel = isDrugPrep(prep)
    ? makeControlledDrugLabel(prep.controlledDrugSchedule)
    : null;
  const prepName = removeTags(prep.name);
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-1">
        <Text level={2}>
          {prep.blackTriangle ? `${prepName} ${BnfPreparationStrings.blackTriangle}` : prepName}
        </Text>
        <Text level={2}>{prep.manufacturer}</Text>
        {isDrugPrep(prep) && prep.sugarFree ? (
          <BnfPill
            value={BnfPreparationStrings.sugarFree}
            className="bg-green-50 border border-green-600 text-green-600 py-0.5"
            valueClassname="text-2xs textx"
          />
        ) : null}
      </div>
      <div>
        {prep.activeIngredients.map((ingredient: string) => {
          return (
            <div key={ingredient}>
              <BnfContentElement htmlContent={ingredient} className="text-sm" />
            </div>
          );
        })}
        {prep.packs.map((pack, index) => {
          return (
            <div key={PACK_BASE_KEY + index}>
              <Pack pack={pack} controlledDrugLabel={controlledDrugLabel} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BnfPreparation;
