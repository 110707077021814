import { Button, Dropdown, InnerPageWrapper, Loader, Modal, NavButton, PageTitle } from "UIKit";

import { useParamsDecoded } from "Utilities/useParamsDecoded";
import { ContentItem, getObjectEntries, sectionStore } from "@eolas-medical/core";
import useFetchContentItem from "modules/contentRepository/data/useContentItem";

import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import { EolasEditorRenderer } from "./components/EolasEditorRenderer";
import { useContentVersion } from "shared/hooks/useContentVersion/useContentVersion";
import { FileViewerUnauthorizedError } from "../FileViewerUnauthorizedError/FileViewerUnauthorizedError";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { ContentItemParams } from "shared/pages/ContentRepository/context/types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";
import { useAdminOperationsContentItem } from "Hooks/useAdminOperationsContentItem";
import { useShareFile } from "shared/hooks/useShareFile";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ErrorComponent } from "../ErrorComponent";
import { VersionPreviewBanner } from "UIKit/VersionPreviewBanner/VersionPreviewBanner";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useKnowledgeContentInfoContext } from "Pages/Knowledge/pages/KnowledgeContent/context/knowledgeContentInfoContext";

type EolasEditorGenericViewerProps = {
  contentItem: ContentItem;
  backBehaviour?: EolasEditorViewerProps["backBehaviour"];
  shadowCopyContentId: string | null;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
  shouldShowAdminOptions?: boolean;
  versionNo?: number;
};

export const EolasEditorGenericViewer = ({
  contentItem,
  backBehaviour = "goBack",
  shadowCopyContentId,
  shouldShowBreadcrumbs,
  fileIdOfDraftFile,
  shouldShowAdminOptions,
  versionNo,
}: EolasEditorGenericViewerProps) => {
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });
  const { mainSectionId, parentId, fileName } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : fileIdOfDraftFile ?? contentItem?.id ?? "",
  });
  const { contextDomain } = useContentRepositoryContext();
  const { isUserAdmin } = useKnowledgeContentInfoContext();
  const { t } = useTranslation();
  const { handleOnShareFile, shareModal, closeShareModal, isShareModalOpen, isShareFileEnabled } =
    useShareFile();
  const [modal, setModal] = useState<React.ReactNode>(null);
  const handleModalChange = (modal: React.ReactNode) => {
    setModal(modal);
  };
  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
    }
    setModal(null);
  };
  const { handleActionMenuSelect, menuOptions } = useAdminOperationsContentItem({
    item: contentItem,
    contentType: "eolas-editor",
    isUnpublished: Boolean(contentItem?.isDraft),
    onShare: isShareFileEnabled && contentItem ? () => handleOnShareFile(contentItem) : undefined,
    isShadowCopy: shadowCopyContentId ? true : false,
    handleCloseModal,
    handleModalChange,
    isInViewer: true,
    isInAdminMode: contextDomain === "spaceOrg" ? sectionStore.isAdmin : isUserAdmin,
    isCopyableStatus: "fromTileDropDown",
  });
  return (
    <InnerPageWrapper className="pb-12 xl:pb-15">
      <Modal open={!!modal || !!shareModal} onClose={handleCloseModal}>
        {modal || shareModal}
      </Modal>
      {shouldShowBreadcrumbs ? (
        <FileBreadcrumbs
          shouldShowSpaceName={true}
          mainSectionId={mainSectionId}
          parentId={parentId}
          fileName={fileName}
        />
      ) : null}
      <div className="flex flex-col space-x-3 gap-2 items-center">
        <PageTitle title={contentItem?.name ?? ""} titleClass="text-2xl lg:text-4xl" />
        <div className="flex gap-2">
          <NavButton onClick={handleGoBack} className="mb-3 mt-5 lg:mt-0" />
          {shouldShowAdminOptions ? (
            <Dropdown
              dropdownToggle={<Button iconRight="CaretDownIcon">{t("admin_actions")}</Button>}
              options={menuOptions}
              onSelect={handleActionMenuSelect}
              overrideAbsoluteHorizontalNeutral
            />
          ) : null}
        </div>
      </div>
      {versionNo ? <VersionPreviewBanner versionNo={versionNo.toString()} /> : null}
      <EolasEditorRenderer contentItem={contentItem} />
    </InnerPageWrapper>
  );
};

type EolasEditorViewerProps = {
  mainSectionId?: string;
  contentItemId?: string;
  backBehaviour?: "goBack" | "goHome";
  shadowCopyContentId?: string;
};

const EolasEditorViewer = ({
  mainSectionId,
  contentItemId,
  backBehaviour = "goBack",
}: EolasEditorViewerProps) => {
  const {
    mainSectionId: mainSectionIdFromRoute,
    contentItemId: contentItemIdFromRoute,
    versionNo,
  } = useParamsDecoded<{
    mainSectionId: string;
    contentItemId: string;
    versionNo?: string;
  }>();

  const {
    fileIdOfDraftFile,
    shadowCopyContentId,
    shouldShowBreadcrumbs: showBreadcrumbsStr,
    shouldShowAdminOptions,
  } = useGetSearchParams({
    paramList: getObjectEntries(ContentItemParams).map(([, v]) => v),
  });

  const shouldShowBreadcrumbs = showBreadcrumbsStr === "true";

  return versionNo ? (
    <EolasEditorVersionViewer
      backBehaviour={backBehaviour}
      contentItemId={contentItemId ? contentItemId : contentItemIdFromRoute}
      versionNo={+versionNo}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
    />
  ) : (
    <EolasEditorFileViewer
      backBehaviour={backBehaviour}
      contentItemId={contentItemId ? contentItemId : contentItemIdFromRoute}
      mainSectionId={mainSectionId ? mainSectionId : mainSectionIdFromRoute}
      shadowCopyContentId={shadowCopyContentId}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      shouldShowAdminOptions={shouldShowAdminOptions === "true"}
    />
  );
};

const EolasEditorFileViewer = ({
  mainSectionId,
  contentItemId,
  backBehaviour,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
  shouldShowAdminOptions,
}: {
  mainSectionId: string;
  contentItemId: string;
  backBehaviour: "goBack" | "goHome";
  shadowCopyContentId: string | null;
  fileIdOfDraftFile: string | null;
  shouldShowBreadcrumbs?: boolean;
  shouldShowAdminOptions?: boolean;
}) => {
  const { contentItem, isContentItemLoading } = useFetchContentItem({
    mainSectionId,
    contentId: fileIdOfDraftFile || contentItemId,
  });

  const { data, isFetching } = useGetDraftFile(fileIdOfDraftFile);

  const draftFile = data?.draftFile ? mapToContentItem(data.draftFile) : null;

  const contentItemView = draftFile || contentItem;

  if (isContentItemLoading || isFetching) {
    return <Loader className="h-100vh" />;
  }
  if (!contentItemView) {
    return <ErrorComponent />;
  }

  return (
    <EolasEditorGenericViewer
      contentItem={contentItemView}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowAdminOptions={shouldShowAdminOptions}
    />
  );
};
const EolasEditorVersionViewer = ({
  contentItemId,
  backBehaviour,
  versionNo,
  shadowCopyContentId,
  shouldShowBreadcrumbs,
}: {
  contentItemId: string;
  backBehaviour: "goBack" | "goHome";
  versionNo: number;
  shadowCopyContentId: string | null;
  shouldShowBreadcrumbs?: boolean;
}) => {
  const { versionContentItem, isLoadingContentVersions, isUserAllowed } = useContentVersion({
    contentId: contentItemId,
    versionNo,
  });

  if (isLoadingContentVersions) return <Loader className="h-100vh" />;

  if (!isUserAllowed) return <FileViewerUnauthorizedError />;

  return (
    <EolasEditorGenericViewer
      contentItem={versionContentItem}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      fileIdOfDraftFile={null}
      versionNo={versionNo}
    />
  );
};

export default EolasEditorViewer;
