import { useState } from "react";
import {
  AnalyticsEvents,
  FlashCardContent,
  getObjectEntries,
  isFlashCardContentItem,
  parseFlashcardFileMetadata,
  sectionStore,
} from "@eolas-medical/core";
import { Button, Dropdown, InnerPageWrapper, Loader, Modal, NavButton } from "UIKit";
import { trackEvent } from "API/Analytics";
import { getImageUrl } from "Utilities";
import { useParamsDecoded } from "Utilities/useParamsDecoded";
import useFlashcardItem from "modules/contentRepository/data/useFlashcardItem";
import Flashcard from "UIKit/Flashcard/Flashcard";
import { makeHtmlToReactNodeParser } from "shared/functions/makeHtmlToReactNodeParser";
import { useDeeplinkingGoBackBehaviour } from "deeplinking/hooks/useDeeplinkingGoBackBehaviour";
import flashcardProcessingInstructions from "Pages/FileViewer/functions/flashcardProcessingInstructions";
import { useContentVersion } from "shared/hooks/useContentVersion/useContentVersion";
import { FileViewerUnauthorizedError } from "../FileViewerUnauthorizedError/FileViewerUnauthorizedError";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { ContentItemParams } from "shared/pages/ContentRepository/context/types";
import { useGetSearchParams } from "Utilities/useGetSearchParams";
import { useGetDraftFile } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/hooks/useGetDraftFile";
import { mapToContentItem } from "modules/contentRepository/helpers";
import { useConstructFileBreadcrumbProps } from "Pages/Spaces/hooks/useConstructFileBreadcrumbProps";
import { useAdminOperationsContentItem } from "Hooks/useAdminOperationsContentItem";
import { useTranslation } from "react-i18next";
import { useShareFile } from "shared/hooks/useShareFile";
import { ErrorComponent } from "../ErrorComponent";
import { VersionPreviewBanner } from "UIKit/VersionPreviewBanner/VersionPreviewBanner";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useKnowledgeContentInfoContext } from "Pages/Knowledge/pages/KnowledgeContent/context/knowledgeContentInfoContext";
import { useRunOnMountUnmount } from "Hooks";

const { convertHtmlToReactComponents } = makeHtmlToReactNodeParser({
  mode: "defaultEolasStyles",
  overrideProcessingInstructions: flashcardProcessingInstructions,
});

const FlashcardViewer = ({
  mainSectionId,
  flashcardId,
  backBehaviour = "goBack",
}: {
  mainSectionId?: string;
  flashcardId?: string;
  backBehaviour?: "goBack" | "goHome";
}) => {
  const {
    mainSectionId: mainSectionIdFromRoute,
    flashcardId: flashcardIdFromRoute,
    versionNo,
  } = useParamsDecoded<{
    mainSectionId: string;
    flashcardId: string;
    versionNo?: string;
  }>();

  const {
    fileIdOfDraftFile,
    shadowCopyContentId,
    shouldShowBreadcrumbs: showBreadcrumbsStr,
    shouldShowAdminOptions,
  } = useGetSearchParams({
    paramList: getObjectEntries(ContentItemParams).map(([, v]) => v),
  });

  const shouldShowBreadcrumbs = showBreadcrumbsStr === "true";

  return versionNo ? (
    <FlashcardVersionViewer
      backBehaviour={backBehaviour}
      flashcardId={flashcardId ? flashcardId : flashcardIdFromRoute}
      versionNo={+versionNo}
      shadowCopyContentId={shadowCopyContentId || undefined}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      shouldShowAdminOptions={false}
    />
  ) : (
    <FlashcardFileViewer
      backBehaviour={backBehaviour}
      flashcardId={flashcardId ? flashcardId : flashcardIdFromRoute}
      mainSectionId={mainSectionId ? mainSectionId : mainSectionIdFromRoute}
      shadowCopyContentId={shadowCopyContentId || undefined}
      fileIdOfDraftFile={fileIdOfDraftFile}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      shouldShowAdminOptions={shouldShowAdminOptions === "true"}
    />
  );
};

const FlashcardFileViewer = ({
  mainSectionId,
  flashcardId,
  backBehaviour,
  shadowCopyContentId,
  fileIdOfDraftFile,
  shouldShowBreadcrumbs,
  shouldShowAdminOptions,
}: {
  mainSectionId: string;
  flashcardId: string;
  backBehaviour: "goBack" | "goHome";
  shadowCopyContentId?: string;
  shouldShowBreadcrumbs?: boolean;
  shouldShowAdminOptions?: boolean;
  fileIdOfDraftFile: string | null;
}) => {
  const { flashcard, flashcardLoading } = useFlashcardItem({
    mainSectionId,
    flashcardId: fileIdOfDraftFile || flashcardId,
  });

  const { data, isFetching } = useGetDraftFile(fileIdOfDraftFile);

  let draftFlashcard: FlashCardContent | null = null;

  if (data?.draftFile) {
    const mapped = mapToContentItem(data.draftFile);
    if (isFlashCardContentItem(mapped)) {
      draftFlashcard = mapped;
    }
  }

  const flashCardToView = draftFlashcard ?? flashcard;
  if (!flashCardToView) {
    return <ErrorComponent />;
  }

  return (
    <FlashcardViewerInternal
      flashcard={flashCardToView}
      flashcardLoading={flashcardLoading || isFetching}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      shouldShowAdminOptions={shouldShowAdminOptions}
      fileIdOfDraftFile={fileIdOfDraftFile}
    />
  );
};

const FlashcardVersionViewer = ({
  flashcardId,
  versionNo,
  backBehaviour,
  shadowCopyContentId,
  shouldShowBreadcrumbs,
  shouldShowAdminOptions,
}: {
  flashcardId: string;
  versionNo: number;
  backBehaviour: "goBack" | "goHome";
  shadowCopyContentId?: string;
  shouldShowBreadcrumbs?: boolean;
  shouldShowAdminOptions?: boolean;
}) => {
  const { versionContentItem, isLoadingContentVersions, isUserAllowed } = useContentVersion({
    contentId: flashcardId,
    versionNo,
  });

  if (isLoadingContentVersions) return <Loader className="h-100vh" />;

  if (!isUserAllowed) return <FileViewerUnauthorizedError />;

  if (!isFlashCardContentItem(versionContentItem)) {
    throw Error(`Version ${versionNo} of file with id ${flashcardId} is not a flashcard file`);
  }

  return (
    <FlashcardViewerInternal
      flashcard={versionContentItem}
      flashcardLoading={isLoadingContentVersions}
      backBehaviour={backBehaviour}
      shadowCopyContentId={shadowCopyContentId}
      shouldShowBreadcrumbs={shouldShowBreadcrumbs}
      fileIdOfDraftFile={null}
      shouldShowAdminOptions={shouldShowAdminOptions}
      versionNo={versionNo}
    />
  );
};

const FlashcardViewerInternal = ({
  flashcard,
  flashcardLoading,
  backBehaviour,
  shadowCopyContentId,
  shouldShowBreadcrumbs,
  shouldShowAdminOptions,
  fileIdOfDraftFile,
  versionNo,
}: {
  flashcard: FlashCardContent;
  flashcardLoading: boolean;
  backBehaviour: "goBack" | "goHome";
  fileIdOfDraftFile: string | null;
  shadowCopyContentId?: string;
  shouldShowBreadcrumbs?: boolean;
  shouldShowAdminOptions?: boolean;
  versionNo?: number;
}) => {
  const { handleOnShareFile, shareModal, closeShareModal, isShareModalOpen, isShareFileEnabled } =
    useShareFile();
  const { handleGoBack } = useDeeplinkingGoBackBehaviour({ backBehaviour });
  const [modal, setModal] = useState<React.ReactNode>(null);
  const handleModalChange = (modal: React.ReactNode) => {
    setModal(modal);
  };
  const { contextDomain } = useContentRepositoryContext();
  const { isUserAdmin } = useKnowledgeContentInfoContext();
  const handleCloseModal = () => {
    if (isShareModalOpen) {
      closeShareModal();
    }
    setModal(null);
  };
  const flashcardMetadata = flashcard?.metadata
    ? parseFlashcardFileMetadata(flashcard?.metadata)
    : undefined;

  const parsedFlashcardContent = flashcardMetadata?.flashcardContent
    ? convertHtmlToReactComponents(flashcardMetadata.flashcardContent)
    : null;

  useRunOnMountUnmount({
    onMount: () => {
      const mainSectionType = flashcard.mainSectionId
        ? sectionStore.getMainSectionTypeFromMainSectionID(flashcard.mainSectionId)
        : null;

      const mainSectionIdentity = flashcard.mainSectionId
        ? sectionStore.getMainSectionIdentityByMainSectionId(flashcard.mainSectionId)
        : null;

      // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
      const idForTracking = mainSectionType ?? mainSectionIdentity;

      if (idForTracking) {
        trackEvent(AnalyticsEvents.OPEN_FILE, {
          mainSectionId: idForTracking,
          fileId: flashcard.id,
          fileType: flashcard.type || "",
          fileName: flashcard.name,
          sectionId: flashcard.parentId,
        });
      }
    },
  });

  const { t } = useTranslation();
  const { mainSectionId, parentId } = useConstructFileBreadcrumbProps({
    id: shadowCopyContentId ? shadowCopyContentId : fileIdOfDraftFile ?? flashcard?.id ?? "",
  });

  const { handleActionMenuSelect, menuOptions } = useAdminOperationsContentItem({
    flashcardsHaveSpecialties: Boolean(flashcard?.specialty?.length),
    item: flashcard,
    contentType: "flashcard",
    isUnpublished: Boolean(flashcard?.isDraft),
    handleCloseModal,
    handleModalChange,
    onShare: isShareFileEnabled && flashcard ? () => handleOnShareFile(flashcard) : undefined,
    isShadowCopy: shadowCopyContentId ? true : false,
    isInViewer: true,
    isInAdminMode: contextDomain === "spaceOrg" ? sectionStore.isAdmin : isUserAdmin,
    isCopyableStatus: "fromTileDropDown",
  });

  return (
    <InnerPageWrapper>
      <Modal open={!!modal || !!shareModal} onClose={handleCloseModal}>
        {modal || shareModal}
      </Modal>
      <div className="py-1">
        <div className="flex flex-col justify-center gap-3">
          {!flashcardLoading && shouldShowBreadcrumbs ? (
            <FileBreadcrumbs
              shouldShowSpaceName={true}
              mainSectionId={mainSectionId}
              parentId={parentId}
              fileName={flashcard?.name}
            />
          ) : null}
          <div className="flex self-center items-center gap-2">
            <NavButton onClick={handleGoBack} />
            {shouldShowAdminOptions ? (
              <Dropdown
                dropdownToggle={<Button iconRight="CaretDownIcon">{t("admin_actions")}</Button>}
                options={menuOptions}
                onSelect={handleActionMenuSelect}
                overrideAbsoluteHorizontalNeutral
              />
            ) : null}
          </div>
        </div>
      </div>
      {versionNo ? <VersionPreviewBanner versionNo={versionNo.toString()} /> : null}

      {flashcardLoading ? (
        <Loader className="relative top-32 bg-transparent" />
      ) : (
        <Flashcard
          imgSrc={getImageUrl(flashcard?.url ?? "")}
          name={flashcard?.name ?? ""}
          description={flashcard?.description || undefined}
          createdAt={flashcard?.createdAt ?? ""}
          content={parsedFlashcardContent}
        />
      )}
    </InnerPageWrapper>
  );
};

export default FlashcardViewer;
