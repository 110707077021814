import { Route, Switch, useRouteMatch } from "react-router-dom";
import useContentRepository from "modules/contentRepository/data/useContentRepository";
import ManageExpiredItems from "./pages/ManageExpiredItems/ManageExpiredItems";
import SpaceContentItems from "./pages/SpaceContentItems/SpaceContentItems";
import SpaceContentRepositoryProvider from "./SpaceContentRepositoryProvider";
import useSpaceContentRepositoryParams from "./hooks/useSpaceContentRepositoryParams";
import { sectionStore } from "@eolas-medical/core";
import { SpaceContentSections } from "./pages/SpaceContentSections/SpaceContentSections";
import { observer } from "mobx-react-lite";
import { spaceContentRepoAnalyticsCallbacks } from "./config/analyticsCallbacks";

const SubsectionComponent = observer(() => {
  const { subSectionId } = useSpaceContentRepositoryParams();

  const section = sectionStore.getSection(subSectionId);

  return section.childrenType === "section" ? <SpaceContentSections /> : <SpaceContentItems />;
});

const SpaceContentRepository = () => {
  const { path } = useRouteMatch();
  const { contentRepositoryId } = useSpaceContentRepositoryParams();

  const { contentRepository } = useContentRepository(contentRepositoryId);

  if (contentRepository.childrenType === "section") {
    return (
      <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
        <Switch>
          <Route
            exact
            path={`${path}`}
            render={() => (
              <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
                <SpaceContentSections />
              </SpaceContentRepositoryProvider>
            )}
          />
          <Route
            exact
            path={`${path}/expired-items`}
            render={() => (
              <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
                <ManageExpiredItems contentRepositoryId={contentRepositoryId} />
              </SpaceContentRepositoryProvider>
            )}
          />
          <Route
            exact
            path={`${path}/:subSectionIds*`}
            render={() => (
              <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
                <SubsectionComponent />
              </SpaceContentRepositoryProvider>
            )}
          />
        </Switch>
      </SpaceContentRepositoryProvider>
    );
  }

  return (
    <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
              <SpaceContentItems />
            </SpaceContentRepositoryProvider>
          )}
        />
        <Route
          exact
          path={`${path}/expired-items`}
          render={() => (
            <SpaceContentRepositoryProvider {...spaceContentRepoAnalyticsCallbacks}>
              <ManageExpiredItems contentRepositoryId={contentRepositoryId} />
            </SpaceContentRepositoryProvider>
          )}
        />
      </Switch>
    </SpaceContentRepositoryProvider>
  );
};

export default SpaceContentRepository;
