import {
  AuthErrorCodes,
  AuthFunctionNames,
  handleAuthErrors,
  hasStringProp,
} from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { login } from "Pages/Authentication/auth.actions";
import { errorStore } from "Stores/ErrorStore";
import { LDFlagNames } from "Utilities/types";
import { useState } from "react";

interface LoginUser {
  email: string;
  password: string;
  isFederated?: boolean;
  isFederatedFeatureEnabled?: boolean;
}
const useSignInUser = ({
  onSuccess = () => {
    return;
  },
  onError = () => {
    return;
  },
} = {}) => {
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signInError, setSignInError] = useState<string>();
  const { flags } = useLaunchDarkly();

  const isFeatureEnabled = flags[LDFlagNames.FEDERATED_USERS];

  const signInUser = async ({ email, password }: LoginUser) => {
    setIsSigningIn(true);
    try {
      await login({
        email,
        password,
        isFederated: false,
        isFederatedFeatureEnabled: isFeatureEnabled,
      });
      onSuccess?.();
    } catch (error: unknown) {
      let errorMessageForDisplay = "";
      if (
        hasStringProp(error, "code") &&
        error.code === AuthErrorCodes.federated_domain_on_enterprise_login_error
      ) {
        errorStore.captureError({
          error: error instanceof Error ? error : "Error signing in",
          source: "user",
          authFunctionName: AuthFunctionNames.login,
        });
        try {
          await login({
            email,
            password,
            isFederated: true,
            isFederatedFeatureEnabled: isFeatureEnabled,
          });
        } catch (secondError: unknown) {
          setIsSigningIn(false);
          ({ errorMessageForDisplay } = handleAuthErrors(secondError, AuthFunctionNames.login));
          setSignInError(errorMessageForDisplay);
        }
      } else {
        setIsSigningIn(false);
        const errorMessage = errorStore.captureError({
          error: error instanceof Error ? error : "Error signing in",
          source: "user",
          authFunctionName: AuthFunctionNames.login,
        });
        setSignInError(errorMessage);
        onError?.();
      }
    }
  };

  return {
    signInUser,
    isSigningIn,
    signInError,
  };
};

export default useSignInUser;
