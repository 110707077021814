import { observer } from "mobx-react-lite";
import { LoadingOverlay } from "UIKit";
import { useCheckInvitation } from "../hooks/useCheckInvitation";
import { AutoAccessBaseModal } from "../modals/AutoAccessBaseModal";
import BackgroundCover from "Components/PageContainer/components/BackgroundCover/BackgroundCover";
import spaceBgLines from "Assets/Img/space-background-lines.svg";

export const CheckInvitation = observer(() => {
  const { isLoggedIn, state } = useCheckInvitation();

  if (!state || isLoggedIn) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <BackgroundCover overrideImage={spaceBgLines} />
      <div className="flex justify-center items-center h-screen">
        <AutoAccessBaseModal
          linkType="invite"
          modalState={state.outcome}
          handleCloseModal={state.handleCloseModal}
        />
      </div>
    </>
  );
});
