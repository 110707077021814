import {
  AnalyticsEvents,
  DepartmentOrganisationSectionActivityPayload,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { ContentSection } from "modules/contentRepository/types";

export const spaceContentRepoAnalyticsCallbacks: {
  onClickContentSection: (section: ContentSection) => void;
} = {
  onClickContentSection: (section) => {
    trackEvent<DepartmentOrganisationSectionActivityPayload>(AnalyticsEvents.OPEN_SECTION, {
      sectionId: section.id,
      name: section.name || "",
      isAdminMode: sectionStore.isAdmin,
    });
  },
};
