import React from "react";
import { UserFeedbackIcon } from "Assets";
import { Button, InnerModalWrapper, ModalBody, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";

type OptionalAssignUserModalProps = {
  onAssign: () => void;
  onSubmit: () => void;
  isLoading: boolean;
};

function OptionalAssignAssessorModal({
  onAssign,
  onSubmit,
  isLoading,
}: OptionalAssignUserModalProps) {
  const { t } = useTranslation();
  return (
    <>
      <InnerModalWrapper>
        <ModalBody>
          <div data-testid="optional-user-modal-body" className="flex flex-col items-center">
            <UserFeedbackIcon className="mb-6" />
            <Title level={5}> {t("portfolio_optional_assign_user_title")}</Title>
            <Text level={2} className="text-center text-sm text-grey-700 mt-3 mb-6">
              {t("portfolio_optional_assign_user_subtitle")}
            </Text>
            <Button
              data-testid="assign-user-button"
              className="w-full rounded-xl mb-4"
              onClick={onAssign}
              disabled={isLoading}
            >
              {t("portfolio_optional_assign_user_assign_feedback_button")}
            </Button>

            <Button
              data-testid="complete-event-button"
              className="w-full rounded-xl"
              onClick={onSubmit}
              variant="outline"
              color="grey"
              isLoading={isLoading}
            >
              {t("portfolio_optional_assign_user_complete_button")}
            </Button>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    </>
  );
}

export default OptionalAssignAssessorModal;
