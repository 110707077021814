import React from "react";
import { useTranslation } from "react-i18next";

import { AnalyticsEvents, userStore } from "@eolas-medical/core";

import { AdminIcon, UserIcon, SearchIcon } from "Assets";
import { Text, Input, Title, Button, Pagination, Modal } from "UIKit";

import { useUserList } from "./useUserList";
import { UserDetailsModal } from "./UserDetailsModal";
import { DeactivateUserModal } from "./DeactivateUserModal";
import { useQueryClient } from "@tanstack/react-query";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { SpaceUser } from "clients/departments/types";
import { trackEvent } from "API/Analytics";
import { useExportUserCsv } from "../../hooks/useExportUserCsv";
import { EolasCheckBox } from "UIKit/EolasCheckBox/EolasCheckBox";

interface UserListProps {
  users: SpaceUser[];
}

export const UserList = ({ users }: UserListProps) => {
  const { t } = useTranslation();
  const [modal, setModal] = React.useState<React.ReactNode | null>(null);
  const { appUserData: loggedInAppUser } = userStore;
  const { spaceId, relevantSpace } = useGetRelevantSpaceDetails();
  const {
    usersSlice,
    searchInput,
    currentPage,
    hasMorePages,
    tablePageDisplay,
    onInputChange,
    setCurrentPage,
  } = useUserList(users);
  const queryClient = useQueryClient();

  const onDeleteUser = (appUser: SpaceUser) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setModal(
      <DeactivateUserModal
        appUser={appUser}
        onCloseModal={() => {
          setModal(null);
          queryClient.refetchQueries(spacesKeys.usersList(spaceId ?? ""));
        }}
      />,
    );
  };
  const { handleExportUsers } = useExportUserCsv();

  const showUserDetails = (appUser: SpaceUser) => () => {
    setModal(<UserDetailsModal appUser={appUser} onCloseModal={() => setModal(null)} />);
  };
  const [isShowAdminsOnly, setIsShowAdminsOnly] = React.useState(false);
  const handleToggle = () => {
    setIsShowAdminsOnly(!isShowAdminsOnly);
  };

  const filteredUsers = isShowAdminsOnly
    ? usersSlice.filter((user) => user.accessLevel === "admin")
    : usersSlice;
  return (
    <>
      <div className="flex flex-col space-y-4">
        <Title level={6}>{t("general_users")}</Title>

        <Input
          size="lg"
          value={searchInput}
          onChange={onInputChange}
          iconLeft={<SearchIcon />}
          className="bg-white input"
          placeholder={t("SearchBarPlaceholder")}
        />
        <div className="flex justify-between items-center">
          {users.length ? (
            <Button
              size="sm"
              iconLeft="ExportIcon"
              onClick={() => {
                handleExportUsers(users, relevantSpace?.name ?? "");
                trackEvent(AnalyticsEvents.DOWNLOAD_USER_CSV);
              }}
            >
              <Text level={1} className="ml-2 font-bold">
                {t("export_users")}
              </Text>
            </Button>
          ) : null}
          <div className="flex items-center">
            <EolasCheckBox isChecked={isShowAdminsOnly} onToggle={handleToggle} />
            <Text level={1} className="ml-2 font-bold">
              {t("display_admins_only")}
            </Text>
          </div>
        </div>

        <div
          className={
            "flex flex-col rounded-md bg-white divide-y divide-grey-300 border border-grey-300"
          }
        >
          {filteredUsers.map((user) => {
            const shouldDisableRemoveUserButton =
              user.userID === loggedInAppUser.userID ||
              (user.user?.email?.includes("autologin.com") ?? false);

            return (
              <div
                key={user.id}
                onClick={showUserDetails(user)}
                className={`
                  flex items-center hover:bg-grey-200 p-4 cursor-pointer
                `}
              >
                <UserIcon className="w-8 h-8 mr-4" style={{ minWidth: 32, minHeight: 32 }} />

                <div className="flex flex-col lg:items-center lg:flex-row w-full gap-4">
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <Text level={1} className="font-semibold line-clamp-1">
                      {`${user.user?.given_name} ${user.user?.family_name}`.trim() || "Not set"}
                    </Text>

                    <Text level={2} className="font-semibold text-grey-700 line-clamp-1">
                      {user.user?.email}
                    </Text>
                  </div>

                  <div className="flex lg:items-center justify-end">
                    <div
                      className={`
                        flex items-center bg-white rounded-full p-2 mr-2 h-8
                        ${user.accessLevel === "admin" ? "visible" : "invisible"}
                      `}
                    >
                      <AdminIcon className="w-4 h-4 mr-2" />
                      <Text level={2}>{t("manageUsers_user_list_admin_label")}</Text>
                    </div>

                    <Button
                      size="xs"
                      color="grey"
                      disabled={shouldDisableRemoveUserButton}
                      variant="outline"
                      className="rounded-full"
                      onClick={onDeleteUser(user)}
                    >
                      {t("manageUsers_user_list_remove_label")}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Pagination
          config={tablePageDisplay}
          currentPage={currentPage}
          hasMorePages={hasMorePages}
          changePage={setCurrentPage}
          title="manageUsers_tablePages_text"
        />
      </div>

      <Modal open={!!modal} onClose={() => setModal(null)}>
        {modal}
      </Modal>
    </>
  );
};
