import { CloseIcon } from "Assets";
import { CircleIcon, Text } from "UIKit";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import TimeAgo from "timeago-react";

interface NotificationCardProps {
  id: string;
  icon: React.ReactNode;
  title: string;
  body: string;
  createdAt: string;
  pillBgColor?: string;
  pillTextColor?: string;
  pillIcon?: React.ReactNode;
  sectionName?: string;
  subSectionName?: string;
  showBlueCircle?: boolean;
  onClickAnchor: (id: string) => void;
}

const NotificationCard = ({
  id,
  icon,
  title,
  body,
  createdAt,
  pillBgColor,
  pillTextColor,
  pillIcon,
  sectionName,
  subSectionName,
  showBlueCircle = false,
  onClickAnchor,
}: NotificationCardProps) => {
  const handleClickAnchor = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClickAnchor(id);
  };

  const containerClass = "grid grid-cols-8 grid-row-2";

  const NotReadIcon = () => <div className="w-3 h-3 rounded-full bg-primary absolute" />;

  return (
    <div
      key={id}
      className={`bg-white p-4.5 cursor-pointer border border-grey-300 hover:bg-grey-100
        ${containerClass} rounded-lg`}
      data-testid="notification-center-card"
    >
      <div className="col-start-1 col-end-2">
        {showBlueCircle ? (
          <div className="relative" style={{ top: "-16px", left: "-16px" }}>
            <NotReadIcon />
          </div>
        ) : null}
        <CircleIcon
          icon={icon}
          height={"h-10 sm:h-12"}
          width={"w-10 sm:w-12"}
          backgroundColor={pillBgColor}
        />
      </div>

      <div className="col-start-2 col-end-8 ml-4 flex flex-col space-y-2 max-w-2xl">
        <Text level={2}>{title}</Text>
        <Text level={2}>{body}</Text>
      </div>

      <div className="row-start-2 col-start-2 col-end-9 flex justify-end items-center ml-4">
        <div className="flex flex-1 flex-wrap items-center">
          {sectionName ? (
            <Pill
              value={sectionName}
              className={`bg-${pillBgColor} text-${pillTextColor} h-6 text-sm mr-1`}
              size={Sizes.MEDIUM}
              icon={pillIcon}
            />
          ) : null}
          {subSectionName ? (
            <Pill
              value={subSectionName}
              className={`bg-${pillBgColor} text-${pillTextColor} h-6 text-sm my-2`}
              size={Sizes.MEDIUM}
            />
          ) : null}
          <Text level={3} className=" text-grey-600 flex-grow text-right">
            <TimeAgo datetime={createdAt} />
          </Text>
        </div>
      </div>

      <div
        className="col-start-8"
        data-testid="notification-card-anchor"
        onClick={handleClickAnchor}
      >
        <CloseIcon className="w-4 h-4 text-grey-600 float-right" />
      </div>
    </div>
  );
};

export default NotificationCard;
