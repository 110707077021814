import { useTranslation } from "react-i18next";
import { Button, FormError, InnerModalWrapper } from "UIKit";

import { appConfig } from "AppTypeConfig";

interface TnCModalProps {
  onSubmit(): void;
  isLoading: boolean;
  error?: string;
  termsUpdated: boolean | null;
}

export const TnCModal: React.FC<TnCModalProps> = ({
  onSubmit,
  isLoading,
  error,
  termsUpdated,
}: TnCModalProps) => {
  const { t } = useTranslation();

  const handleUpdateCompletedTour = () => {
    onSubmit();
  };

  return (
    <InnerModalWrapper>
      <div className="overflow-y-auto" style={{ maxHeight: "80vh" }}>
        <div className="flex flex-col py-8 px-4 sm:px-20 space-y-6">
          <p data-testid="welcomeModalTitle" className="text-center text-lg">
            {termsUpdated
              ? t("departmentTour_TC_modal_title_update")
              : t("departmentTour_TC_modal_title")}
          </p>

          <span>
            {termsUpdated
              ? t("departmentTour_TC_modal_description_updated")
              : t("departmentTour_TC_modal_description")}
            <span className="whitespace-pre-wrap">{t("departmentTour_TC_modal_popups_bold")}</span>
            <span className="whitespace-pre-wrap">{t("departmentTour_TC_modal_popups_desc")}</span>
          </span>

          <div className="flex flex-col space-y-2">
            <p className="mb-4">{t("departmentTour_TC_modal_our_policies")}</p>
            <a
              className="text-blue-500 hover:underline font-semibold"
              href={appConfig.termlyTermsOfService}
              target="_blank"
              rel="noreferrer"
            >
              {t("departmentTour_TC_confirmTC")}
            </a>
            <a
              className="text-blue-500 hover:underline font-semibold"
              href={appConfig.termlyPrivacyPolicy}
              target="_blank"
              rel="noreferrer"
            >
              {t("departmentTour_TC_confirmPrivacyPolicy")}
            </a>
            <a
              className="text-blue-500 hover:underline font-semibold"
              href={appConfig.termlyDisclaimer}
              target="_blank"
              rel="noreferrer"
            >
              {t("departmentTour_TC_confirmDisclaimer")}
            </a>
          </div>
          <Button
            onClick={handleUpdateCompletedTour}
            color="blue"
            type="submit"
            isLoading={isLoading}
            data-testid="TnCModalSubmit"
            className="self-center w-full sm:w-1/3 "
          >
            {t("departmentTour_TC_button")}
          </Button>
          <FormError error={error} />
        </div>
      </div>
    </InnerModalWrapper>
  );
};
