import { useTranslation } from "react-i18next";
import { Text } from "UIKit/Typography";

export const VersionPreviewBanner = ({ versionNo }: { versionNo: string }) => {
  const { t } = useTranslation();
  return (
    <div className="text-center bg-green-100">
      <Text level={2} className="text-green-700">
        {t("viewing_version_history", { versionNo: versionNo.toString() })}
      </Text>
    </div>
  );
};
