import { eolasPhone, eolasPill, spaceBackground } from "Assets";
import React from "react";
import { CircleIcon } from "UIKit";
import CarouselModal from "UIKit/CarouselModal/CarouselModal";
import { useTranslation } from "react-i18next";
import PostCognitoStepItem from "./components/PostCognitoStepItem/PostCognitoStepItem";
import usePostCognitoSteps from "./hooks/usePostCognitoSteps";

const PostCognitoModal = ({ onComplete }: { onComplete: () => void }) => {
  const { t } = useTranslation();

  const { postCognitoSteps } = usePostCognitoSteps();

  const baseStep = (
    <>
      <div className="absolute top-0 left-1/2 transform -translate-x-1/2">
        <img src={eolasPill} alt="" />
      </div>

      <div className="absolute top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-center  ">
        <div className="text-2xl">{t("post_cognito_welcome_title")}</div>
        <div className="font-extrabold text-4xl">{t("post_cognito_eolas_medical_title")}</div>
      </div>
      <div className="cursor-pointer relative">
        <div className="flex flex-col justify-center items-center">
          <img
            src={spaceBackground}
            className="h-72 right-0 top-0 left-0 z-[-1]"
            style={{
              borderBottomLeftRadius: "50% 15%",
              borderBottomRightRadius: "50% 15%",
            }}
            alt=""
          />
          <img
            src={eolasPhone}
            className="relative z-10 w-64 sm:w-80 top-1/2 h-60 object-contain"
            alt=""
          />
        </div>
      </div>
    </>
  );

  return (
    <CarouselModal onComplete={onComplete}>
      <div>{baseStep}</div>
      {postCognitoSteps.map((step, index) => (
        <div key={index}>
          <div className="relative mb-4">
            <PostCognitoStepItem
              backgroundImg={step.backgroundImg}
              phoneImg={step.phoneImg}
              title={step.title}
              content={step.content.map((item, index) => (
                <div key={index} className="flex gap-3 items-center mt-2">
                  <div>
                    <CircleIcon
                      width="w-12"
                      height="h-12"
                      icon={item.icon}
                      className="rounded-md"
                      backgroundColor="grey"
                    />
                  </div>
                  <div className="text-gray-900 text-sm sm:text-md font-semibold">{item.text}</div>
                </div>
              ))}
            />
          </div>
        </div>
      ))}
    </CarouselModal>
  );
};

export default PostCognitoModal;
