import { FeedbackForm, FormElement, FormElementType } from "modules/portfolio/types";
import { Title, Text } from "UIKit";
import { useTranslation } from "react-i18next";
import { MAX_FEEDBACK_VALUE } from "Pages/MeTab/pages/Portfolio/constants";
import { isDateValue } from "Pages/MeTab/pages/Portfolio/helpers";
import { isString } from "@eolas-medical/core";

interface FeedbackFormProps {
  feedbackForm?: FeedbackForm;
}

const CompletedFeedback = ({ feedbackForm }: FeedbackFormProps) => {
  const { t } = useTranslation();

  if (!feedbackForm) return null;

  const { formElements } = feedbackForm;

  const renderFormElement = (element: FormElement) => {
    const { type } = element;

    switch (type) {
      case FormElementType.NUMERIC:
        return (
          <div key={element.fieldName} className="flex flex-1 justify-between">
            <Text level={1}>{element.componentConfig.title}</Text>
            <Title level={8} className="text-blue-500">
              {isString(element.value) ? element.value : "-"} / {MAX_FEEDBACK_VALUE}
            </Title>
          </div>
        );
      default:
        return (
          <div key={element.fieldName} className="flex flex-col space-y-2">
            <Title level={7}>{element.componentConfig.title}</Title>
            <Text level={1}>
              {isDateValue(element.value) ? element.value.toString() : element.value}
            </Text>
          </div>
        );
    }
  };

  return (
    <div className="rounded-xl bg-white p-8" data-testid="completed-feedback-body">
      <div className="mb-4">
        <Title level={6}>{t("portfolio_completed_feedback_title")}</Title>
      </div>
      <div className="flex flex-col space-y-4">
        {formElements.map((el) => renderFormElement(el))}
      </div>
    </div>
  );
};

export default CompletedFeedback;
