import { EolasFile } from "@eolas-medical/core";
import {
  AddFileCheckIcon,
  ClockIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  EditMonoIcon,
  MoveIcon,
  RedirectIcon,
  SendOutlineIcon,
  UnpublishIcon,
} from "Assets";
import { useEolasNavigation } from "Components/Navigation/hooks";
import { useLaunchDarkly } from "Contexts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DropdownOption } from "UIKit";
import { AdminFileTile } from "UIKit/EolasFileTile/EolasFileTile.types";
import { Action, LDFlagNames } from "Utilities/types";

export type UseAdminOperationsArgs = {
  isUnpublished: boolean;
  eolasFile: EolasFile | null;
  onShare?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onShowVersions?: () => void;
  onDownload?: () => void;
  onPublishDraft?: () => void;
  onCopy?: () => void;
  onMove?: () => void;
  onGoToOriginal?: () => void;
  onUnpublish?: () => void;
  isShadowCopy: boolean;
  isInViewer: boolean;
  isInAdminMode: boolean;
};
const ICON_SIZE = 16;
export const useAdminOperations = ({
  onEdit,
  eolasFile,
  isInAdminMode,
  isUnpublished,
  onDelete,
  onDownload,
  onPublishDraft,
  onCopy,
  onMove,
  onGoToOriginal,
  onShowVersions,
  onShare,
  onUnpublish,
  isShadowCopy,
  isInViewer,
}: UseAdminOperationsArgs) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  let status: AdminFileTile["status"] = isUnpublished ? "unpublished" : "published";
  if (eolasFile && eolasFile.hasDrafts) {
    status = "parallelDraft";
  }
  const { activeTab } = useEolasNavigation();

  const menuOptions = useMemo(() => {
    if (!isInAdminMode) return [];
    const options: DropdownOption[] = [];

    if (onEdit) {
      options.push({
        label:
          status === "parallelDraft" || status === "unpublished"
            ? t("content_item_edit_draft")
            : Action.EDIT,
        value: "edit",
        icon: <EditMonoIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isShadowCopy,
        disabledReason: t("content_item_shadow_copy_disabled_edit_tooltip"),
      });
    }

    if (onShare) {
      options.push({
        label: t("component_eolasVersatileList_create_link"),
        value: "share",
        icon: <SendOutlineIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: status === "unpublished",
        disabledReason: t("content_item_disabled_share_tooltip"),
      });
    }

    if (status === "unpublished") {
      options.push({
        label: t("content_item_publish"),
        value: "publish",
        icon: <AddFileCheckIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (onCopy) {
      options.push({
        label: t("component_eolasVersatileList_create_shortcut"),
        value: "copy",
        icon: <CopyIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isShadowCopy || status === "unpublished",
        disabledReason: t("content_item_shadow_copy_disabled_copy_tooltip"),
      });
    }

    if (onMove) {
      options.push({
        label: t("component_eolasVersatileList_move_to"),
        value: "move",
        icon: <MoveIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (isShadowCopy && onGoToOriginal) {
      options.push({
        label: t("content_item_options_go_to_original"),
        value: "goToOriginal",
        icon: <RedirectIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (onDownload) {
      options.push({
        label: Action.DOWNLOAD,
        value: "download",
        icon: <DownloadIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }
    if (
      onShowVersions &&
      flags[LDFlagNames.VERSION_HISTORY] &&
      (activeTab === "spaces" || activeTab === "organisation")
    ) {
      options.push({
        label: t("version_history"),
        value: "versions",
        icon: <ClockIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isShadowCopy,
        disabledReason: t("content_item_shadow_copy_disabled_versions_tooltip"),
      });
    }

    if (eolasFile && !isShadowCopy && (status === "published" || status === "parallelDraft")) {
      options.push({
        label: t("content_item_unpublish"),
        value: "unpublish",
        icon: <UnpublishIcon width={ICON_SIZE} height={ICON_SIZE} />,
      });
    }

    if (onDelete) {
      options.push({
        label:
          status === "parallelDraft" || status === "unpublished"
            ? t("content_item_delete_draft")
            : Action.DELETE,
        value: "delete",
        icon: <DeleteIcon width={ICON_SIZE} height={ICON_SIZE} />,
        disabled: isShadowCopy && isInViewer,
        disabledReason: t("content_item_shadow_copy_disabled_deleted_tooltip"),
      });
    }

    return options;
  }, [
    isInViewer,
    isShadowCopy,
    onDelete,
    onDownload,
    onShowVersions,
    isInAdminMode,
    onEdit,
    t,
    onCopy,
    onMove,
    onGoToOriginal,
    flags,
    activeTab,
    onShare,
    status,
    eolasFile,
  ]);
  const handleActionMenuSelect = (option: DropdownOption) => {
    if (option.value === "delete") {
      onDelete?.();
    }

    if (option.value === "edit") {
      onEdit?.();
    }

    if (option.value === "download") {
      onDownload?.();
    }

    if (option.value === "publish") {
      onPublishDraft?.();
    }

    if (option.value === "copy") {
      onCopy?.();
    }

    if (option.value === "move") {
      onMove?.();
    }

    if (option.value === "goToOriginal") {
      onGoToOriginal?.();
    }

    if (option.value === "versions") {
      onShowVersions?.();
    }

    if (option.value === "share") {
      onShare?.();
    }

    if (option.value === "unpublish") {
      onUnpublish?.();
    }
  };

  return { handleActionMenuSelect, menuOptions, status };
};
