import { observer } from "mobx-react-lite";
import { InnerPageWrapper } from "UIKit";
import MasterSearch from "Components/MasterSearch/MasterSearch";
import MainSectionList from "Pages/Spaces/pages/Space/components/MainSectionList/MainSectionList";
import { useSpaceOrgMainSections } from "modules/spaces/data/useSpaceOrgMainSections";
import SpaceDetails from "../Space/components/SpaceDetails/SpaceDetails";
import EditSpaceDetailsWizard from "../Space/components/EditSpaceDetailsWizard/EditSpaceDetailsWizard";
import { useSpaceDetailsModal } from "../Space/hooks/useSpaceDetailsModal";
import { modalStore } from "Stores/ModalStore";

import { Space } from "@eolas-medical/core";
import GridContainer from "UIKit/GridContainer/GridContainer";

const Organisation = observer(() => {
  const { activatedMainSections } = useSpaceOrgMainSections({
    activeTab: "organisation",
    isOnAdminView: false,
  });

  const {
    isAdminUser,
    isPublic,
    updatingSpace,
    handleSpaceUpdated,
    selectedSpace,
    updateSpaceSuccess,
  } = useSpaceDetailsModal();

  const handleClickEditSpace = (selectedSpace: Space) =>
    modalStore.openModal({
      variant: "component",
      Component: (
        <EditSpaceDetailsWizard
          isSuccess={updateSpaceSuccess}
          onUpdate={handleSpaceUpdated}
          isLoading={updatingSpace}
          isOrgAdminSpace
          selectedSpace={selectedSpace}
        />
      ),
      name: "editSpace",
    });

  if (!selectedSpace) {
    return null;
  }
  return (
    <InnerPageWrapper>
      <div className="space-y-6 flex flex-col mb-10">
        <SpaceDetails
          onClickEditSpace={() => handleClickEditSpace(selectedSpace)}
          isAdminUser={isAdminUser}
          isPublic={isPublic}
          selectedSpace={selectedSpace}
          isOrgAdminSpace
        />
        <MasterSearch />
      </div>

      <GridContainer>
        <MainSectionList mainSectionList={activatedMainSections} />
      </GridContainer>
    </InnerPageWrapper>
  );
});

export default Organisation;
