import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormElement } from "UIKit/FormElements/FormElement";
import { Input } from "UIKit/FormElements/Input/Input";
import { Button } from "UIKit/Button/Button";
import { InnerModalWrapper } from "UIKit/Modal/components/InnerModalWrapper";

type SelectLinkModalValues = {
  link: string;
};

type Props = {
  onSubmit: (link: string) => void;
  onGoBack: () => void;
};

export const ExternalLinkForm = ({ onSubmit, onGoBack }: Props) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    link: yup.string().url("Please enter a valid URL").required("Link is required"),
  });

  const { control, handleSubmit, formState } = useForm<SelectLinkModalValues>({
    resolver: yupResolver(schema),
  });

  const { isDirty } = formState;

  const handleSelectLink = ({ link }: SelectLinkModalValues) => {
    onSubmit(link);
  };

  return (
    <InnerModalWrapper>
      <div className="relative p-8 max-h-80vh overflow-y-auto">
        <form onSubmit={handleSubmit(handleSelectLink)}>
          <FormElement required id="link" control={control} label="Link">
            {(fieldProps) => {
              const { value, ...rest } = fieldProps;
              return (
                <Input
                  {...rest}
                  data-testid="second-input"
                  size="lg"
                  className="col-end-3 input pl-4 box-content rounded-md"
                  placeholder={t("linked_documents_viewer_input_placeholder")}
                  value={value}
                />
              );
            }}
          </FormElement>

          <div className="flex justify-end space-x-2 mt-4">
            <Button type="button" color="grey" onClick={onGoBack} variant="outline">
              {t("general_cancel")}
            </Button>
            <Button type="submit" disabled={!isDirty}>
              {t("general_submit")}
            </Button>
          </div>
        </form>
      </div>
    </InnerModalWrapper>
  );
};
