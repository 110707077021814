import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Space, userStore } from "@eolas-medical/core";
import { Loader, Title } from "UIKit";

import { SpaceItem } from "./components/SpaceItem/SpaceItem";
import useUserSpaces from "modules/spaces/data/useUserSpaces";

const YourSpaces = () => {
  const { t } = useTranslation();
  const { userID = "" } = userStore;
  const { userSpaces, userSpacesLoading } = useUserSpaces(userID);

  const renderSpaceItem = useCallback((space: Space) => {
    return (
      <div key={space.id}>
        <SpaceItem space={space} />
      </div>
    );
  }, []);

  const renderSpaceList = () => {
    if (userSpacesLoading) {
      return <Loader className="bg-transparent h-30vh" />;
    }

    if (userSpaces.length === 0) {
      return (
        <Title level={8} className="text-grey-700">
          {t("selectSpace_no_member_of_orgs")}
        </Title>
      );
    }

    return (
      <div className="space-y-4">
        <div className="text-grey-700">
          <Title level={7}>{t("your_spaces")}</Title>
        </div>
        {userSpaces.map(renderSpaceItem)}
      </div>
    );
  };

  return <div className="flex flex-col relative md:mt-12 space-y-4">{renderSpaceList()}</div>;
};

export default YourSpaces;
