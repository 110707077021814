import { CheckIcon } from "Assets";

export type EolasCheckboxProps = {
  isChecked: boolean;
  onToggle: () => void;
  id?: string;
  disabled?: boolean;
  className?: string;
  UncheckedIcon?: JSX.Element;
  CheckedIcon?: JSX.Element;
};

export const EolasCheckBox = ({
  id,
  isChecked,
  onToggle,
  className = "",
  UncheckedIcon,
  CheckedIcon,
}: EolasCheckboxProps) => {
  const icon = isChecked ? CheckedIcon || <Checked /> : UncheckedIcon || <Unchecked />;
  return (
    <div
      onClick={() => {
        onToggle();
      }}
      className={`flex items-center cursor-pointer ${className}`}
    >
      <input id={id || "eolas-check-box"} type="checkbox" checked={isChecked} className="hidden" />
      {icon}
    </div>
  );
};

const Checked = () => <CheckIcon className="text-green w-6 h-6" />;

const Unchecked = () => (
  <span className="w-6 h-6 border border-grey-400 bg-grey-300 rounded-full" />
);
