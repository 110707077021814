import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import useBackgroundCoverTheme, { BackgroundClassName } from "./useBackgroundCoverTheme";

export enum BackgroundImageName {
  SpaceBackground = "spaceBgLines",
  OrganisationBackground = "orgBgLines",
  KnowledgeBackground = "knowledgeBgLines",
  MeBackground = "meBgLines",
}
const BackgroundCover = ({
  overrideClassName,
  overrideImage,
}: {
  overrideClassName?: BackgroundClassName;
  overrideImage?: BackgroundImageName;
}) => {
  const { className, backgroundImage } = useBackgroundCoverTheme();
  const backgroundClass = twMerge(
    "h-full overflow-y-overlay absolute bg-no-repeat bg-cover w-full",
    className,
  );

  return (
    <motion.div
      layout
      className={overrideClassName ? overrideClassName : backgroundClass}
      style={{
        backgroundImage: `url(${overrideImage ? overrideImage : backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        position: "fixed", // Make it persist through scrolling
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        zIndex: "-1",
      }}
    />
  );
};

export default BackgroundCover;
