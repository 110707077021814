import {
  ContentItem,
  eolasLogger,
  hasStringProp,
  isBlobContentItem,
  isEolasEditorContentItem,
  isFlashCardContentItem,
  SupportedFileType,
} from "@eolas-medical/core";

import { useLaunchDarkly } from "Contexts";
import { useTranslation } from "react-i18next";
import { isShadowCopyContentItem } from "shared/pages/ContentRepository/ContentItems/functions/typeguards";
import { LDFlagNames } from "Utilities/types";
import { useDeleteParallelDraft } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useDeleteParallelDraft";
import { useUnpublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useUnpublishItem";
import { usePublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/usePublishItem";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";
import { modalStore } from "Stores/ModalStore";
import { ManageContentItemWizardModal } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/ManageContentItemWizardModal";
import EditFlashcardModal from "shared/pages/ContentRepository/ContentItems/components/EditFlashcardItemModal/EditFlashcardItemModal";
import EditEolasEditorItemModal from "shared/pages/ContentRepository/ContentItems/components/EditEolasEditorItemModal/EditEoalsEditorItemModal";
import EditContentItemModal from "shared/pages/ContentRepository/ContentItems/components/EditContentItemModal/EditContentItemModal";
import useDownloadContentFile from "modules/contentRepository/data/useDownloadContentFile";
import { PublishContentItemModal } from "shared/pages/ContentRepository/ContentItems/components/PublishContentItemModal/PublishContentItemModal";
import { CopyItemsModal } from "Pages/Spaces/components/CopyItemsModal/CopyItemsModal";
import { MoveItemsModal } from "Pages/Spaces/components/MoveItemsModal/MoveItemsModal";
import { useNotifications } from "Components/Notifications";
import { useHistory } from "react-router-dom";
import { getPathToShadowCopyOriginal } from "shared/pages/ContentRepository/ContentItems/functions/getPathToShadowCopyOriginal";
import { useSpacesContext } from "modules/spaces";
import { ShowVersionsModal } from "shared/pages/ContentRepository/ContentItems/components/ShowVersionsModal/ShowVersionsModal";
import { useHandleClickContent } from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/hooks/useHandleClickContent";
import { useAdminOperations, UseAdminOperationsArgs } from "./useAdminOperations";
import { useDeleteItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useDeleteItem";
import DeleteContentItemModal from "shared/pages/ContentRepository/ContentItems/components/DeleteContentItemModal/DeleteContentItemModal";
import { getPathToItemOrSection } from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";
import { IsCopyableStatus } from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/types";

export type UseAdminOperationsContentItemArgs = Omit<UseAdminOperationsArgs, "eolasFile"> & {
  item: ContentItem;
  contentType: SupportedFileType;
  handleCloseModal: () => void;
  handleModalChange: (modal: React.ReactNode) => void;
  flashcardsHaveSpecialties?: boolean;
  isCopyableStatus?: IsCopyableStatus;
};

export const useAdminOperationsContentItem = ({
  flashcardsHaveSpecialties,
  isInViewer,
  isShadowCopy,
  item,
  contentType,
  isUnpublished,
  onShare,
  handleCloseModal,
  handleModalChange,
  isCopyableStatus,
  isInAdminMode,
}: UseAdminOperationsContentItemArgs) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const { hasCopilotData, contextDomain } = useContentRepositoryContext();
  const { file: eolasFile } = useGetFile({
    id: contextDomain === "spaceOrg" ? item.id : null,
  });
  const handleEdit = () => {
    if (isShadowCopy) {
      eolasLogger.error(
        new Error("Cannot edit shadow copy content item -- this should never happen"),
        { item },
      );
      return;
    }

    if (contextDomain === "spaceOrg") {
      modalStore.openModal({
        variant: "component",
        isDismissible: false,
        shouldRenderCloseButton: true,
        name: "editContentItem",
        Component: <ManageContentItemWizardModal sectionId={item.parentId} fileId={item.id} />,
      });
      return;
    }

    if (isFlashCardContentItem(item)) {
      handleModalChange(
        <EditFlashcardModal
          flashcard={item}
          hasSpecialties={flashcardsHaveSpecialties}
          hasCopilotData={hasCopilotData}
          onClose={handleCloseModal}
        />,
      );
    } else if (isEolasEditorContentItem(item)) {
      handleModalChange(<EditEolasEditorItemModal contentItem={item} onClose={handleCloseModal} />);
    } else {
      handleModalChange(<EditContentItemModal contentItem={item} onClose={handleCloseModal} />);
    }
  };
  const canEdit = contentType !== "dsm" || flags[LDFlagNames.DSM_EDITING];
  const { goBack } = useHistory();
  const handleDeleteItem = () => {
    if (eolasFile?.hasDrafts) {
      deleteParallelDraft();
      return;
    }
    if (contextDomain === "spaceOrg") {
      deleteItem(isInViewer ? goBack : undefined);
      return;
    }
    handleModalChange(<DeleteContentItemModal contentItem={item} onClose={handleCloseModal} />);
  };

  const { deleteParallelDraft } = useDeleteParallelDraft(eolasFile);
  const { unpublishItem } = useUnpublishItem(eolasFile);
  const { publishItem } = usePublishItem(eolasFile);
  const { deleteItem } = useDeleteItem(eolasFile);
  const { downloadContentFile } = useDownloadContentFile(item);
  const handleDownloadFile = () => {
    downloadContentFile();
  };
  const { showNotification } = useNotifications();
  const { encodedSelectedSpaceName } = useSpacesContext();
  const { push } = useHistory();
  const onPublishDraft = () => {
    if (contextDomain === "spaceOrg") {
      publishItem();
      return;
    }
    handleModalChange(<PublishContentItemModal contentItem={item} onClose={handleCloseModal} />);
  };

  const handleOnClickCopyTo = () => {
    modalStore.openModal({
      name: "selectCopyTargetModal",
      variant: "component",
      Component: (
        <CopyItemsModal initialItems={[item]} onCopySuccess={isInViewer ? goBack : undefined} />
      ),
    });
  };

  const handleOnClickMoveTo = () => {
    modalStore.openModal({
      name: "selectMoveTargetModal",
      variant: "component",
      Component: (
        <MoveItemsModal initialItems={[item]} onMoveSuccess={isInViewer ? goBack : undefined} />
      ),
    });
  };
  const handleGoToOriginal = async () => {
    let result;
    if (isInViewer) {
      result = await getPathToItemOrSection({
        id: item.id,
        isInAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      });
    } else if (!isShadowCopyContentItem(item)) {
      showNotification({
        type: "error",
        description: t("content_item_go_to_original_error"),
      });
      return;
    } else {
      result = await getPathToShadowCopyOriginal({
        shadowItem: item,
        isInAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      });
    }
    if (hasStringProp(result, "error")) {
      eolasLogger.error(new Error("Error getting content item path"), { result });
      showNotification({
        type: "error",
        description: t("content_item_go_to_original_error"),
      });
      return;
    }
    push(result);
  };

  const { handleClickItem } = useHandleClickContent({ id: item.id, isAdminMode: isInAdminMode });
  const handleShowVersions = () => {
    modalStore.openModal({
      name: "showVersionsModal",
      variant: "component",
      Component: (
        <ShowVersionsModal contentId={item.id} handleClickVersionPreview={handleClickItem} />
      ),
    });
  };

  return useAdminOperations({
    onEdit: canEdit ? handleEdit : undefined,
    eolasFile,
    isInAdminMode,
    isUnpublished,
    onDelete: handleDeleteItem,
    onDownload: isBlobContentItem(item) ? handleDownloadFile : undefined,
    onPublishDraft,
    onCopy: isCopyableStatus ? handleOnClickCopyTo : undefined,
    onMove: isCopyableStatus ? handleOnClickMoveTo : undefined,
    onGoToOriginal: handleGoToOriginal,
    onShowVersions: handleShowVersions,
    onShare,
    onUnpublish: unpublishItem,
    isShadowCopy,
    isInViewer,
  });
};
