import { contentClient, EolasFile } from "@eolas-medical/core";
import { useAppSync } from "Hooks";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { intermediateUpdateForUi } from "../../../functions/intermediateUpdateForUi";

export const useDeleteItem = (file: EolasFile | null) => {
  const { t } = useTranslation();
  const { refetch } = useAppSync();

  const deleteItem = useCallback(
    (onDelete?: () => void) => {
      if (!file) {
        return;
      }

      modalStore.openModal({
        name: "deleteItem",
        variant: "dialogue",
        layoutType: "stacked",
        title: t("content_item_delete_title"),
        message: t("content_item_delete_description"),
        icon: "warning",
        isDismissible: true,
        onConfirmAsync: async () => {
          await contentClient.deleteContentItem({
            mainSectionId: file.mainSectionID,
            itemId: file.id,
          });
          intermediateUpdateForUi({ type: "file", file, action: "delete" });
          refetch();
          onDelete?.();
        },
      });
    },
    [refetch, file, t],
  );

  return { deleteItem };
};
