import React, { useCallback } from "react";
import { motion, Variants } from "framer-motion";

import { DeleteIcon, EditMonoIcon } from "Assets";
import type { IconPickerIconsType } from "Assets/Icons/iconPickerIcons";

import { IconButton } from "../Button";
import { SectionIcon } from "../IconPicker";
import { Title } from "UIKit/Typography";

const childrenVariants: Variants = {
  out: { x: -50, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

export interface SubSectionTileProps {
  title: string;
  image: IconPickerIconsType | string;
  description?: string | null;
  onClick?: () => void;
  onEdit?(): void;
  onDelete?(): void;
}

export const SubSectionTile: React.FC<SubSectionTileProps> = ({
  image,
  title,
  onEdit,
  onDelete,
  description,
  ...props
}: SubSectionTileProps) => {
  const onEditSection = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit?.();
    },
    [onEdit],
  );

  const onDeleteSection = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete?.();
    },
    [onDelete],
  );

  return (
    <motion.div
      variants={childrenVariants}
      data-testid="subsectionTile"
      whileHover={{ scale: 1.01 }}
      className={`
        flex items-center justify-between p-4 space-x-4 md:space-x-6 cursor-pointer
        bg-white rounded-md tile-border
      `}
      {...props}
    >
      <SectionIcon iconURL={image} iconClassname="w-10 h-10" fallbackImageClassname="w-10 h-10" />

      <div
        className={`flex flex-col items-start flex-1 ${
          description ? "space-y-1 md:space-y-2" : ""
        }`}
      >
        <Title level={8} className="line-clamp-2 font-semibold">
          {title}
        </Title>
        <p className="line-clamp-2 text-sm" title={description as string}>
          {description}
        </p>
      </div>

      <div className="flex flex-col items-center justify-center space-y-3">
        {onDelete ? (
          <IconButton
            icon={<DeleteIcon />}
            onClick={onDeleteSection}
            data-testid={`${title}-delete`}
          />
        ) : null}
        {onEdit ? <IconButton icon={<EditMonoIcon />} onClick={onEditSection} /> : null}
      </div>
    </motion.div>
  );
};
