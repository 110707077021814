import { AssignUserIcon } from "Assets/Icons";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { Button, FormElement, Input, Loader } from "UIKit";
import { Title, Text } from "../../Typography";
import { ModalBody, ModalHeader, InnerModalWrapper } from "../components";
import { UserItem } from "./components/UserItem/UserItem";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useState } from "react";

interface RecentlyAssignedUser {
  email: string;
}

export interface AssignUserModalProps<T extends RecentlyAssignedUser> {
  assignTitleText?: string;
  reassignTitleText?: string;
  descriptionText?: string;
  placeholderText?: string;
  submitAssignButtonText?: string;
  submitReassignButtonText?: string;
  cancelButtonText?: string;
  assignButtonText?: string;
  removeButtonText?: string;
  assignerEmail: string;
  onSubmit: (email: string) => void;
  onCancel: () => void;
  recentlyAssignedUsers?: T[];
  isLoading?: boolean;
  isSubmitting?: boolean;
  isReassigning?: boolean;
  canReassignCurrent?: boolean;
  renderUserAvatar?: (user: T) => React.ReactNode;
  currentAssignedUsers?: T[];
  assignedUsersErrorText?: string;
}

const AssignUserModal = <T extends RecentlyAssignedUser>({
  assignTitleText = "Assign User",
  reassignTitleText = "Reassign User",
  descriptionText = "",
  placeholderText = "Enter email",
  submitAssignButtonText = "Submit",
  submitReassignButtonText = "Reassign",
  cancelButtonText = "Cancel",
  assignButtonText = "Assign",
  removeButtonText = "Remove",
  recentlyAssignedUsers = [],
  assignerEmail,
  onSubmit,
  onCancel,
  isSubmitting = false,
  isLoading = false,
  renderUserAvatar,
  isReassigning = false,
  canReassignCurrent = false,
  currentAssignedUsers = [],
  assignedUsersErrorText,
}: AssignUserModalProps<T>) => {
  const [userIsAssigned, setUserIsAssigned] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required")
      .test("is-not-current-user", "This user is already assigned", function (value) {
        // if user declined feedback then can be reassiged again if typing the email
        if (value === currentAssignedUsers[0]?.email && !canReassignCurrent) {
          return false;
        }
        return true;
      })
      .test("is-not-user", "You cannot assign yourself", (value) => {
        return value !== assignerEmail;
      })
      // if any of all current assigned users contains the submitted user
      .test(
        "is-currently-assigned",
        assignedUsersErrorText || "This user is already assigned",
        function (value) {
          if (assignedUsersErrorText) {
            return (
              value !== undefined && !currentAssignedUsers.find((user) => user.email === value)
            );
          }
          return true;
        },
      ),
  });

  const { control, handleSubmit, register, setValue, watch, reset } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });

  const handleAssignUser = useCallback(
    ({ email }: { email: string }) => {
      setValue("email", email);
      setUserIsAssigned(true);
    },
    [setValue],
  );

  const handleRemoveAssigned = useCallback(() => {
    setUserIsAssigned(false);
    reset();
  }, [reset]);

  const renderAssignedUser = () => (
    <>
      <UserItem
        email={watch("email")}
        actionButton={{
          buttonText: removeButtonText,
          onClick: handleRemoveAssigned,
          iconLeft: "RemoveIcon",
          backgroundColor: "blue",
          color: "white",
          disabled: isSubmitting,
        }}
      />
      <div className="flex justify-end">
        <Button
          type="submit"
          color="white"
          onClick={onCancel}
          className="h-10 sm:h-10 px-8 w-40 mr-2"
        >
          {cancelButtonText}
        </Button>
        <Button
          type="submit"
          onClick={() => onSubmit(watch("email"))}
          className="h-10 px-8 w-40 whitespace-nowrap"
          isLoading={isSubmitting}
          data-testid="submit-event"
        >
          {isReassigning ? submitReassignButtonText : submitAssignButtonText}
        </Button>
      </div>
    </>
  );

  const renderAssignForm = () => (
    <>
      <form onSubmit={handleSubmit(handleAssignUser)}>
        <FormElement required id="email" control={control}>
          {(fieldProps) => {
            const { value: _value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                data-testid="email-input"
                size="lg"
                className="input pl-4 sm:h-12 box-content rounded-md"
                iconLeft={
                  <AssignUserIcon className={"h-8 w-8 mr-4 hidden sm:inline text-blue-500"} />
                }
                placeholder={placeholderText}
                rightSection={
                  <Button
                    type="submit"
                    variant="solid"
                    style={{ borderRadius: "3rem" }}
                    className="h-10 sm:h-10 px-8 mr-1"
                  >
                    {assignButtonText}
                  </Button>
                }
                {...register("email")}
              />
            );
          }}
        </FormElement>
      </form>
      <Text level={1} className="text-center text-grey-700">
        {descriptionText}
      </Text>

      <div className="max-h-80 overflow-y-auto">
        {isLoading ? (
          <Loader />
        ) : (
          recentlyAssignedUsers.map((user: T) => {
            const isUserAssigned = currentAssignedUsers?.some(
              (assignedUser) => assignedUser.email === user.email,
            );
            const isNotAssignable =
              (currentAssignedUsers[0]?.email === user.email ? !canReassignCurrent : false) ||
              isUserAssigned;
            return (
              <UserItem
                key={user.email}
                email={user.email}
                notAssignable={isNotAssignable}
                actionButton={{
                  buttonText: assignButtonText,
                  onClick: () => handleAssignUser({ email: user.email }),
                  color: "blue",
                  variant: "outline",
                }}
                renderUserAvatar={() => renderUserAvatar && renderUserAvatar(user)}
              />
            );
          })
        )}
      </div>
    </>
  );

  return (
    <>
      <InnerModalWrapper>
        <ModalHeader className="flex flex-col">
          <Title level={5}>{isReassigning ? reassignTitleText : assignTitleText}</Title>
        </ModalHeader>

        <ModalBody className="space-y-4 sm:space-y-8">
          {!userIsAssigned ? renderAssignForm() : renderAssignedUser()}
        </ModalBody>
      </InnerModalWrapper>
    </>
  );
};

export default AssignUserModal;
