import { rocket } from "Assets";
import { Button } from "UIKit/Button";
import { InnerModalWrapper, LottieWithHeader, ModalHeader, SuccessModal, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { AnalyticsEvents, AppLevelSection, sectionStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useMainSectionAnalytics } from "../../../hooks/useMainSectionAnalytics";
import { observer } from "mobx-react-lite";

const BoostOpenRatesModal = observer(() => {
  const { t } = useTranslation();

  const { id: mainSectionId } = sectionStore.getChildReferenceByMainSectionType(
    AppLevelSection.newsFeed,
  ) ?? {
    id: "",
  };

  const {
    sendMainSectionReminder,
    sendingMainSectionReminder: isSendingReminder,
    sendMainSectionReminderSuccess: didSendReminder,
    resetMainSectionReminder,
  } = useMainSectionAnalytics({ mainSectionId });

  const handleSendMainSectionReminder = () => {
    sendMainSectionReminder({ mainSectionId });
    trackEvent(AnalyticsEvents.MAIN_SECTION_STATS_SEND_BOOST_OPEN_RATE, {
      mainSection: AppLevelSection.newsFeed,
    });
  };

  const handleCompleteMainSectionReminder = () => {
    resetMainSectionReminder();
    modalStore.closeModal();
  };

  if (didSendReminder) {
    return (
      <SuccessModal
        onComplete={handleCompleteMainSectionReminder}
        text={t("analytics_stats_read_reminder_sent")}
      />
    );
  }

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col justify-center items-center p-10  border-b border-b-grey-300">
        <Title level={5}>{t("analytics_boost_rates_title")}</Title>
        <Text level={1} className="text-grey-600">
          {t("analytics_boost_rates_subtitle")}
        </Text>
      </ModalHeader>

      <div
        className="flex flex-col
        p-6 sm:p-8 bg-grey-50 max-h-80vh overflow-y-auto"
      >
        <div className="space-y-4">
          <div className="flex flex-col w-2/3 mx-auto justify-center items-center">
            <LottieWithHeader
              animationSize="100%"
              animation={rocket}
              lottieOptions={{ loop: true }}
              text={""}
            />
            <Title level={9} className="mx-auto text-center ">
              {t("analytics_boost_rates_reminder_information")}.
            </Title>
          </div>
          <div className="flex justify-center items-center mx-auto space-x-2 w-full md:w-2/4">
            <Button
              size="sm"
              variant="outline"
              onClick={() => modalStore.closeModal()}
              className="w-full"
            >
              {t("general_cancel")}
            </Button>
            <Button
              size="sm"
              iconLeft="ShareIcon"
              className="w-full"
              onClick={handleSendMainSectionReminder}
              isLoading={isSendingReminder}
              disabled={isSendingReminder}
            >
              <Text level={2} className="ml-2">
                {t("general_send")}
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </InnerModalWrapper>
  );
});

export default BoostOpenRatesModal;
