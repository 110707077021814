import { EolasWizardModalWrapper } from "UIKit/EolasWizard/EolasWizardModalWrapper";
import { NormalStepComponentProps, NormalStepData } from "../../../types";
import { useTranslation } from "react-i18next";
import { useEditorContent } from "./hooks/useEditorContent";
import { EditorComponent } from "./EditorComponent";
import { useRef } from "react";
import { makeMutableWizardState } from "../../../functions/makeMutableWizardState";
import { FormError } from "UIKit";
import { getFinalStepName } from "../../../functions/getFinalStepName";
import {
  handleUpdateWizardDraftStatus,
  useMakePenultimateStepButtonProps,
} from "../../../../../hooks/useMakePenultimateStepButtonProps";
import { NewContentType } from "@eolas-medical/core";

const EolasEditorCreator = ({
  onClickBack,
  onClickForward,
  wizardState,
  sectionId,
}: NormalStepComponentProps) => {
  const modalBodyRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const { control, handleSubmit, initialContent, isLoading, processEditorData, isError } =
    useEditorContent(wizardState);

  const submitFunc = ({ isDraft }: { isDraft: boolean }) => {
    handleSubmit(async ({ content }) => {
      let newState = makeMutableWizardState(wizardState);
      const newMetadata = await processEditorData({ content });
      if (!newState.isExistingFileWithNewType) {
        newState.dto.metadata = newMetadata;
      } else if (newState.dto.newType === NewContentType.EOLAS_EDITOR) {
        newState.dto.newProperties.metadata = newMetadata;
      }

      newState = handleUpdateWizardDraftStatus({ state: newState, isDraft });
      onClickForward(newState);
    })();
  };

  const buttonProps = useMakePenultimateStepButtonProps({
    currentWizardState: wizardState,
    handleClickBack: onClickBack,
    handleClickForward: () => submitFunc({ isDraft: false }),
    handleAltClickForward: () => submitFunc({ isDraft: true }),
    isLoading,
  });

  return (
    <EolasWizardModalWrapper
      header={{ title: t("eolasEditor_page_title") }}
      modalWrapperClassName="max-w-6xl h-90vh p-0"
      buttonRowProps={buttonProps}
      ref={modalBodyRef}
    >
      <EditorComponent
        sectionId={sectionId}
        initialEditorContent={initialContent}
        modalBodyRef={modalBodyRef}
        control={control}
        state={wizardState}
      />
      {isError ? <FormError error={t("repository_upload_error")} /> : null}
    </EolasWizardModalWrapper>
  );
};

export const eolasEditorCreatorData: NormalStepData = {
  StepComponent: EolasEditorCreator,
  props: {
    getPreviousStepName: () => "editorFileDetails",
    getNextStepName: (_, state) => getFinalStepName(state),
  },
};
