import { Button, Text } from "UIKit";
import { useTranslation } from "react-i18next";

interface PortfolioWizardButtonProps {
  onClick: () => void;
}
const PortfolioWizardButton = ({ onClick }: PortfolioWizardButtonProps) => {
  const { t } = useTranslation();

  return (
    <Button
      size="md"
      weight="bold"
      iconLeft="WandIcon"
      color="yellow"
      className={`group sm:self-center bg-yellow-500 mb-8
      text-white border-yellow-500 rounded-xl w-full hover:bg-yellow-300 hover:border-yellow-300`}
      onClick={onClick}
    >
      <Text level={1} className="ml-2">
        {t("portfolio_wizard_button")}
      </Text>
    </Button>
  );
};

export default PortfolioWizardButton;
