import { Route, RouteComponentProps, Redirect, Switch } from "react-router-dom";

import { BaseAppRoute, LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import Portfolio from "./pages/Portfolio/Portfolio";
import MyFiles from "./pages/MyFiles/MyFiles";
import MyFavourites from "./pages/MyFavourites/MyFavourites";
import { MeLevelSection } from "@eolas-medical/core";
import useUserDetails from "Hooks/useUserDetails";
import { MeTab } from "./MeTab";
import { PersonalDetails } from "./pages";

type MeRoute = keyof typeof MeLevelSection | "personalDetails";

export const meRoutes = {
  personalDetails: `/${BaseAppRoute.myProfile}/personal-details`,
  myPortfolio: `/${BaseAppRoute.myProfile}/portfolio`,
  myFiles: `/${BaseAppRoute.myProfile}/my-files`,
  myFavourites: `/${BaseAppRoute.myProfile}/my-favourites`,
} as const;

// Just to make sure that the keys are checked
const _meRoutesCheck: Record<MeRoute, string> = meRoutes;

export const MeTabRoutes = ({ match: { path } }: RouteComponentProps) => {
  const { flags } = useLaunchDarkly();
  const { isSharedAccount } = useUserDetails();
  const isPortfolioActive = flags[LDFlagNames.TEACHING_DELIVERY];
  const isMyFilesActive = flags[LDFlagNames.MY_FILES];
  const isMyFavouritesActive = flags[LDFlagNames.MY_FAVOURITES] && !isSharedAccount;

  return (
    <Switch>
      <Route exact path={path} component={MeTab} />

      <Route exact path={meRoutes.personalDetails} component={PersonalDetails} />

      {isPortfolioActive ? <Route path={meRoutes.myPortfolio} component={Portfolio} /> : null}

      {isMyFilesActive ? <Route path={meRoutes.myFiles} component={MyFiles} /> : null}

      {isMyFavouritesActive ? (
        <Route path={meRoutes.myFavourites} component={MyFavourites} />
      ) : null}

      <Redirect to={`/${BaseAppRoute.myProfile}`} />
    </Switch>
  );
};
