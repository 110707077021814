import { useTranslation } from "react-i18next";
import { InnerPageWrapper, Text } from "UIKit";

export const ErrorComponent = () => {
  const { t } = useTranslation();

  return (
    <InnerPageWrapper>
      <div className="flex flex-1 items-center justify-center">
        <Text level={1}>{t("error_loading_content")}</Text>
      </div>
    </InnerPageWrapper>
  );
};
