import { Text } from "UIKit";
import { AdditionalInfoStrings } from "./AdditionalInfo.strings";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";

type AdditionalInfoItem = { title: string; content: string };

interface AdditionalInfoProps {
  doseAdjustments?: string;
  extremesOfBodyWeight?: string;
  doseEquivalence?: string;
  pharmacokinetics?: string;
  potency?: string;
}

const KEY_BASE_ADD_INFO = "eolas_add_info";

const AdditionalInfo = ({
  doseAdjustments,
  extremesOfBodyWeight,
  doseEquivalence,
  pharmacokinetics,
  potency,
}: AdditionalInfoProps) => {
  const addInfoContent: AdditionalInfoItem[] = [];
  if (doseAdjustments) {
    addInfoContent.push({ title: AdditionalInfoStrings.doseAdjustments, content: doseAdjustments });
  }
  if (extremesOfBodyWeight) {
    addInfoContent.push({
      title: AdditionalInfoStrings.extremesOfBodyWeight,
      content: extremesOfBodyWeight,
    });
  }
  if (doseEquivalence) {
    addInfoContent.push({ title: AdditionalInfoStrings.doseEquivalence, content: doseEquivalence });
  }
  if (potency) {
    addInfoContent.push({ title: AdditionalInfoStrings.potency, content: potency });
  }
  if (pharmacokinetics) {
    addInfoContent.push({
      title: AdditionalInfoStrings.pharmacokinetics,
      content: pharmacokinetics,
    });
  }

  if (!addInfoContent.length) {
    return null;
  }

  return (
    <div>
      {addInfoContent.map(({ title, content }, addInfoIndex) => {
        return (
          <div key={KEY_BASE_ADD_INFO + addInfoIndex} className="space-y-2">
            <Text level={2}>{title}</Text>
            <BnfContentElement htmlContent={content} />
          </div>
        );
      })}
    </div>
  );
};

export default AdditionalInfo;
