import React, { useCallback } from "react";

import { DeleteIcon, EditMonoIcon } from "Assets";
import { IconButton } from "UIKit/Button";

interface BaseContent {
  id: string;
  isHighlighted?: boolean;
}

interface TileControlsProps<F> {
  content: F;
  onEdit?: (content: F) => void;
  onDelete?: (content: F) => void;
  onHighlight?: (content: F) => void;
  direction?: "row" | "column";
  isHighlighting?: boolean;
  "data-testid"?: string;
}

export const TileControls = <F extends BaseContent>({
  content,
  onEdit,
  onDelete,
  direction = "column",
  "data-testid": dataTestId = "tile-controls",
}: TileControlsProps<F>) => {
  const _onEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit?.(content);
    },
    [content, onEdit],
  );

  const _onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete?.(content);
    },
    [content, onDelete],
  );

  return (
    <div
      className={`
        flex items-center h-full content-between justify-between space-y-2
        ${direction === "row" ? "flex-row" : "flex-col"}
        self-start md:self-center pt-1
      `}
      data-testid={dataTestId}
    >
      {onDelete ? (
        <IconButton
          size="sm"
          onClick={_onDelete}
          icon={<DeleteIcon />}
          data-testid={`deleteButtonFor-${content.id}`}
        />
      ) : null}

      {onEdit ? (
        <IconButton
          size="sm"
          onClick={_onEdit}
          icon={<EditMonoIcon />}
          data-testid={`editButtonFor-${content.id}`}
        />
      ) : null}
    </div>
  );
};
