import { useCustomTheme } from "Components/Navigation/hooks/useCustomTheme";
import { useEolasNavigation } from "Components/Navigation/hooks";

import spaceBgLines from "Assets/Img/space-background-lines.svg";
import orgBgLines from "Assets/Img/org-background-lines.svg";
import meBgLines from "Assets/Img/me-background-lines.svg";
import knowledgeBgLines from "Assets/Img/knowledge-background-lines.svg";
import { sectionStore } from "@eolas-medical/core";

export enum BackgroundClassName {
  SpaceBackground = "bg-blue-spaces-background",
  OrganisationBackground = "bg-orange-organisation-background",
  KnowledgeBackground = "bg-green-knowledge-background",
  MeBackground = "bg-yellow-me-background",
  CustomBrandBackground = "linear-gradient(180deg, bg-brand -15%, rgba(236, 196, 172, 0) 35%, #FAFAFA 100%)",
}

type BackgroundCoverTheme = {
  className: BackgroundClassName;
  backgroundImage: string | null;
};

const useBackgroundCoverTheme = (): BackgroundCoverTheme => {
  const { customBrandColor } = useCustomTheme();
  const {
    isOnSpacesRoot,
    isOnSelectSpaceRoot,
    isOnOrganisationRoot,
    isOnKnowledgeRoot,
    isOnMeRoot,
    activeTab,
  } = useEolasNavigation();

  const defaultCoverTheme: BackgroundCoverTheme = {
    className: BackgroundClassName.SpaceBackground,
    backgroundImage: spaceBgLines,
  };

  if (customBrandColor) {
    return {
      className: BackgroundClassName.CustomBrandBackground,
      backgroundImage: spaceBgLines,
    };
  }
  if (isOnSelectSpaceRoot) {
    return {
      className: BackgroundClassName.SpaceBackground,
      backgroundImage: spaceBgLines,
    };
  }
  if (activeTab === "spaces") {
    return {
      className: BackgroundClassName.SpaceBackground,
      backgroundImage:
        isOnSelectSpaceRoot || (isOnSpacesRoot && !sectionStore.isAdmin) ? spaceBgLines : null,
    };
  }
  if (activeTab === "organisation") {
    return {
      className: BackgroundClassName.OrganisationBackground,
      backgroundImage: isOnOrganisationRoot && !sectionStore.isAdmin ? orgBgLines : null,
    };
  }
  if (activeTab === "knowledge") {
    return {
      className: BackgroundClassName.KnowledgeBackground,
      backgroundImage: isOnKnowledgeRoot ? knowledgeBgLines : null,
    };
  }
  if (activeTab === "my-profile") {
    return {
      className: BackgroundClassName.MeBackground,
      backgroundImage: isOnMeRoot ? meBgLines : null,
    };
  }
  return defaultCoverTheme;
};

export default useBackgroundCoverTheme;
