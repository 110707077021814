import React, { useCallback } from "react";

import { DeleteIcon, EditMonoIcon } from "Assets";

import { IconButton } from "../../Button";
import { ControlsWrapper, FileTileProps } from "./";

type DefaultFileControlsProps = Omit<FileTileProps, "isAdmin" | "onSelectFile">;

export const DefaultFileControls: React.FC<DefaultFileControlsProps> = ({
  onEdit,
  onDelete,
  eolasFile,
}: DefaultFileControlsProps) => {
  const _onEdit = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onEdit?.(eolasFile);
    },
    [eolasFile, onEdit],
  );

  const _onDelete = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onDelete?.(eolasFile);
    },
    [eolasFile, onDelete],
  );

  return (
    <ControlsWrapper>
      {onDelete ? (
        <IconButton
          size="sm"
          onClick={_onDelete}
          icon={<DeleteIcon />}
          data-testid={`deleteButtonFor-${eolasFile.name}`}
        />
      ) : null}

      {onEdit ? (
        <IconButton
          size="sm"
          onClick={_onEdit}
          icon={<EditMonoIcon />}
          data-testid={`editButtonFor-${eolasFile.name}`}
        />
      ) : null}
    </ControlsWrapper>
  );
};
