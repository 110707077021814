import React, { useCallback, useMemo } from "react";
import { Text, IconButton, DropdownOption, Dropdown, OperationsButton, CircleIcon } from "UIKit";
import { AdminIcon, DeleteIcon, EditMonoIcon } from "Assets";
import { ChecklistNotifications } from "../types";
import { useTranslation } from "react-i18next";
import OwnerNotification from "./OwnerNotification";
import { useMediaQuery } from "Hooks";
import { useSpaceAdmins } from "modules/spaces/data/useSpaceAdmins";
import { useSpacesContext } from "modules/spaces";

interface OwnerTileProps {
  id: string;
  activeNotifications: ChecklistNotifications[];
  onRemoveOwner: (id: string) => void;
  onEditOwner: () => void;
}
const OwnerTile: React.FC<OwnerTileProps> = ({
  id,
  activeNotifications,
  onRemoveOwner,
  onEditOwner,
}: OwnerTileProps) => {
  const { t } = useTranslation();
  const { selectedSpace } = useSpacesContext();

  const media = useMediaQuery();
  const isMobile = media === "xs";

  const notificationMap = useMemo(
    () => ({
      [ChecklistNotifications.ISSUES_FLAGGED]: t("checklistBuilder_issues_are_flagged"),
      [ChecklistNotifications.IS_COMPLETED]: t("checklistBuilder_checklist_is_completed"),
    }),
    [t],
  );

  const { spaceAdmins } = useSpaceAdmins(selectedSpace?.id || "");
  const user = spaceAdmins.find(({ userID }: { userID: string }) => userID === id);

  const actionOptions = useMemo(() => {
    const sharedOptions: DropdownOption[] = [
      {
        label: t("general_edit"),
        value: "edit",
        icon: <EditMonoIcon width={16} height={16} />,
      },
      {
        label: t("general_delete"),
        value: "delete",
        icon: <DeleteIcon width={16} height={16} />,
      },
    ];

    return sharedOptions;
  }, [t]);

  const handleActionMenuSelect = useCallback(
    (option: DropdownOption) => {
      if (option.value === "edit") onEditOwner();
      if (option.value === "delete") onRemoveOwner(id);
    },
    [id, onEditOwner, onRemoveOwner],
  );

  if (!user) return <></>;

  return (
    <div className="grid grid-cols-12 gap-3 rounded-lg bg-white p-5 mb-3">
      <div className="flex items-center col-start-1 col-span-8 xl:col-span-4">
        <CircleIcon height="24" width="24" icon={<AdminIcon className="w-8 h-8" />} />
        <Text level={1} className="text-lg ml-3">
          {user.user?.given_name ?? ""} {user.user?.family_name ?? ""}
        </Text>
      </div>
      <div className="flex items-center col-start-1 col-span-12 xl:col-span-4 xl:col-start-6">
        {activeNotifications.sort().map((n) => (
          <OwnerNotification key={n} text={notificationMap[n]} />
        ))}
      </div>
      <div className="flex col-start-9 col-span-4 row-start-1 xl:row-start-auto xl:col-start-auto xl:col-span-3 justify-end">
        {isMobile ? (
          <Dropdown
            dropdownToggle={<OperationsButton className="static" size="xs" />}
            options={actionOptions}
            onSelect={handleActionMenuSelect}
          />
        ) : (
          <>
            <IconButton data-testid="edit-button" onClick={onEditOwner} icon={<EditMonoIcon />} />
            <IconButton
              data-testid="delete-button"
              className="ml-3"
              onClick={() => {
                onRemoveOwner(id);
              }}
              icon={<DeleteIcon />}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default OwnerTile;
