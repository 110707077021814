import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { NavBackButton } from "shared/components/LogicComponents";
import { Button, InnerPageWrapper, Text, Title } from "UIKit";
import { ClinicalQuestion } from "Pages/Knowledge/pages/ClinicalQuestions/hooks/useClinicalQuestionInput";
import { Control, FieldError, UseFormReset, UseFormSetValue } from "react-hook-form";
import { BrowseButton } from "Pages/Knowledge/pages/ClinicalQuestions/components/BrowseButton";
import { titleColors } from "Pages/Knowledge/pages/ClinicalQuestions/styles/style";
import { NewQuestionView } from "Pages/Knowledge/pages/ClinicalQuestions/components/NewQuestionView";
import { ResponseCards } from "Pages/Knowledge/pages/ClinicalQuestions/components/ResponseCards";
import { BaseAppRoute } from "Utilities/types";

export type AskEolasVariants = "knowledge" | "space";

type AskEolasProps = {
  type: AskEolasVariants;
  control: Control<ClinicalQuestion>;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
  errors: {
    question?: FieldError | undefined;
    notes?: FieldError | undefined;
  };
  progress: number;
  reset: UseFormReset<ClinicalQuestion>;
  isSubmitSuccessful: boolean;
  setValue: UseFormSetValue<ClinicalQuestion>;
  handleResetQuestion: () => void;
  handleSubmitQuestion: () => Promise<void>;
  showNewQuestion: boolean;
  shouldShowBrowserButton?: boolean;
};

export const AskEolasHome = ({
  control,
  onSubmit,
  errors,
  progress,
  handleResetQuestion,
  handleSubmitQuestion,
  isSubmitSuccessful,
  setValue,
  showNewQuestion,
  shouldShowBrowserButton,
  type,
}: AskEolasProps) => {
  const basePath = type === "space" ? `/${BaseAppRoute.spaces}` : `/${BaseAppRoute.knowledge}`;
  const shouldShowNewQuestionButton = isSubmitSuccessful && !showNewQuestion;
  const shouldShowResponseCards = isSubmitSuccessful && !showNewQuestion;
  const { t } = useTranslation();
  return (
    <InnerPageWrapper>
      <div className="flex flex-col justify-start items-center flex-1 w-full pt-4 mb-12">
        <div className="flex-col mb-8 flex items-center gap-4">
          <div className="flex gap-4 mb-10">
            <NavBackButton modePreference="removeFromPath" overrideUrl={basePath} />
            {shouldShowBrowserButton && !shouldShowNewQuestionButton ? (
              <BrowseButton />
            ) : (
              <NewQuestionButton onClick={handleResetQuestion} />
            )}
          </div>
          <div>
            {t("ask_eolas")
              .split("")
              .map((el: string, i: number) => (
                <motion.span
                  style={{ display: "inline-block" }}
                  animate={{
                    opacity: 1,
                    color: titleColors,
                  }}
                  transition={{
                    duration: 1,
                    delay: i / 10,
                    repeat: 1,
                    repeatType: "loop",
                  }}
                  key={i}
                >
                  <Title level={4} className="font-extrabold">
                    {el === " " ? "\u00A0" : el}
                  </Title>
                </motion.span>
              ))}
          </div>
        </div>
        {showNewQuestion || !isSubmitSuccessful ? (
          <NewQuestionView
            setValue={setValue}
            onSubmit={handleSubmitQuestion}
            control={control}
            errorMessage={errors ? errors.question?.message : undefined}
            type={type}
          />
        ) : null}

        {shouldShowResponseCards ? (
          <>
            <motion.div
              initial={{ opacity: 0, scaleY: 0, originY: 0.5 }}
              animate={{ opacity: 1, scaleY: 1 }}
              transition={{ duration: 0.8 }}
              className="w-full"
            >
              <ResponseCards
                followUpInputError={errors.notes}
                onSubmit={onSubmit}
                progress={progress}
                control={control}
                type={type}
              />
            </motion.div>
          </>
        ) : null}
      </div>
    </InnerPageWrapper>
  );
};

export const NewQuestionButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <Button
      size="md"
      weight="bold"
      variant="solid"
      onClick={onClick}
      iconLeft="NewQuestionIcon"
      className="self-center border-darkblue-900 rounded-md bg-darkblue-900 hover:bg-white hover:text-black hover:border hover:border-blue-500"
    >
      <Text level={1} className="ml-2">
        {t("new_question")}
      </Text>
    </Button>
  );
};
