import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { AppLevelSection } from "@eolas-medical/core";

import { useMainSectionVisit } from "Hooks";

import {
  Modal,
  Input,
  Button,
  Toggle,
  Textarea,
  PageTitle,
  NavButton,
  DatePicker,
  FormElement,
  SuccessModal,
  InnerPageWrapper,
} from "UIKit";
import useSpaceSection from "modules/spaces/data/useSpaceSection";
import { useAddEolasFile } from "Pages/Spaces/components/AddEolasFile";

const filterDateFn = (date: Date) => {
  const today = new Date();
  return date.valueOf() < today.valueOf();
};

export const ClientIncidentReportingForm: React.FC<RouteComponentProps> = observer(
  ({ match: { url }, history: { push } }) => {
    const { t } = useTranslation();
    useMainSectionVisit({ mainSectionId: AppLevelSection.incidentReporting });

    const { spaceSection } = useSpaceSection(AppLevelSection.incidentReporting);

    const { control, onSubmit, error, isLoading, isSuccessful, isFormComplete } = useAddEolasFile({
      mainSectionID: AppLevelSection.incidentReporting,
    });

    const goBack = () => push(url.replace("/incidentReporting", ""));

    if (isSuccessful) {
      return (
        <Modal
          open={true}
          onClose={() => {
            return;
          }}
        >
          <SuccessModal
            text="incident_reporting_successful_toast"
            onComplete={() => {
              goBack();
            }}
          />
        </Modal>
      );
    }

    return (
      <>
        <InnerPageWrapper>
          <PageTitle title={spaceSection.name ?? t("incident_reporting_form_title")} />
          <NavButton to={url.replace("/incidentReporting", "")} />

          <form onSubmit={onSubmit} className="flex flex-col space-y-4">
            <FormElement
              required
              id="name"
              control={control}
              label={t("incident_reporting_title_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  data-testid="incidentsTitle"
                  placeholder={t("incident_reporting_title_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              id="info.date"
              control={control}
              data-testid="incidentDate"
              label={t("incident_reporting_date_label")}
            >
              {({ value, onChange, onBlur }, { error }) => {
                return (
                  <DatePicker
                    showTime
                    onBlur={onBlur}
                    error={error}
                    selected={value}
                    shouldCloseOnSelect
                    onChange={onChange}
                    filterDate={filterDateFn}
                    dateFormat="dd MMM yyyy HH:mm"
                  />
                );
              }}
            </FormElement>

            <FormElement
              required
              id="info.location"
              control={control}
              label={t("incident_reporting_location_label")}
            >
              {(field) => (
                <Input
                  {...field}
                  data-testid="incidentLocation"
                  placeholder={t("incident_reporting_location_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              id="info.details"
              control={control}
              label={t("incident_reporting_details_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="incidentDetails"
                  placeholder={t("incident_reporting_details_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="info.immediateActions"
              label={t("incident_reporting_immediate_actions_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="incidentActions"
                  placeholder={t("incident_reporting_immediate_actions_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              control={control}
              id="info.contributingFactors"
              label={t("incident_reporting_contributing_factors_label")}
            >
              {(field) => (
                <Textarea
                  {...field}
                  data-testid="contributingFactors"
                  placeholder={t("incident_reporting_contributing_factors_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              id="info.critical"
              control={control}
              label={t("incident_reporting_critical_event_label")}
            >
              {({ value, ...field }) => {
                return (
                  <Toggle
                    checked={value}
                    {...field}
                    label={t("incident_reporting_critical_event_placeholder")}
                  />
                );
              }}
            </FormElement>

            <p className="text-sm lg:text-base">
              <span>{t("incident_reporting_critical_event_notice")} </span>
              {t("incident_reporting_critical_event_notice_info")}
            </p>

            {isFormComplete ? (
              <Button type="submit" isLoading={isLoading} className="self-center w-full sm:w-1/3">
                {t("incident_reporting_submit_button_label")}
              </Button>
            ) : null}

            {error ? <span className="text-center text-red">{error}</span> : null}
          </form>
        </InnerPageWrapper>
      </>
    );
  },
);
