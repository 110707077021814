import { useHistory } from "react-router-dom";

import { AppLevelSection, EolasFile, Section, sectionStore } from "@eolas-medical/core";

import { INameable } from "Utilities/helpers";
import { FavouriteToggle, FileTileWrapper } from "UIKit";

import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { observer } from "mobx-react-lite";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";

type Props = {
  checklist: Section & INameable;
  url: string;
  isAdmin: boolean;
  onShare?: (checklist: Section & INameable) => void;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
};

export const ChecklistTile = observer(
  ({ checklist, url, onShare, showRemoveFavouriteModal }: Props) => {
    const history = useHistory();

    const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
      useAddFavourite(checklist.id);

    const isAdmin = sectionStore.isAdmin;
    const shouldShowShareButton = !!onShare && !isAdmin;
    const shouldShowFavouriteButton = isMarkFavouriteEnabled && !isAdmin;

    return (
      <FileTileWrapper
        eolasFile={{} as EolasFile}
        key={checklist?.id}
        data-testid={`checklistTile-${checklist.name}`}
        onClick={() => history.push(`${url}/details/${encodeURIComponent(checklist?.id)}`)}
      >
        <span className="flex-1 text-lg font-semibold line-clamp-2">{checklist.name}</span>

        <div>
          {shouldShowFavouriteButton ? (
            <FavouriteToggle
              entityId={checklist.id}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: checklist.id,
                      favouriteId,
                      title: checklist.name ?? "",
                      entityType: FavouriteEntityType.CHECKLIST_TEMPLATE,
                      originForAnalytics: AppLevelSection.checklists,
                    })
                  : handleAddFavourite({
                      item: checklist,
                      entityType: FavouriteEntityType.CHECKLIST_TEMPLATE,
                    })
              }
              isLoading={!!isMarkingAsFavourite}
            />
          ) : null}

          {shouldShowShareButton ? (
            <div className="w-fit self-start flex flex-col items-center justify-between h-full">
              <ShareFileButton id={checklist.id} onShareFile={() => onShare(checklist)} />
            </div>
          ) : null}
        </div>
      </FileTileWrapper>
    );
  },
);
