import { Redirect, useHistory, useParams } from "react-router-dom";

import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { AutoAccessBaseModal } from "../modals/AutoAccessBaseModal";
import { BaseAppRoute } from "Utilities/types";
import { useSignoutSpace } from "Hooks/useSignoutSpace";
import { LoadingOverlay } from "UIKit";
import useUserDetails from "Hooks/useUserDetails";
import { logout } from "Pages/Authentication/auth.actions";

import { useSpacesContext } from "modules/spaces";
import { useRunOnMountUnmount } from "Hooks";
import BackgroundCover from "Components/PageContainer/components/BackgroundCover/BackgroundCover";
import spaceBgLines from "Assets/Img/space-background-lines.svg";

export type AccessLinkToken = `${string}-${string}-${string}-${string}`;

interface TokenParams {
  token: AccessLinkToken;
}

const AccessLink = () => {
  const { token } = useParams<TokenParams>();
  const { isLoggedIn, isInOrganisation } = userStore.userSession;
  const { userID } = userStore;
  const { push } = useHistory();
  const { signoutSpace } = useSignoutSpace({ shouldPushNewRoute: true });
  const { isSharedAccount } = useUserDetails();

  const { onClearSpace } = useSpacesContext();
  const logoutUser = async () => {
    await logout({ shouldSyncTabs: false });
    onClearSpace();
    return <Redirect to={`/access-link/${token}`} />;
  };

  useRunOnMountUnmount({
    onMount: () => {
      if (isSharedAccount) {
        logoutUser();
      } else {
        if (userID) {
          if (!isLoggedIn) {
            userStore.setLoggedStatus(true);
          }
          if (isInOrganisation) {
            signoutSpace();
          } else {
            push(`/${BaseAppRoute.selectSpace}`);
          }
        }
      }
      if (token) userStore.setAccessLinkId(token);
      trackEvent(AnalyticsEvents.INVITE_VIA_ACCESS_LINK_STARTED);
    },
  });

  if (!isLoggedIn) {
    return (
      <>
        <BackgroundCover overrideImage={spaceBgLines} />
        <div className="flex justify-center items-center h-screen">
          <AutoAccessBaseModal
            linkType="access"
            modalState="pleaseLogin"
            handleCloseModal={() => {
              push("/");
            }}
          />
        </div>
      </>
    );
  }

  return <LoadingOverlay />;
};

export default AccessLink;
