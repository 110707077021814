import { PatientGroup, PrepContentIndicationAndDoseGroup } from "@eolas-medical/core";
import { Text } from "UIKit";
import { removeTags } from "modules/bnf/helpers";
import { Fragment } from "react";
import BnfPill from "../../BnfPill/BnfPill";
import DoseStatement from "./DoseStatement";

interface IndicationsAndGroupProps {
  group: PrepContentIndicationAndDoseGroup;
}

const IndicationsAndDoseGroup = ({ group }: IndicationsAndGroupProps) => {
  const { therapeuticIndications, routesAndPatientGroups } = group;
  return (
    <div className="flex flex-col space-y-2 my-6">
      <div className="flex flex-col space-y-2">
        {therapeuticIndications.map((indication) => {
          return (
            <Fragment key={indication.indication}>
              <Text level={1}>{removeTags(indication.indication)}</Text>
            </Fragment>
          );
        })}
      </div>
      <div className="space-y-2">
        {routesAndPatientGroups.map((routesAndDoseGroup, index) => {
          return (
            <div
              key={routesAndDoseGroup.routeOfAdministration + index}
              className="flex flex-col space-y-1"
            >
              <Text level={2} className="italic">
                {routesAndDoseGroup.routeOfAdministration}
              </Text>
              <div className="flex flex-col gap-4">
                {routesAndDoseGroup.patientGroups.map((doseGroup, index2) => {
                  const patientGroup: PatientGroup = doseGroup.patientGroup;
                  const doseStatement = doseGroup.doseStatement;

                  return (
                    <div
                      key={doseGroup.patientGroup + index2}
                      className="flex flex-col space-y-4 mt-2"
                    >
                      <BnfPill value={doseGroup.detailedPatientGroup} type={patientGroup} />
                      <DoseStatement type={patientGroup} text={doseStatement} />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IndicationsAndDoseGroup;
