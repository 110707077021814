import { Variants, motion } from "framer-motion";
import { Dropdown, FileTypeItemIcon, IconButton, Text, Title, FavouriteToggle } from "UIKit";
import { LinkIcon, OperationsIcon } from "Assets";
import { format, isValid, parseISO } from "date-fns";
import { getImageUrl } from "Utilities";
import {
  CommunityLevelSection,
  ContentItem,
  EolasFile,
  FlashCardHeroImage,
  SupportedFileType,
  isFlashCardContentItem,
  sectionStore,
} from "@eolas-medical/core";
import { useTranslation } from "react-i18next";
import { ShareFileButton } from "UIKit/FileTile/shared/ShareFileButton";
import { useAddFavourite } from "shared/hooks/useAddFavourite";
import { FavouriteEntityType } from "@eolas-medical/core/lib/src/clients/myFavourites";
import { isSmpcContentItem } from "modules/myFavourites/typeguards";
import { HandleClickRemoveFavouriteProps } from "Components/RemoveFavouriteModal/RemoveFavouriteModal";
import { SelectFileToggleProps } from "UIKit/Button/SelectFileToggle";
import { EolasFileTile } from "UIKit/EolasFileTile/EolasFileTile";
import { useToolTip } from "Hooks/useToolTip";
import { isShadowCopyContentItem } from "../../functions/typeguards";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useAdminOperationsContentItem } from "Hooks/useAdminOperationsContentItem";
import { IsCopyableStatus } from "../ContentItemInstance/types";
import { useAdminOperationsSpaceOrgFile } from "Hooks/useAdminOperationsSpaceOrgFile";
import useContentRepositoryContext from "shared/pages/ContentRepository/context/useContentRepositoryContext";

export interface ContentItemTileProps {
  eolasFile: EolasFile | null;
  item: ContentItem;
  isInAdminMode: boolean;
  contentType: SupportedFileType;
  description?: string | null;
  date?: string | null;
  "data-testid"?: string;
  isDraggable?: boolean;
  isDragging?: boolean;
  dateLabel?: string;
  onShare?: () => void;
  onClick?: () => void;
  shouldAllowFavourite?: boolean;
  shouldTruncateDescription?: boolean;
  shouldShowDate?: boolean;
  imageUrl: string | null;
  showRemoveFavouriteModal?: ({
    entityId,
    entityType,
    favouriteId,
  }: HandleClickRemoveFavouriteProps) => void;
  isSearchResult?: boolean;
  flashcardsHaveSpecialties: boolean;
  handleModalChange: (modal: React.ReactNode) => void;
  handleCloseModal: () => void;
  isCopyableStatus?: IsCopyableStatus;
}

const childrenVariants: Variants = {
  out: { x: -25, opacity: 0 },
  in: { x: 0, opacity: 1 },
};

const ContentItemTile = ({
  isInAdminMode,
  contentType,
  description,
  date,
  "data-testid": dataTestId,
  isDraggable,
  isDragging,
  dateLabel = "Date",
  onClick,
  imageUrl,
  onShare,
  showRemoveFavouriteModal,
  item,
  isSelected,
  onCheckboxClick,
  shouldAllowFavourite,
  isSearchResult = false,
  shouldTruncateDescription = true,
  shouldShowDate = true,
  flashcardsHaveSpecialties,
  handleModalChange,
  handleCloseModal,
  isCopyableStatus,
  eolasFile,
}: ContentItemTileProps & Partial<SelectFileToggleProps>): JSX.Element => {
  const { name, id, isDraft } = item;
  const { t } = useTranslation();
  const { makeToolTipDataAttr } = useToolTip();
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.();
  };
  const { contextDomain } = useContentRepositoryContext();
  const { isMarkingAsFavourite, handleAddFavourite, favouriteId, isMarkFavouriteEnabled } =
    useAddFavourite(id);
  const shouldShowFavouriteToggle = isMarkFavouriteEnabled && shouldAllowFavourite;
  const isItemShadowCopy = isShadowCopyContentItem(item);

  const {
    handleActionMenuSelect: handleMenuSelectKnowledge,
    menuOptions: menuOptionsKnowledge,
    status,
  } = useAdminOperationsContentItem({
    flashcardsHaveSpecialties,
    item,
    contentType,
    isUnpublished: !!isDraft,
    onShare,
    handleModalChange,
    handleCloseModal,
    isShadowCopy: isItemShadowCopy,
    isInViewer: false,
    isCopyableStatus,
    isInAdminMode,
  });

  const { handleActionMenuSelect, menuOptions } = useAdminOperationsSpaceOrgFile({
    item: eolasFile,
    isUnpublished: !!eolasFile?.isDraft,
    onShare: eolasFile ? onShare : undefined,
    isShadowCopy: isItemShadowCopy,
    isInViewer: false,
    isInAdminMode,
  });
  const parsedDate = !!date && date !== "null" ? parseISO(date) : undefined;

  const favouriteOrigin = isSmpcContentItem(item.metadata)
    ? CommunityLevelSection.communityMedications
    : sectionStore.getMainSectionIdentityByMainSectionId(item.mainSectionId);

  if (isInAdminMode) {
    const shouldShowExpiryDate =
      !!parsedDate && isValid(parsedDate) && !isFlashCardContentItem(item);

    return (
      <motion.div variants={childrenVariants} data-testid={dataTestId}>
        <EolasFileTile
          variant="admin"
          onClick={handleClick}
          title={name}
          expiryDate={shouldShowExpiryDate ? parsedDate : undefined}
          iconComponent={
            <div className="flex items-center justify-center h-full w-full">
              <FileTypeItemIcon type={contentType} className="w-12 h-12" />
            </div>
          }
          isDraggable={isDraggable}
          status={status}
          rightLabelTagTooltipText={{
            parallelDraft: t("content_item_parallel_draft_tooltip"),
            unpublished: t("content_item_unpublished_tooltip"),
            published: t("content_item_published_tooltip"),
          }}
          isSelected={isSelected}
          onToggleSelect={onCheckboxClick}
          menuActions={contextDomain === "spaceOrg" ? menuOptions : menuOptionsKnowledge}
          onMenuActionSelect={
            contextDomain === "spaceOrg" ? handleActionMenuSelect : handleMenuSelectKnowledge
          }
          isShadowCopy={isItemShadowCopy}
          isSearchResult={isSearchResult}
          mainSectionId={item.mainSectionId}
          parentSectionId={item.parentId}
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      variants={childrenVariants}
      whileHover={{ scale: 1.01 }}
      className={`grid grid-cols-12 transition-all rounded-lg shadow-sm bg-white ${
        onClick ? "cursor-pointer" : ""
      } ${isDragging ? "tile-border__dragging" : "tile-border"}`}
      // FIXME: For some reason tailwind isn't applying min-h-40 to the div??
      style={{ minHeight: "10rem" }}
      onClick={handleClick}
      data-testid={dataTestId}
    >
      <div className="row-start-1 col-start-1 col-end-4 lg:col-end-3 flex bg-blue-50 rounded-l-lg relative">
        {imageUrl && imageUrl !== FlashCardHeroImage.blankImage ? (
          <img
            src={getImageUrl(imageUrl)}
            srcSet={getImageUrl(imageUrl)}
            alt=""
            className="w-full h-full object-cover rounded-l-lg"
          />
        ) : (
          <div className="flex items-center justify-center w-full h-full">
            <FileTypeItemIcon type={contentType} className="w-12 h-12" />
          </div>
        )}
      </div>

      <div
        className={`row-start-1 col-start-4 ${
          !isInAdminMode ? "col-end-12" : "col-end-10"
        } lg:col-start-3 flex flex-col justify-between py-5 pl-5 pr-0`}
      >
        <div>
          <div className="flex flex-row items-center gap-2">
            <div className="flex flex-col">
              {isSearchResult ? (
                <FileBreadcrumbs
                  shouldShowSpaceName={false}
                  mainSectionId={item.mainSectionId}
                  parentId={item.parentId}
                />
              ) : null}
              <Title className="line-clamp-2 font-semibold" level={8}>
                {name}
              </Title>
            </div>
            {isItemShadowCopy ? (
              <LinkIcon
                className="h-5 w-5 text-grey-600"
                {...makeToolTipDataAttr({
                  text: t("component_admin_file_tile_shadow_copy_tooltip"),
                })}
              />
            ) : null}
          </div>
          {description ? (
            <Text
              level={1}
              className={`text-grey-700 ${shouldTruncateDescription ? "line-clamp-2" : ""}`}
            >
              {description}
            </Text>
          ) : null}
        </div>

        {shouldShowDate && parsedDate && isValid(parsedDate) ? (
          <Text level={1} className="text-xs">
            {dateLabel}
            <span className="font-semibold ml-3">
              {format(new Date(parsedDate), "do MMMM yyyy")}
            </span>
          </Text>
        ) : null}
      </div>
      <div className="row-start-1 row-end-2 col-start-13 flex p-5 flex-col items-end justify-start">
        {menuOptions.length > 0 || menuOptionsKnowledge.length > 0 ? (
          <Dropdown
            dropdownToggle={
              <IconButton
                type="button"
                variant="rounded"
                icon={<OperationsIcon />}
                className="bg-transparent"
              />
            }
            options={contextDomain === "spaceOrg" ? menuOptions : menuOptionsKnowledge}
            onSelect={
              contextDomain === "spaceOrg" ? handleActionMenuSelect : handleMenuSelectKnowledge
            }
          />
        ) : null}
        {showRemoveFavouriteModal && shouldShowFavouriteToggle ? (
          <div className="self-end">
            <FavouriteToggle
              isLoading={isMarkingAsFavourite}
              onClick={() =>
                favouriteId && showRemoveFavouriteModal
                  ? showRemoveFavouriteModal({
                      entityId: id,
                      favouriteId,
                      title: name,
                      entityType: isSmpcContentItem(item.metadata)
                        ? FavouriteEntityType.SMPC_CONTENT
                        : FavouriteEntityType.FILE,
                      originForAnalytics: favouriteOrigin ?? undefined,
                    })
                  : handleAddFavourite({
                      item,
                      entityType: isSmpcContentItem(item.metadata)
                        ? FavouriteEntityType.SMPC_CONTENT
                        : FavouriteEntityType.FILE,
                    })
              }
              entityId={id}
            />
          </div>
        ) : null}

        {onShare ? (
          <div className="self-end">
            <ShareFileButton id={id} onShareFile={onShare} />
          </div>
        ) : null}
      </div>
    </motion.div>
  );
};

export default ContentItemTile;
