import { adminPanelTour, spaceBackground } from "Assets";
import React from "react";
import { Button, InnerModalWrapper, LottieWithHeader } from "UIKit";
import { useTranslation } from "react-i18next";

interface GetStartedSpaceModalProps {
  onClickExploreAdmin: () => void;
  onClickInvitePeople: () => void;
}

const GetStartedSpaceModal = ({
  onClickInvitePeople,
  onClickExploreAdmin,
}: GetStartedSpaceModalProps) => {
  const { t } = useTranslation();

  const baseStep = (
    <div className="flex flex-col h-full">
      <div className="flex-1 relative">
        <img
          src={spaceBackground}
          alt=""
          className="absolute inset-0 w-full object-cover h-full"
          style={{
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
          }}
        />
        <LottieWithHeader
          animationSize="46.7%"
          animation={adminPanelTour}
          lottieOptions={{ loop: true }}
          text={""}
        />
      </div>

      <div className="flex flex-col text-center justify-center items-center mx-auto p-1 w-full md:w-11/12 space-y-2 mt-8">
        <div className=" text-lg md:text-xl">{t("get_started_space_modal_title")}</div>
        <div className="text-md lg:text-lg font-semibold">
          {t("get_started_space_modal_description")}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-y-2 space-x-4 p-10">
        <Button
          variant="outline"
          size="sm"
          className="text-black ml-3 w-full sm:w-4/5"
          color="grey"
          iconLeft="AddUserIcon"
          onClick={onClickInvitePeople}
        >
          {t("get_started_space_modal_invite_button")}
        </Button>
        <Button
          size="sm"
          className="ml-3 w-full sm:w-4/5"
          iconLeft="ExploreIcon"
          onClick={onClickExploreAdmin}
        >
          {t("get_started_space_modal_explore_button")}
        </Button>
      </div>
    </div>
  );

  return (
    <InnerModalWrapper className="xl:w-50vw h-95vh max-w-screen-lg">
      <div>{baseStep}</div>
    </InnerModalWrapper>
  );
};

export default GetStartedSpaceModal;
