import { SearchTypeSection } from "Components/MasterSearch/types";
import { Text, Title } from "UIKit";
import useKnowledgeSections from "modules/mainSections/data/useKnowledgeSections";
import { useTranslation } from "react-i18next";

enum SearchableKnowledgeSections {
  NationalGuidelines = "National Guidelines",
  ClinicalCalculators = "Clinical Calculators",
  Medications = "Medications",
  NICE = "NICE Guidelines",
  WikEm = "WikEM",
  Leaflets = "National Patient Leaflets",
}

const isSearchableKnowledgeSection = (
  sectionName: string,
): sectionName is SearchableKnowledgeSections => {
  return Object.values<string>(SearchableKnowledgeSections).includes(sectionName);
};

const SearchDisclaimer = ({ searchType }: { searchType: SearchTypeSection }) => {
  const { t } = useTranslation();
  const textColor = "text-grey-600";
  const { knowledgeSections } = useKnowledgeSections();
  const knowledgeSectionNames = knowledgeSections.map((section) => section.name);
  const searchableKnowledgeSectionNames = knowledgeSectionNames.filter(
    isSearchableKnowledgeSection,
  );
  const renderLocalDisclaimer = () => {
    return (
      <>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_space")}
          </Text>
        </li>
        <li>
          <Text level={1} className={`${textColor}`}>
            {t("master_search_desclaimer_organisation")}
          </Text>
        </li>
      </>
    );
  };

  const renderKwowledgeDisclaimer = () => {
    if (!searchableKnowledgeSectionNames) return null;
    return (
      <>
        {searchableKnowledgeSectionNames.map((sectionName: SearchableKnowledgeSections) => (
          <li key={sectionName}>
            <Text level={1} className={`${textColor}`}>
              {sectionName}
            </Text>
          </li>
        ))}
      </>
    );
  };

  return (
    <div className="p-3 -mt-8">
      <Title level={6} className={`${textColor} my-4`}>
        {t("master_search_desclaimer_title")}
      </Title>
      <ul className={`list-disc ${textColor} px-3 space-y-2`}>
        {searchType == "local" ? renderLocalDisclaimer() : renderKwowledgeDisclaimer()}
      </ul>
    </div>
  );
};

export default SearchDisclaimer;
