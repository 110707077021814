import { CircleIcon, InnerModalWrapper, ModalBody, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { startedBackground } from "Assets";
import { useGetStartedSteps } from "./hooks/useGetStartedSteps";

interface GetStartedSection {
  icon: JSX.Element;
  titleKey: string;
  descriptionKey: string;
  onClick: () => void;
  titleColor: string;
}

interface GetStartedUserModalProps {
  onGoToKnowledgeTab: () => void;
  onGoToMeTab: () => void;
  onGoToSpacesTab: () => void;
  onGoToCreateSpace: () => void;
}

const GetStartedUserModal = (goToRoutesProps: GetStartedUserModalProps) => {
  const { t } = useTranslation();

  const { getStartedSteps } = useGetStartedSteps(goToRoutesProps);

  const renderSection = (section: GetStartedSection, index: number) => (
    <div
      key={index}
      className="w-80 h-60 flex flex-col justify-center items-center space-y-4 border border-grey-300 rounded-md shadow z-10 bg-white hover:border-blue-500 cursor-pointer"
      onClick={section.onClick}
    >
      <div className="bg-neutral-100 rounded-md justify-center items-center gap-2 inline-flex">
        <CircleIcon
          width="w-20"
          height="h-20"
          icon={section.icon}
          className="rounded-lg"
          backgroundColor="grey"
        />
      </div>
      <div className="flex flex-col justify-center items-center space-y-4">
        <div className={`w-64 h-5 text-center text-${section.titleColor} text-lg`}>
          {t(section.titleKey)}
        </div>
        <div className="w-64 text-center text-black text-sm font-semibold">
          {t(section.descriptionKey)}
        </div>
      </div>
    </div>
  );

  return (
    <InnerModalWrapper className="max-h-90vh overflow-y-auto">
      <ModalBody className="relative">
        <div className="flex flex-col items-center justify-center text-center p-8 pt-2 z-20">
          <Title level={5}>{t("get_started_modal_title")}</Title>
          <Title level={5}>{t("get_started_modal_subtitle")}</Title>
        </div>
        <div className="w-full flex flex-wrap justify-center items-center gap-4 bg-white relative overflow-y-auto">
          {getStartedSteps.map(renderSection)}
        </div>
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            background: "linear-gradient(196deg, #F5F7F8 21.83%, #C2D5FA 67.02%, #C2D5FA 92.53%)",
            zIndex: 0,
          }}
        />
        <div className="absolute bottom-0 left-0 w-full">
          <img src={startedBackground} className="w-full object-cover" alt="" />
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default GetStartedUserModal;
