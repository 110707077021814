import { Orama, TypedDocument, Results } from "@orama/orama";

export type LocalSearchFile = {
  id: string;
  name: string;
  searchField: string;
  stringKeywords: string;
  keywords: [];
};

export const localSearchSchema = {
  name: "string",
  searchField: "string",
  keywords: "string[]",
  stringKeywords: "string",
} as const;

export type LocalFilesSearchDb = Orama<typeof localSearchSchema>;

export type SearchResults = Results<TypedDocument<LocalFilesSearchDb>>;
