import { Divider, Text } from "UIKit";
import BnfPill from "../../BnfPill/BnfPill";
import { MedicinalFormsStrings } from "./MedicinalForms.strings";
import BnfContentElement from "../../BnfContentElement/BnfContentElement";
import FormGroupLabel from "./FormGroupLabel";
import { BnfLabel, GeneralPreparation, MedicinalForm } from "@eolas-medical/core";
import BnfPreparation from "../../BnfPreparation/BnfPreparation";

const PREP_BASE_KEY = "eolas_prep";
const LABEL_BASE_KEY = "eolas_label";

const FormGroup = ({ form }: { form: MedicinalForm }) => {
  return (
    <Divider className="flex flex-col space-y-4">
      <div className="">
        <BnfPill value={form.form} type="drug" />
        {form.excipients ? (
          <div className="flex flex-col space-y-2 pt-4">
            <Text level={2}>{MedicinalFormsStrings.excipients}</Text>
            <BnfContentElement htmlContent={form.excipients} className="text-sm" />
          </div>
        ) : null}
        {form.electrolytes ? (
          <div className="flex flex-col space-y-2 pt-4">
            <Text level={2}>{MedicinalFormsStrings.electrolytes}</Text>
            <BnfContentElement htmlContent={form.electrolytes} />
          </div>
        ) : null}
      </div>
      {form.cautionaryAndAdvisoryLabels.length ? (
        <div className="flex flex-col space-y-2 pt-4">
          <Text level={1}>{MedicinalFormsStrings.labels}</Text>
          {form.cautionaryAndAdvisoryLabels.map((label: BnfLabel, index: number) => {
            return (
              <div key={LABEL_BASE_KEY + index} className="flex flex-col">
                <FormGroupLabel label={label} />
              </div>
            );
          })}
        </div>
      ) : null}
      {form.preps.length ? (
        form.preps.map((prep: GeneralPreparation, index: number) => {
          return (
            <div key={PREP_BASE_KEY + index} className="flex flex-col space-y-2 pt-4">
              <BnfPreparation prep={prep} />
            </div>
          );
        })
      ) : (
        <Text level={2} className="italic font-semibold">
          {MedicinalFormsStrings.noPreps(form.form)}
        </Text>
      )}
    </Divider>
  );
};

export default FormGroup;
