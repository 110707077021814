import { useRoleSwitcher } from "Components/Navigation/hooks";
import { AdminDashboardIconType } from "Hooks";
import { RequestAnalyticsModal } from "Pages/Spaces/components/RequestAnalyticsModal/RequestAnalyticsModal";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEffect } from "react";
import { modalStore } from "Stores/ModalStore";
import { useSpacesContext } from "../context";
import {
  AdminAnalyticsPayload,
  AnalyticsEvents,
  eolasLogger,
  sectionStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { sanitiseUrl } from "Utilities/helpers";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

type AdminDashboardSection = {
  title: string;
  route: string;
  description: string;
  id: AdminDashboardIconType;
  disabled?: boolean;
};

const limitedAdminSections: AdminDashboardSection[] = [
  {
    id: "contentManagement",
    title: "adminHome_cm_card_title",
    description: "adminHome_cm_card_description",
    route: "content-management",
  },
];

const adminSections: AdminDashboardSection[] = [
  ...limitedAdminSections,
  {
    id: "manageUsers",
    title: "adminHome_manage_users_card_title",
    description: "adminHome_manage_users_card_description",
    route: "manageUsers",
  },
  {
    id: "adminAnalytics",
    title: "adminHome_analytics_title",
    description: "adminHome_analytics_space_description",
    route: "analytics",
  },
];

const useSpaceAdminSections = () => {
  const fullAccessLevel = useGetAdminStatus({ activeTab: "spaces" });
  const { selectedSpace } = useSpacesContext();
  const { switchToUser } = useRoleSwitcher();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const handleAnalyticsClick = () => {
    if (selectedSpace?.analyticsUrl) {
      const sanitisedUrl = sanitiseUrl(selectedSpace.analyticsUrl);
      if (!sanitisedUrl) {
        showNotification({
          type: "error",
          description: t("request_analytics_error"),
          autoHideTimeout: 5000,
        });
        eolasLogger.error(new Error("Invalid analytics URL"), { url: selectedSpace.analyticsUrl });
        return;
      }
      window.open(sanitisedUrl, "_blank");
      trackEvent<AdminAnalyticsPayload>(AnalyticsEvents.ANALYTICS_ACCESSED, {
        spaceOrgId: sectionStore.appID,
      });
      return;
    }

    trackEvent<AdminAnalyticsPayload>(
      AnalyticsEvents.ANALYTICS_TILE_CLICKED_BEFORE_ACCESS_GRANTED,
      {
        spaceOrgId: sectionStore.appID,
      },
    );
    modalStore.openModal({
      name: "analyticsModal",
      variant: "component",
      Component: (
        <RequestAnalyticsModal
          spaceOrgName={selectedSpace?.name ?? ""}
          spaceOrgId={sectionStore.appID}
        />
      ),
    });
  };
  useEffect(() => {
    if (!isAccessLevelInAdminGroup(fullAccessLevel)) {
      switchToUser();
    }
  }, [fullAccessLevel, switchToUser]);

  if (fullAccessLevel === "admin") {
    return { activeAdminMainSections: adminSections, handleAnalyticsClick };
  }

  if (fullAccessLevel === "limitedAdmin") {
    return { activeAdminMainSections: limitedAdminSections };
  }

  return { activeAdminMainSections: [] };
};

export default useSpaceAdminSections;
