import { format } from "date-fns";

import { eolasLogger } from "@eolas-medical/core";
import { SpaceUser } from "clients/departments/types";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";

const NOT_AVAILABLE = "Not available";

const getAccessLevel = (user: SpaceUser) => {
  if (user.accessLevel === "admin") {
    if (user.limitedAccess?.length) {
      return "Limited Admin";
    }
    return "Full Admin";
  }
  return "Standard";
};

const generateCSVContent = (users: SpaceUser[]) => {
  const headers = [
    "First Name",
    "Family Name",
    "Email",
    "Standard/Limited Admin/Full Admin",
    "Created At",
  ];

  const rows = users.map((user) => [
    user.user?.given_name || NOT_AVAILABLE,
    user.user?.family_name || NOT_AVAILABLE,
    user.user?.email || NOT_AVAILABLE,
    getAccessLevel(user),
    format(new Date(user.createdAt), "dd MMMM yyyy"),
  ]);

  const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

  return csvContent;
};

const downloadCSV = (
  csvContent: string,
  fileName: string,
  showNotification: (outcome: "success" | "failure") => void,
) => {
  try {
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    showNotification("success");
  } catch {
    eolasLogger.error(new Error("Failed to export user CSV"));
    showNotification("failure");
  }
};

export const useExportUserCsv = () => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const handleExportUsers = (users: SpaceUser[], spaceOrgName?: string) => {
    const csvContent = generateCSVContent(users);
    const fileName = spaceOrgName ? `User List ${spaceOrgName}.csv` : "User List.csv";
    downloadCSV(csvContent, fileName, (outcome) => {
      showNotification({
        type: outcome === "success" ? "success" : "error",
        description: outcome === "success" ? t("export_users_success") : t("export_users_failure"),
      });
    });
  };

  return { handleExportUsers };
};
