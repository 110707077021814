import React, { useCallback, useEffect, useRef, useState } from "react";
import { useClickOutside } from "Hooks";
import { useToolTip } from "Hooks/useToolTip";

export interface DropdownOption {
  value:
    | "copy"
    | "delete"
    | "edit"
    | "download"
    | "publish"
    | "move"
    | "goToOriginal"
    | "versions"
    | "share"
    | "unpublish"
    | "preview";
  label: string;
  disabled?: boolean;
  disabledReason?: string;
  icon?: React.ReactElement;
}

interface DropdownProps {
  dropdownToggle: React.ReactElement;
  options: DropdownOption[];
  overrideAbsoluteHorizontalNeutral?: boolean;
  onSelect: (option: DropdownOption, event: React.MouseEvent) => void;
  disabled?: boolean;
}

export const Dropdown = ({
  dropdownToggle,
  options,
  disabled = false,
  onSelect,
  overrideAbsoluteHorizontalNeutral,
  ...rest
}: DropdownProps) => {
  const [on, setOn] = useState(false);
  const { makeToolTipDataAttr } = useToolTip();
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setOn(false));

  const handleClick = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    setOn((prev) => !prev);
  };

  const handleSelect = useCallback(
    (event: React.MouseEvent, option: DropdownOption) => {
      event.preventDefault();
      event.stopPropagation();
      onSelect(option, event);
      setOn(false);
    },
    [onSelect],
  );

  useEffect(() => {
    if (on && disabled) setOn(false);
  }, [disabled, on]);
  if (!options.length) {
    return null;
  }
  const horizontalClass = overrideAbsoluteHorizontalNeutral ? "" : "right-0";
  return (
    <div className="" {...rest}>
      {React.cloneElement(dropdownToggle, {
        ...dropdownToggle.props,
        onClick: handleClick,
        disabled,
        "data-testid": "dropdown-toggle",
      })}
      {on ? (
        <div
          className={`overflow-hidden absolute ${horizontalClass} rounded-md drop-shadow-dropdown z-10`}
          ref={dropdownRef}
          onMouseEnter={() => setOn(true)}
        >
          {options.map((option) => (
            <div key={option.value} className="relative">
              <button
                className={`flex items-center px-4 py-2 w-full text-left hover:bg-grey-hover ${
                  option.disabled || disabled ? "bg-grey-300 text-grey-600" : "bg-white"
                }`}
                {...(option.disabledReason && option.disabled
                  ? makeToolTipDataAttr({ text: option.disabledReason })
                  : {})}
                disabled={option.disabled}
                onClick={(event) => handleSelect(event, option)}
                data-testid={option.value}
              >
                {option.icon}
                <span className="ml-4" />
                {option.label}
              </button>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};
