import { FC } from "react";
import { Control, FieldValues, useController } from "react-hook-form";
import { format } from "date-fns";
import { Button, DatePicker, FormLabel, Text } from "UIKit";
interface DatePickerProps {
  control?: Control<FieldValues>;
  fieldName: string;
  onClick?: () => void;
  title: string;
  required?: boolean;
  placeholder?: string;
}
export const PortfolioDatePicker: FC<DatePickerProps> = ({
  control,
  fieldName,
  onClick,
  title,
  required = false,
  placeholder = "Select Date",
}: DatePickerProps) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ control, name: fieldName, rules: { required } });
  return (
    <div className="mt-2 mb-10">
      <FormLabel htmlFor={fieldName} required={required} labelClassName={"text-base sm:text-lg"}>
        {title}
      </FormLabel>
      <DatePicker
        className="self-start align-top mt-4"
        onChange={(selectedDate) => {
          onChange(new Date(selectedDate || value));
        }}
        customWrapper={
          <Button
            className="rounded-md w-full mt-0 self-start"
            iconLeft="CalendarMonoIcon"
            color="grey"
            variant="outline"
            onClick={onClick}
          >
            <Text className="mx-3 text-sm sm:text-base" level={1}>
              {value ? format(new Date(value), "do MMMM yyyy") : placeholder}
            </Text>
          </Button>
        }
      />
      <Text level={3} className="text-red-700 justify-center mx-auto">
        {error?.message || " "}
      </Text>
    </div>
  );
};
