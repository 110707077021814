import { sectionStore } from "@eolas-medical/core";

import { useHistory } from "react-router-dom";
import { tabSyncStore } from "Stores/TabSyncStore";
import { getCurrentFullPath, removeBracketsAndPercent } from "Utilities/helpers";
import { BaseAppRoute } from "Utilities/types";

export const useNavigateToSpace = (shouldSyncTabs?: boolean) => {
  const { push } = useHistory();

  const navigateToSpace = (spaceName: string, forwardUrl?: string) => {
    if (forwardUrl) {
      push(forwardUrl);
    } else {
      const formattedName = removeBracketsAndPercent(spaceName);
      const newLocation = `/${
        sectionStore.activeDepartmentTab
          ? `${BaseAppRoute.spaces}/${encodeURIComponent(formattedName || "")}`
          : `${BaseAppRoute.organisation}/${encodeURIComponent(formattedName || "")}`
      }`;
      push(newLocation, {
        shouldAllowUserAccess: true,
      });
    }
    if (shouldSyncTabs) {
      tabSyncStore.sendMessage({ type: "routeUpdate", route: getCurrentFullPath });
    }
  };

  return { navigateToSpace };
};
