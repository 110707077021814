import { eolasLogger } from "@eolas-medical/core";
import { debounce } from "lodash";
import { makeAutoObservable } from "mobx";
import { getRandomId } from "Utilities/helpers";

enum SyncTypeEnum {
  "routeUpdate" = "routeUpdate",
}

export type SyncType = `${SyncTypeEnum}`;

const isSyncType = (type: unknown): type is SyncType => {
  if (typeof type !== "string") {
    return false;
  }
  return Object.values<string>(SyncTypeEnum).includes(type);
};

export type SyncPayload = { type: "routeUpdate"; route: string | (() => string) };

export type SyncMessage = MessageEvent<{
  id: string;
  payload: { type: "routeUpdate"; route: string };
}>;

export type RouteUpdateOnMessage = (e: SyncMessage) => void;

class TabSyncStore {
  private channel: BroadcastChannel;
  private instanceId: string;
  private onMessageCallback: RouteUpdateOnMessage | null = null;

  constructor() {
    makeAutoObservable(this);
    this.channel = new BroadcastChannel("eolas-tab-sync");
    this.instanceId = getRandomId();
    this.channel.onmessage = (e) => {
      const type = e.data.payload.type;
      if (!isSyncType(type)) {
        eolasLogger.error(new Error(`Invalid tab sync type: ${type}`));
        return;
      }
      if (e.data.id === this.instanceId) {
        return;
      }
      this.onMessageCallback?.(e);
    };
  }

  updateOnMessageCallback = (params: { type: "routeUpdate"; callback: RouteUpdateOnMessage }) => {
    if (params.type === "routeUpdate") {
      this.onMessageCallback = (e) => {
        params.callback(e);
      };
    } else {
      eolasLogger.error(new Error(`Invalid tab sync type: ${params.type}`));
    }
  };

  sendMessage = debounce((payload: SyncPayload) => {
    let newPayload = payload;
    if (payload.type === "routeUpdate") {
      const route = typeof payload.route === "string" ? payload.route : payload.route();
      newPayload = { ...newPayload, route };
    }
    this.channel.postMessage({ id: this.instanceId, payload: newPayload });
  }, 1000);

  clearOnMessageCallback = () => {
    this.onMessageCallback = null;
  };
}

export const tabSyncStore = new TabSyncStore();
