import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";
import { useCallback, useEffect, useState, useMemo } from "react";

import { DeleteIcon } from "Assets/Icons";
import { Button, Text, FormElement, Input } from "UIKit";

import { IHospitalContact } from "../types";
import { hospitalDefaultValuesMapper } from "Pages/Spaces/components/AddEolasFile/defaultValuesMappers";

const labelStyle = "text-lg font-semibold";
const smallLabelStyles = "text-md font-semibold";

const initialContact: IHospitalContact = { name: "", number: null };

interface AddContactsSectionProps {
  hospital?: EolasFile;
}

export const useContacts = (hospital?: EolasFile) => {
  const { control, setValue, watch, register } = useFormContext();
  const { info } = useMemo(
    () =>
      hospitalDefaultValuesMapper({
        mainSectionID: AppLevelSection.hospitalList,
        eolasFile: hospital,
      }),
    [hospital],
  );

  const contacts = watch("info");
  const [showForm, setShowForm] = useState(false);
  const [contact, setContact] = useState<IHospitalContact>(initialContact);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    register("info", info as any);
  }, [info, register]);

  const onShow = useCallback(() => {
    setShowForm(true);
  }, [setShowForm]);

  const handleInputChange = useCallback(
    (inputKey: "name" | "number") => (e: React.ChangeEvent<HTMLInputElement>) => {
      setContact((s) => ({
        ...s,
        [inputKey]: e.target.value,
      }));
    },
    [setContact],
  );

  const onAddContact = useCallback(() => {
    setValue("info", [...contacts, contact], { shouldValidate: true });
    setShowForm(false);
    setContact(initialContact);
  }, [contact, setContact, setShowForm, contacts, setValue]);

  const onDeleteContact = (index: number) => () => {
    const _contacts = [...contacts.slice(0, index), ...contacts.slice(index + 1)];
    setValue("info", _contacts, { shouldValidate: true });
  };

  return {
    onShow,
    control,
    contact,
    showForm,
    contacts,
    onAddContact,
    onDeleteContact,
    handleInputChange,
  };
};

export const AddContactsSection: React.FC<AddContactsSectionProps> = ({
  hospital,
}: AddContactsSectionProps) => {
  const { t } = useTranslation();
  const {
    onShow,
    contact,
    control,
    showForm,
    onAddContact,
    contacts = [],
    onDeleteContact,
    handleInputChange,
  } = useContacts(hospital);

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <span className={labelStyle}>{t("hospitalsAdmin_contacts_section_title")}</span>
        <span className="ml-1 text-red font-semibold">*</span>
      </div>

      <div className="flex flex-col divide-y divide-grey-300">
        {contacts.map((c: IHospitalContact, index: number) => {
          return (
            <div
              key={`${c.name}-${c.number}-${index}`}
              className="grid grid-cols-7 items-center p-2"
            >
              <Text level={1} className="col-span-3">
                {c.name}
              </Text>
              <Text level={1} className="col-span-3">
                {c.number}
              </Text>
              <DeleteIcon
                width={20}
                height={20}
                onClick={onDeleteContact(index)}
                className="col-span-1 cursor-pointer justify-self-end text-red-500 hover:text-red-400"
              />
            </div>
          );
        })}
      </div>

      {showForm ? (
        <>
          <div className="flex flex-col p-4 items-stretch bg-grey-100 rounded-lg shadow-sm">
            <FormElement
              required
              id="contactName"
              control={control}
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_new_contact_name_label")}
            >
              {() => (
                <Input
                  id="contactName"
                  value={contact.name || ""}
                  className="bg-white"
                  onChange={handleInputChange("name")}
                  data-testid="hospitalContactNameInput"
                  placeholder={t("hospitalsAdmin_new_contact_name_placeholder")}
                />
              )}
            </FormElement>

            <FormElement
              required
              id="contactNumber"
              control={control}
              labelClassName={smallLabelStyles}
              label={t("hospitalsAdmin_new_contact_number_label")}
            >
              {() => (
                <Input
                  type="text"
                  id="contactNumber"
                  className="bg-white"
                  value={contact.number || ""}
                  onChange={handleInputChange("number")}
                  data-testid="hospitalContactNumberInput"
                  placeholder={t("hospitalsAdmin_new_contact_number_placeholder")}
                />
              )}
            </FormElement>

            {contact.name && contact.number ? (
              <Button
                color="blue"
                type="button"
                variant="outline"
                onClick={onAddContact}
                className="self-center w-full sm:w-1/3"
              >
                {t("hospitalsAdmin_facilities_add_contact_button")}
              </Button>
            ) : null}
          </div>
        </>
      ) : (
        <Button
          color="grey"
          weight="regular"
          variant="outline"
          onClick={onShow}
          data-testid="addNewContactButton"
          className="self-center w-full sm:w-1/3"
        >
          {t("hospitalsAdmin_add_new_contact")}
        </Button>
      )}
    </div>
  );
};
