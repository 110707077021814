import { knowledgeRoutes } from "Pages/Knowledge/KnowledgeRoutes";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { Button, ButtonProps, Text } from "UIKit";

export const BrowseButton = ({ ...props }: ButtonProps) => {
  const { push } = useHistory();
  const { t } = useTranslation();
  const { routeId } = useParams<{ routeId: string }>();

  const onClick = () => {
    push(`${knowledgeRoutes.clinicalQuestionsContent}/${routeId}`);
  };

  return (
    <Button
      size="md"
      color="grey"
      variant="outline"
      weight="bold"
      onClick={onClick}
      iconLeft="PaperIcon"
      className="self-center rounded-md"
      {...props}
    >
      <Text level={1} className="ml-2">
        {t("general_browse")}
      </Text>
    </Button>
  );
};
