import { Text } from "UIKit";
import { repoConfig } from "AppTypeConfig";

export const CopyrightAndVersion: React.FC<{ className?: string }> = ({
  className = "",
}: {
  className?: string;
}) => {
  return (
    <div className={`flex flex-col items-center ${className}`}>
      <Text level={3} className="text-grey-700">
        {`© Copyright ${new Date().getFullYear()} Eolas Medical LTD`}
      </Text>

      <Text level={3} className="text-grey-700">
        v{repoConfig.version}
      </Text>
    </div>
  );
};
