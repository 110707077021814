import BackgroundCover, {
  BackgroundImageName,
} from "Components/PageContainer/components/BackgroundCover/BackgroundCover";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Pill, Tag } from "UIKit";
import { Sizes } from "UIKit/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { BackgroundClassName } from "Components/PageContainer/components/BackgroundCover/useBackgroundCoverTheme";

export interface FeatureGuideData {
  backgroundImg: BackgroundImageName;
  animation?: React.ReactNode;
  title: string;
  description: string;
  shouldShowBetaTag?: boolean;
  className: BackgroundClassName;
}
export const FeatureGuideModal = ({
  backgroundImg,
  title,
  description,
  animation,
  shouldShowBetaTag,
  className,
}: FeatureGuideData) => {
  const { t } = useTranslation();
  const closeModal = () => {
    modalStore.closeModal();
  };

  return (
    <>
      <InnerModalWrapper className="h-full min-h-90vh max-w-screen-lg relative">
        <FeatureGuideBackground className={className} backgroundImage={backgroundImg}>
          <div className="h-50vh items-center justify-center">{animation}</div>
          <div className="flex flex-col justify-center items-center self-center overflow-auto px-16 lg:max-w-2xl">
            <Pill
              value={t("general_feature_guide")}
              className="text-xs bg-blue-50 text-blue-500 px-4"
              size={Sizes.SMALL}
            />
            <div className="text-center flex text-xl lg:text-2xl my-4 gap-1 flex-col sm:flex-row justify-center items-center sm:items-start">
              {title}

              {shouldShowBetaTag ? (
                <Tag className="bg-green-500 text-white" size="sm" label={t("general_beta")} />
              ) : null}
            </div>

            <div className="text-md lg:text-lg font-semibold text-center">{description}</div>
            <Button
              size="md"
              color="blue"
              variant="solid"
              onClick={closeModal}
              className="mt-4 sm:mt-8"
              data-testid="delete-modal-button"
            >
              {t("whats_new_button")}
            </Button>
          </div>
        </FeatureGuideBackground>
      </InnerModalWrapper>
    </>
  );
};

const FeatureGuideBackground = ({
  children,
  backgroundImage,
  className,
}: {
  children: React.ReactNode;
  backgroundImage: BackgroundImageName;
  className: BackgroundClassName;
}) => {
  const backgroundClass = twMerge(
    "h-full flex-1 bg-no-repeat bg-cover justify-center w-full",
    className,
  );

  return (
    <div
      className={`flex-col flex ${backgroundClass}`}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        zIndex: "2",
      }}
    >
      {children}
    </div>
  );
};

export default BackgroundCover;
