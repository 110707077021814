import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import envConfig from "env-config";
import { Button, InnerModalWrapper, ModalBody, Text } from "UIKit";
import { BaseAppRoute } from "Utilities/types";
import { PortfolioConstants } from "Pages/MeTab/pages/Portfolio/constants";
import { CopyIcon } from "Assets";
import { useMediaQuery } from "Hooks";

export interface ViewQRCodeModalProps {
  eventId: string;
  eventType: string;
  onClose(e: React.MouseEvent<HTMLButtonElement>): void;
}

const ViewQRCodeModal: React.FC<ViewQRCodeModalProps> = ({
  onClose,
  eventId,
  eventType,
}: ViewQRCodeModalProps) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const media = useMediaQuery();

  const isDesktop = media === "lg" || media === "xl" || media === "2xl";

  const feedbackRoute = useMemo(() => {
    return `${envConfig.REACT_APP_PROTOCOL}://${envConfig.REACT_APP_WEB_DOMAIN}/${
      BaseAppRoute.myProfile
    }/portfolio/feedback/${eventType.toLowerCase()}/${eventId}?${
      PortfolioConstants.TRACK_QUERY_PARAM
    }=true`;
  }, [eventId, eventType]);

  const handleClickLink = () => {
    navigator.clipboard.writeText(feedbackRoute);
    setIsClicked(true);
  };

  const copyLinkButtonText = isClicked ? t("copy_to_clipboard_tooltip") : t("copy_to_clipboard");

  return (
    <InnerModalWrapper>
      <ModalBody className="flex flex-col items-center justify-center  text-center">
        <p className="mt-1 text-3xl lg:text-5xl lg:font-extrabold">
          {t("portfolio_qr_modal_title")}
        </p>
        <p className="text-grey-700 py-3 text-base sm:text-lg">
          {t("portfolio_qr_modal_subtitle")}
        </p>

        <QRCode
          data-testid="event-QR-code"
          value={feedbackRoute}
          size={isDesktop ? 240 : 200}
          className="self-center my-8"
        />
        <Button
          onClick={handleClickLink}
          color="grey"
          variant="outline"
          className="rounded-xl mt-1 max-w-32 bg-white"
          data-testid="copy-link-button"
        >
          <div className="flex flex-1 justify-center items-center text-black">
            {!isClicked ? <CopyIcon width={16} height={16} /> : null}
            <Text level={2} className="ml-1">
              {copyLinkButtonText}
            </Text>
          </div>
        </Button>
        <Button
          onClick={onClose}
          size="lg"
          className="mt-10 w-full rounded-xl"
          data-testid="event-qr-close-button"
        >
          {t("portfolio_qr_modal_close")}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default ViewQRCodeModal;
