import { MaginWandIcon } from "Assets";
import {
  Button,
  InnerModalWrapper,
  Textarea,
  Text,
  ModalHeader,
  Title,
  ModalBody,
  FormElement,
} from "UIKit";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import useCompleteWizard, { WizardPayload } from "modules/portfolio/data/useCompleteWizard";
import { wizard, spinner } from "Assets/LottieAnimations";
import Lottie from "react-lottie-player";
import usePortfolioNotifications from "../../hooks/usePortfolioNotifications";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  EventType,
  FormElement as EventFormElement,
  FormElementType,
  WizardFormElements,
} from "modules/portfolio/types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface PortfolioWizardProps {
  ownerId: string;
  eventType: string;
  formElements: EventFormElement[];
  onClose: () => void;
}

const PortfolioWizardModal = ({
  ownerId,
  eventType,
  formElements,
  onClose,
}: PortfolioWizardProps) => {
  const [showLoadingWizard, setShowLoadingWizard] = useState(false);
  const [shouldCloseWizard, setShouldCloseWizard] = useState(false);
  const history = useHistory();
  const { completeWizard, isLoadingWizard } = useCompleteWizard();

  const { t } = useTranslation();

  const { refetchWebNotifications, lastNotification } = usePortfolioNotifications();

  const schema = yup.object().shape({
    prompt: yup.string().required(t("portfolio_wizard_event_summary_required")),
  });

  const { control, handleSubmit, setFocus, register } = useForm({
    defaultValues: {
      prompt: "",
    },
    resolver: yupResolver(schema),
  });

  const wizardFormElements: WizardFormElements[] = useMemo(() => {
    const filteredArray = formElements.filter(
      (obj) => obj.id !== null && obj.id !== undefined && obj.type !== FormElementType.ATTACHMENTS,
    );

    const mappedFormElements = filteredArray.map((obj) => ({
      componentId: obj.id,
      title: obj.componentConfig.title,
    }));

    mappedFormElements.unshift({ componentId: "", title: "Event Title" });

    return mappedFormElements;
  }, [formElements]);

  const handleSubmitWizard = useCallback(
    ({ prompt }: { prompt: string }) => {
      const wizardData: WizardPayload = {
        ownerId,
        eventDate: new Date().toISOString(),
        eventType: eventType as EventType,
        prompt,
        formElements: wizardFormElements,
      };

      completeWizard(wizardData, {
        onSuccess: () => {
          setShowLoadingWizard(true);
          setTimeout(async () => {
            setShouldCloseWizard(true);
            await refetchWebNotifications();
          }, 30000);
        },
      });
    },
    [completeWizard, eventType, ownerId, wizardFormElements, refetchWebNotifications],
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleCloseWizard = useCallback(() => {
    history.push("/my-profile/portfolio");
    onClose();
  }, [history, onClose]);

  useEffect(() => {
    setFocus("prompt");
  }, [setFocus]);

  useEffect(() => {
    if (lastNotification && shouldCloseWizard) {
      onClose();
      history.push(`/${lastNotification?.route}`);
      setShowLoadingWizard(false);
    }
  }, [lastNotification, onClose, history, showLoadingWizard, shouldCloseWizard]);

  return (
    <InnerModalWrapper>
      {!showLoadingWizard ? (
        <>
          <ModalHeader className="text-center">
            <MaginWandIcon className="h-20 w-20" />
            <Title level={3}>{t("portfolio_wizard_event_summary")}</Title>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(handleSubmitWizard)} className="overflow-y-auto">
              <FormElement control={control} id="prompt" required>
                {(field, { className }) => (
                  <Textarea
                    {...register("prompt")}
                    {...field}
                    className={`${className} rounded-lg p-3 resize-none`}
                    rowSpan={3}
                    characterLimit={320}
                    placeholder={t("portfolio_wizard_event_placeholder")}
                    data-testid="prompt-input"
                  />
                )}
              </FormElement>

              <div className="flex space-x-2">
                <Button
                  className="group sm:self-center w-full  rounded-xl"
                  variant="outline"
                  onClick={onClose}
                >
                  {t("general_cancel")}
                </Button>
                <Button
                  type="submit"
                  size="md"
                  weight="bold"
                  iconLeft="WandIcon"
                  color="yellow"
                  className={`group sm:self-center w-full bg-yellow-500
  text-white border-yellow-500 rounded-xl hover:bg-yellow-300 hover:border-yellow-300`}
                  isLoading={isLoadingWizard}
                >
                  <Text level={1} className="ml-2">
                    {t("portfolio_wizard_button_complete")}
                  </Text>
                </Button>
              </div>
            </form>
          </ModalBody>
        </>
      ) : (
        <div data-testid="loading-wizard">
          <ModalBody className="bg-yellow-500 rounded-lg">
            <Title level={6} className="text-center">
              {t("portfolio_wizard_loading")}
            </Title>

            <div className="flex items-center flex-col align-middle self-center">
              <Lottie
                play
                style={{ height: "18rem", width: "18rem" }}
                animationData={wizard}
                {...defaultOptions}
              />
              <Text level={2} className="ml-2" onClick={handleCloseWizard}>
                {t("portfolio_wizard_ai_message")}
              </Text>
              <Lottie play className="h-32 w-32" animationData={spinner} {...defaultOptions} />

              <Button
                type="submit"
                size="md"
                weight="bold"
                variant="link"
                color="black"
                isLoading={isLoadingWizard}
              >
                <Text level={1} className="ml-2" onClick={handleCloseWizard}>
                  {t("general_i_understand")}
                </Text>
              </Button>
            </div>
          </ModalBody>
        </div>
      )}
    </InnerModalWrapper>
  );
};

export default PortfolioWizardModal;
