import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import AccessTypeForm from "./AccessTypeForm";

interface useSpaceAccessTypeStepProps {
  onSubmit: () => void;
  spaceName: string;
  onChange: ({ isPublic }: { isPublic: boolean }) => void;
  isPublic: boolean;
  isLoading?: boolean;
  error?: string;
  isSuccess?: boolean;
}

export const useSpaceAccessTypeStep = ({
  onSubmit,
  spaceName,
  onChange,
  isPublic,
  isLoading,
  error,
  isSuccess,
}: useSpaceAccessTypeStepProps): Step<"access"> => {
  const { t } = useTranslation();
  const handleChangeAccessType = (isPublic: boolean) => {
    onChange({ isPublic });
  };

  const handleSubmitAccessType = async () => {
    onSubmit();
  };

  return {
    name: "access",
    title: t("space_access_type_title", { spaceName }),
    component: (
      <AccessTypeForm defaultIsPublic={isPublic} error={error} onChange={handleChangeAccessType} />
    ),
    isLoading: isLoading,
    onSubmit: handleSubmitAccessType,
    nextLabel: t("general_done"),
    canProceed: isSuccess,
  };
};
