import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AttentionIcon } from "Assets";
import { SuccessModal, Title, Text, Button, FormElement, Input } from "UIKit";

import { useMedusa } from "../../data/useMedusa";
import { MedusaStatusView } from "../MedusaStatus/MedusaStatus";
import { MainSection } from "modules/mainSections/types";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import useToggleMainSection from "modules/mainSections/data/useToggleMainSection";

interface ActivateSectionProps {
  onCloseModal(): void;
  section: MainSection;
}

export const ActivateMedusaSection: React.FC<ActivateSectionProps> = observer(
  ({ section, onCloseModal }) => {
    const { t } = useTranslation();
    const { getIconComponent } = useIconComponent();
    const {
      toggleMainSectionError,
      togglingMainSection,
      toggleMainSection,
      toggleMainSectionSuccess,
    } = useToggleMainSection();

    const {
      isLoading: loadingMedusa,
      status,
      control,
      error: medusaError,
      cached,
      onSubmit,
    } = useMedusa({}, () => toggleMainSection({ id: section.id, disabled: false }));

    const cachingState = useMemo(() => {
      if (status === "enabled") {
        return cached ? (
          <Text level={2} className="text-center">
            {t("contentManagement_medusa_caching_complete")}
          </Text>
        ) : (
          <Text level={2} className="text-center">
            {t("contentManagement_medusa_caching")}
          </Text>
        );
      }
      return (
        <Text level={1} className="text-center">
          {t("contentManagement_medusa_body1")}
        </Text>
      );
    }, [status, cached, t]);

    if (toggleMainSectionSuccess) {
      return (
        <SuccessModal onComplete={onCloseModal} text="contentManagement_editFeature_activated" />
      );
    }

    return (
      <div
        key={section.id}
        className="flex flex-col border-b border-grey px-4 lg:px-12 py-6 lg:py-10"
      >
        <div className="grid grid-cols-5 gap-4 mb-6">
          <div className="flex items-center justify-center">
            {section.iconUrl ? getIconComponent(section.iconUrl) : null}
          </div>

          <div className="flex flex-col ml-4 col-span-4">
            <Title level={8}>
              {section.name} <MedusaStatusView status={status} className="text-base" />
            </Title>
            <Text level={1} className="mt-2">
              {section.description}
            </Text>
          </div>
        </div>
        {cachingState}
        <form onSubmit={onSubmit}>
          <FormElement id="url" label={t("contentManagement_medusa_label")} control={control}>
            {(field) => (
              <Input
                {...field}
                disabled={status !== "disabled"}
                placeholder={t("contentManagement_medusa_placeholder")}
              />
            )}
          </FormElement>
          {medusaError || toggleMainSectionError ? (
            <p className="text-red text-base text-center line-clamp-1">
              <AttentionIcon className="h-4 w-4 align-middle inline mr-2" />
              <span className="align-middle">{medusaError || toggleMainSectionError}</span>
            </p>
          ) : null}
          <div className="flex justify-center">
            <Button
              variant="outline"
              type="submit"
              disabled={togglingMainSection || loadingMedusa}
              isLoading={togglingMainSection || loadingMedusa}
              className="self-center mt-6 sm:mt-8 w-full sm:w-1/2"
            >
              {t("contentManagement_activateSection_button")}
            </Button>
          </div>
        </form>
      </div>
    );
  },
);
