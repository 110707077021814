import { useCallback, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import { useClickOutside } from "Hooks";
import { ClickSearchBox, Loader, Text } from "UIKit";
import { RecentSearch, SearchResult, SearchTypeSection } from "../types";

import NoResultsMessage from "./NoResultsMessage/NoResultsMessage";
import SearchResultList from "./SearchResultList/SearchResultList";
import FilterPills from "./FilterPills/FilterPills";
import RecentSearchesList from "./RecentSearchesList/RecentSearchesList";
import SearchDisclaimer from "./SearchDisclaimer/SearchDisclaimer";

import useResizeScreen from "Hooks/useResizeScreen/useResizeScreen";
import { MasterSearchState } from "./useMasterSearchState";
import useBlockScreenScroll from "Hooks/useBlockScreenScroll/useBlockScreenScroll";

import { trackEvent } from "API/Analytics";
import { AnalyticsEvents } from "@eolas-medical/core";
import LoadMore from "UIKit/LoadMore/LoadMore";
import { LDFlagNames } from "Utilities/types";
import { useLaunchDarkly } from "Contexts";
import { useEolasNavigation, useRoleSwitcher } from "Components/Navigation/hooks";

export interface MasterSearchResultsProps {
  searchInput: string;
  searchValue: string;
  searchLoading: boolean;
  searchResult: SearchResult[];
  searchType: SearchTypeSection;
  onClickMessage: (searchType: SearchTypeSection) => void;
  recentSearches?: RecentSearch[];
  knowledgeSearchDisabled?: boolean;
  localSearchDisabled?: boolean;
  onClearSearch: () => void;
  onChangeText: (text: string) => void;
  onClickFilter: (searchType: SearchTypeSection) => void;
  onClickSearch: (searchText?: string) => void;
  onClickResult: (result: SearchResult) => void;
  onSearchError?: (isError: boolean) => void;
  onClickSearchbox: () => void;
  onClickRecentSearch: (text: string) => void;
  onClickPill: () => void;
  onClear: () => void;
  onClickSearchAction: () => void;
  onClickOutside: () => void;
  state: MasterSearchState;
  onType: (text: string) => void;
  isCopilotError?: boolean;
  copilotErrorMessage?: string;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  renderLoadingCopilot?: () => JSX.Element;
  renderSuggestedSearch?: () => JSX.Element;
  renderAlsoAskedSuggestions?: () => JSX.Element | null;
  knowledgeCopilotSearchDisabled?: boolean;
  masterSearchPlaceholder?: string;
}
const MasterSearchResults = ({
  searchInput,
  searchValue,
  searchLoading,
  searchType,
  onClickMessage,
  searchResult,
  recentSearches,
  knowledgeSearchDisabled = false,
  localSearchDisabled = false,
  onClickSearch,
  onChangeText,
  onClearSearch,
  onClickFilter,
  onSearchError,
  onClickResult,
  onClickSearchbox,
  onClickRecentSearch,
  onClickPill,
  onClear,
  onClickSearchAction,
  onClickOutside,
  state,
  onType,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  isCopilotError = false,
  copilotErrorMessage = "master_search_copilot_error",
  renderLoadingCopilot,
  renderSuggestedSearch,
  renderAlsoAskedSuggestions,
  masterSearchPlaceholder = "master_search_placeholder",
  knowledgeCopilotSearchDisabled,
}: MasterSearchResultsProps) => {
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();
  const { isOnAdminPanel } = useRoleSwitcher();
  const { activeTab } = useEolasNavigation();

  const isAdminSearch = isOnAdminPanel && activeTab !== "knowledge";

  const showModal = state.showRecentSearches || state.showResults;

  const suggestedSearchEnabled = flags?.[LDFlagNames.SHOW_COPILOT_SEARCH_SUGGESTIONS] || false;

  const showSuggestedSearch =
    suggestedSearchEnabled &&
    searchInput.length !== 0 &&
    searchResult.length < 1 &&
    renderSuggestedSearch;

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const scrollToView = () => {
    dropdownRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useClickOutside(dropdownRef, onClickOutside);
  useResizeScreen({ onResize: onClickOutside });
  useBlockScreenScroll(showModal);

  useEffect(() => {
    if (isCopilotError && onSearchError) onSearchError(isCopilotError);
  }, [isCopilotError, onSearchError]);

  const handleChangeText = useCallback(
    (text: string) => {
      onChangeText(text);
      if (text !== state.searchText) {
        onType(text);
      }
    },
    [onType, onChangeText, state],
  );

  const handleClickPill = useCallback(
    (searchType: SearchTypeSection) => {
      onClickFilter(searchType);
      onClickPill();
    },
    [onClickFilter, onClickPill],
  );

  const handleClickSearch = useCallback(() => {
    scrollToView();
    onClickSearch(state.searchText);
    onClickSearchAction();
  }, [state, onClickSearch, onClickSearchAction]);

  const handleClickRecentSearch = useCallback(
    (search: RecentSearch) => {
      onChangeText(search.searchText);
      onClickSearch(search.searchText);
      onClickRecentSearch(search.searchText);
    },
    [onClickSearch, onChangeText, onClickRecentSearch],
  );

  const handleClearSearch = useCallback(() => {
    onClearSearch();
    onClear();
  }, [onClearSearch, onClear]);

  const handleOnFocus = () => {
    scrollToView();
    onClickSearchbox();
    trackEvent(AnalyticsEvents.MAIN_SECTION_VISIT, {
      visitFrom: "directNavigation",
      mainSectionId: "appGlobalSearch",
    });
  };

  const handleLoadMore = () => {
    if (hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  };

  const renderSearchBox = () => (
    <ClickSearchBox
      value={searchInput}
      isLoading={searchLoading}
      onClickSearch={handleClickSearch}
      onClearSearch={handleClearSearch}
      onChangeText={handleChangeText}
      onClick={onClickSearchbox}
      onFocus={handleOnFocus}
      className="z-20 w-full"
      inputClassName="placeholder-black placeholder:font-semibold"
      placeholder={t(masterSearchPlaceholder)}
      data-testid="master-search-input"
      searchBoxSize="xl"
      iconClassName="black"
    />
  );

  const renderRecentSearches = () => {
    if (searchInput.length === 0) {
      return <SearchDisclaimer searchType={searchType} />;
    }
    if (recentSearches?.length) {
      return (
        <>
          <RecentSearchesList
            recentSearches={recentSearches}
            onClickSearch={handleClickRecentSearch}
          />
        </>
      );
    }
  };

  const renderResults = () => {
    if (searchLoading && !isFetchingNextPage) {
      return (
        <div className="flex flex-col items-center justify-center space-y-4 mt-10 mb-24">
          {renderLoadingCopilot ? renderLoadingCopilot() : <Loader />}
        </div>
      );
    }

    if (searchValue.length === 0) {
      return null;
    }

    if (searchValue.length && searchResult.length === 0) {
      return (
        <NoResultsMessage
          searchType={searchType}
          searchValue={searchValue}
          onClickMessage={onClickMessage}
        />
      );
    }

    if (searchValue.length && searchResult.length > 0) {
      return (
        <>
          <SearchResultList
            isAdminSearch={isAdminSearch}
            results={searchResult}
            suggestedQueries={renderAlsoAskedSuggestions ? renderAlsoAskedSuggestions() : null}
            onClickResult={onClickResult}
          />
          {hasNextPage ? (
            <LoadMore isLoading={isFetchingNextPage || false} onLoadMore={handleLoadMore} />
          ) : null}
        </>
      );
    }
  };

  return (
    <>
      {showModal ? (
        <motion.div
          style={{ backgroundColor: "rgba(0, 0, 0, 0.45)", marginTop: "-3rem" }}
          className="-mt-16 flex items-end md:items-center justify-center fixed inset-0 z-10"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        />
      ) : null}
      <div
        className={`items-stretch w-full z-20 relative ${showModal ? "pt-28 xl:pt-24" : "mx-auto"}`}
        ref={dropdownRef}
      >
        <div className="flex items-center justify-center">{renderSearchBox()}</div>
        {showModal ? (
          <div className="absolute z-10 w-full left-0 mt-4" data-testid="master-search-results">
            <div className={"bg-grey-50 rounded-b-md p-2"} style={{ marginTop: "-3rem" }}>
              <>
                {!isAdminSearch ? (
                  <FilterPills
                    localSearchDisabled={localSearchDisabled}
                    knowledgeSearchDisabled={knowledgeSearchDisabled}
                    knowledgeCopilotSearchDisabled={knowledgeCopilotSearchDisabled}
                    selectedPill={searchType}
                    onClickPill={handleClickPill}
                  />
                ) : null}
                {showSuggestedSearch && !searchLoading ? renderSuggestedSearch() : null}

                {isCopilotError ? (
                  <div className="mx-auto text-center">
                    <Text level={1} className="text-grey-700 mx-auto">
                      {t(copilotErrorMessage)}
                    </Text>
                  </div>
                ) : null}
                {renderResults()}
              </>

              {state.showRecentSearches ? <>{renderRecentSearches()}</> : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MasterSearchResults;
