import { EolasMainSection, FeatureGuides, userStore } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { isFeatureGuide } from "shared/functions/typeguards";
import { useModalState } from "shared/hooks/useModalState/useModalState";
import { FeatureGuideModal } from "../FeatureGuideModal";
import { clinicalQuestions, favourites } from "Assets";
import { LottieWithHeader } from "UIKit";
import { useTranslation } from "react-i18next";
import { useCompleteFeatureGuide } from "modules/featureGuides/data/useCompleteFeatureGuide";
import { modalStore } from "Stores/ModalStore";
import { BackgroundImageName } from "Components/PageContainer/components/BackgroundCover/BackgroundCover";
import { BackgroundClassName } from "Components/PageContainer/components/BackgroundCover/useBackgroundCoverTheme";
import meBgLines from "Assets/Img/me-background-lines.svg";
import knowledgeBgLines from "Assets/Img/knowledge-background-lines.svg";
export interface FeatureGuideData {
  backgroundImg: BackgroundImageName;
  animation?: React.ReactNode;
  title: string;
  description: string;
  shouldShowBetaTag?: boolean;
  className: BackgroundClassName;
}

export const useFeatureGuideModal = (mainSectionType: EolasMainSection) => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const { completeFeatureGuide } = useCompleteFeatureGuide();
  const shouldShowFeatureGuide =
    flags[LDFlagNames.FEATURE_GUIDE_MODALS] && hasNotSeenFeatureGuide(mainSectionType);
  const { modal, closeModal, isOpen } = useModalState();

  const featureGuideMap: Partial<Record<EolasMainSection, FeatureGuideData>> = {
    // @TODO: implement Feature Guides for other main sections
    [FeatureGuides.MY_FAVOURITES]: {
      title: t("my_favourites_homepage"),
      description: t("my_favourites_feature_guide_description"),
      backgroundImg: meBgLines,
      className: BackgroundClassName.MeBackground,
      animation: (
        <LottieWithHeader
          animationSize="50%"
          animation={favourites}
          lottieOptions={{ loop: true }}
          className="absolute self-center"
        />
      ),
    },

    [FeatureGuides.CLINICAL_QUESTIONS]: {
      title: t("eolas_clinical_questions"),
      description: flags[LDFlagNames.SHOULD_SHOW_CLINICAL_QUESTIONS_BETA_TAG]
        ? t("clinical_questions_feature_guide_beta_description")
        : t("clinical_questions_feature_guide_description"),
      backgroundImg: knowledgeBgLines,
      className: BackgroundClassName.KnowledgeBackground,
      animation: (
        <LottieWithHeader
          animationSize="35%"
          animation={clinicalQuestions}
          lottieOptions={{ loop: true }}
          className="absolute self-center"
        />
      ),
      shouldShowBetaTag: Boolean(flags[LDFlagNames.SHOULD_SHOW_CLINICAL_QUESTIONS_BETA_TAG]),
    },
  };

  const featureGuideData = featureGuideMap[mainSectionType];
  const showFeatureGuide = async () => {
    if (featureGuideData) {
      modalStore.openModal({
        name: "featureGuideModal",
        variant: "component",
        Component: <FeatureGuideModal {...featureGuideData} />,
      });
      if (isFeatureGuide(mainSectionType)) {
        await completeFeatureGuide(mainSectionType);
      }
    }
  };

  return {
    featureGuideModal: modal,
    closeFeatureGuide: closeModal,
    isFeatureGuideOpen: isOpen,
    showFeatureGuide,
    shouldShowFeatureGuide,
  };
};

export const hasNotSeenFeatureGuide = (sectionKey: EolasMainSection): boolean => {
  return isFeatureGuide(sectionKey) && !userStore.userData.featureGuides?.web[sectionKey];
};
