import { FileTileWrapper, FileTileProps } from "./shared";

export const HospitalFileTile: React.FC<FileTileProps> = ({
  eolasFile,
  isDragging,

  onSelectFile = () => {
    return;
  },
}: FileTileProps) => {
  return (
    <FileTileWrapper
      eolasFile={eolasFile}
      isDragging={isDragging}
      onClick={() => onSelectFile(eolasFile)}
    >
      <div className="flex flex-col flex-1">
        <span className="text-lg font-semibold line-clamp-2">{eolasFile.name}</span>
        <span className="text-sm text-grey-darker capitalize line-clamp-2">
          {eolasFile.description}
        </span>
      </div>
    </FileTileWrapper>
  );
};
