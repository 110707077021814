/* eslint-disable camelcase */
import mixpanel from "mixpanel-browser";

import { EventPayload, AnalyticsEvents, makeMixpanelMetadata } from "@eolas-medical/core";

import envConfig from "../env-config";
import { appConfig } from "AppTypeConfig";
import { isDev } from "Utilities/helpers";

export const initMixpanel = async () => {
  if (envConfig.REACT_APP_MIXPANEL_ENABLED) {
    mixpanel.init(envConfig.REACT_APP_MIXPANEL_TOKEN, {
      debug: envConfig.REACT_APP_APP_ENV === "development",
      api_host: "https://api-eu.mixpanel.com",
    });
    mixpanel.register({
      appType: appConfig.appType,
      environment: envConfig.REACT_APP_APP_ENV,
    });
  }
};

let timeRecordedEvents: { eventName: AnalyticsEvents; time: number }[] = [];

export const trackEvent = <T extends EventPayload>(
  eventName: AnalyticsEvents,
  eventPayload?: T,
) => {
  const latestEvents =
    timeRecordedEvents.length > 10 ? timeRecordedEvents.slice(-10) : timeRecordedEvents;

  const now = new Date().getTime();

  const isRecentDuplicate = latestEvents.some(
    ({ eventName: recentEventName, time: recentTime }) =>
      recentEventName === eventName && now - recentTime < 500,
  );

  latestEvents.push({ eventName, time: now });

  timeRecordedEvents = latestEvents;

  if (isRecentDuplicate) {
    return;
  }

  const baseMetadata = makeMixpanelMetadata(envConfig.REACT_APP_APP_ENV);

  if (isDev() && eventPayload) {
    if (
      Object.keys(eventPayload).some((key) => Object.keys(baseMetadata).includes(key as string))
    ) {
      throw new Error(
        "There is a clash in property names between Mixpanel base metadata and additional properties",
      );
    }
  }

  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.track(eventName, {
    ...baseMetadata,
    ...eventPayload,
  });
};

export const timeEvent = (eventName: AnalyticsEvents) => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.time_event(eventName);
};

// call this after the user logs in so we can track their actions thru the app
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const identifyUser = (user: any) => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.identify(user.id);
};

export const resetAnalytics = () => {
  if (!envConfig.REACT_APP_MIXPANEL_ENABLED) {
    return;
  }

  mixpanel.reset();
};
