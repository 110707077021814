import { EolasMainSection } from "@eolas-medical/core";
import React, { useCallback } from "react";

import {
  CreatinineClearanceIcon,
  GentamicinIcon,
  AnalyticsIcon,
  NewsFeedIcon,
  NotificationIcon,
  UserIcon,
  WeightIcon,
} from "Assets/Icons";

import * as communityIcons from "Assets/Icons/communitySectionIcons";
import * as hospitalIcons from "Assets/Icons/hospitalSectionIcons";
import * as iconPickerIcons from "Assets/Icons/iconPickerIcons";
import * as departmentIcons from "Assets/Icons/mainSectionIcons";
import { meTabIcons, MeTabIconsType } from "Assets/Icons/meTabIcons";

export type IconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

const mainSectionIcons: Record<EolasMainSection, IconComponent> = {
  askEolasSpaces: departmentIcons.AskEolasSpacesIcon,
  admissionGuides: departmentIcons.AdmissionPoliciesIcon,
  checklists: departmentIcons.ChecklistIcon,
  clinicalCalculators: hospitalIcons.ClinicalCalculatorsIcon,
  clinicalPathways: departmentIcons.ClinicalPathwaysIcon,
  completedChecklist: departmentIcons.ChecklistIcon,
  contacts: departmentIcons.ContactsIcon,
  drugMonitoring: hospitalIcons.DrugMonitoringIcon,
  educationPortal: departmentIcons.EducationPortalIcon,
  emergencyActionCards: departmentIcons.EmergencyActionCardsIcon,
  equipmentLocations: departmentIcons.EquipmentLocationsIcon,
  guidelines: departmentIcons.GuidelinesIcon,
  clinicalDocuments: departmentIcons.ClinicalDocumentsIcon,
  policyDocuments: departmentIcons.PolicyDocumentsIcon,
  highlights: departmentIcons.HighlightsIcon,
  hospitalContacts: hospitalIcons.HospitalContactsIcon,
  hospitalGuidelines: hospitalIcons.HospitalGuidelinesIcon,
  hospitalList: departmentIcons.HospitalListIcon,
  howTo: departmentIcons.HowToIcon,
  importantLinks: departmentIcons.ImportantLinksIcon,
  incidentReporting: departmentIcons.IncidentReportingIcon,
  majorIncidents: departmentIcons.MajorIncidentIcon,
  meetTheTeam: departmentIcons.MeetTheTeamIcon,
  newsFeed: departmentIcons.NewsFeedIcon,
  patientLeaflets: departmentIcons.PatientLeafletsIcon,
  orgPatientLeaflets: hospitalIcons.OrgPatientLeafletsIcon,
  rotas: departmentIcons.RotasIcon,
  surveys: departmentIcons.SurveysIcon,
  theDepartment: departmentIcons.TheDepartmentIcon,
  wellbeing: departmentIcons.WellbeingIcon,
  medusaMedications: hospitalIcons.MedusaIcon,
  communicationPortal: hospitalIcons.CommunicationPortalIcon,
  learningManagement: hospitalIcons.LearningManagementIcon,
  researchAndAudit: departmentIcons.ResearchAndAuditIcon,
  nationalGuidelines: communityIcons.NationalGuidelinesIcon,
  communityPatientLeaflets: departmentIcons.CommunityPatientLeafletsIcon,
  genericOrganisationGuidelines: departmentIcons.GuidelinesIcon,
  organisationWellbeing: departmentIcons.WellbeingIcon,
  channels: departmentIcons.ChatBubblesIcon,
  communityMedications: communityIcons.CommunityMedicationsIcon,
  activityFeed: departmentIcons.ActivityFeedIcon,
  niceGuidelines: communityIcons.NiceGuidelinesIcon,
  wikiEm: communityIcons.WikiEmIcon,
  majorIncidentDocuments: () => null,
  myFiles: () => null,
  myPortfolio: () => null,
  clinicalQuestions: communityIcons.ClinicalQuestionIcon,
  myFavourites: () => null,
  sponsoredSlots: communityIcons.SponsoredSlotsIcon,
};

export type AdminDashboardIconType =
  | "notifications"
  | "manageUsers"
  | "contentManagement"
  | "adminAnalytics";

export type ClinicalCalculatorsIconType =
  | "creatinineClearance"
  | "idealBodyWeight"
  | "gentamicinDose";

const adminDashboardIcons: Record<AdminDashboardIconType, IconComponent> = {
  manageUsers: UserIcon,
  notifications: NotificationIcon,
  contentManagement: NewsFeedIcon,
  adminAnalytics: AnalyticsIcon,
};

const clinicalCalculatorsIcons: Record<ClinicalCalculatorsIconType, IconComponent> = {
  idealBodyWeight: WeightIcon,
  gentamicinDose: GentamicinIcon,
  creatinineClearance: CreatinineClearanceIcon,
};

export const useIcons = () => {
  const getMainSectionIcon = useCallback((sectionID: EolasMainSection) => {
    return mainSectionIcons[sectionID];
  }, []);

  const getSpaceAdminDashboardIcon = useCallback((iconType: AdminDashboardIconType) => {
    return adminDashboardIcons[iconType];
  }, []);

  const getClinicalCalculatorSectionIcon = useCallback((iconType: ClinicalCalculatorsIconType) => {
    return clinicalCalculatorsIcons[iconType];
  }, []);

  const getSectionIcon = useCallback((iconType: iconPickerIcons.IconPickerIconsType) => {
    if (!iconType) return null;

    return iconPickerIcons[iconType] ?? <FallbackImg src={iconType} />;
  }, []);

  const getMeTabIcon = useCallback((iconType: MeTabIconsType) => {
    const Icon: IconComponent = meTabIcons[iconType];
    return Icon;
  }, []);

  return {
    getSectionIcon,
    getMainSectionIcon,
    getMeTabIcon,
    getSpaceAdminDashboardIcon,
    getClinicalCalculatorSectionIcon,
  };
};

const FallbackImg: React.FC<{ src: string }> = ({ src }: { src: string }) => {
  return <img className="h-16 w-16" src={src} alt="" />;
};
