import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import TemplateSelector from "./SpaceTemplateSelector";

interface useSpaceTemplateSelectorStepProps {
  isLoading?: boolean;
  spaceTemplateId?: string;
  onSubmit: ({ spaceTemplateId }: { spaceTemplateId: string }) => void;
}

export const useSpaceTemplateSelectorStep = ({
  isLoading,
  onSubmit,
  spaceTemplateId,
}: useSpaceTemplateSelectorStepProps): Step<"templateSelector"> => {
  const { t } = useTranslation();

  const handleTemplateSelection = (spaceTemplateId: string) => {
    onSubmit({ spaceTemplateId });
  };

  return {
    name: "templateSelector",
    title: t("space_create_select_template"),
    subtitle: t("space_create_select_template_subtitle"),
    subtitleClassName: "text-blue",
    component: (
      <TemplateSelector onChange={handleTemplateSelection} spaceTemplateId={spaceTemplateId} />
    ),
    isLoading,
    hideButtons: true,
  };
};
