import { Button, Text, Title } from "UIKit";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "Hooks";

const ADMIN_SUPPORT_URL = "https://support.eolasmedical.com/admins";

export const AdminTitleDetails = ({
  entity,
  onClickEditSpace,
}: {
  onClickShareSpace: () => void;
  onClickEditSpace: () => void;
  entity: "Space" | "Organisation";
}) => {
  const media = useMediaQuery();
  const isSmallDevice = media === "xs";

  const { t } = useTranslation();
  return (
    <div className="flex flex-col space-y-3 mt-8">
      <Title level={3}>{t("adminHome_title")}</Title>
      <div className="flex gap-2">
        <AdminSupportButton isSmallDevice={isSmallDevice} />
        <EditSpaceButton
          isSmallDevice={isSmallDevice}
          onClickEditSpace={onClickEditSpace}
          text={t("space_details_edit_space", { entity })}
        />
      </div>
    </div>
  );
};

const AdminSupportButton = ({ isSmallDevice }: { isSmallDevice: boolean }) => {
  const handleClick = () => {
    window.open(ADMIN_SUPPORT_URL, "_blank");
  };
  const { t } = useTranslation();
  return (
    <Button
      iconLeft={isSmallDevice ? null : "MortarboardIcon"}
      variant="ghost"
      color="blue"
      size="sm"
      className="h-10 rounded-md hover:bg-opacity-50 font-bold"
      onClick={handleClick}
    >
      <Text level={1} className="ml-2">
        {t("learn_more_admin")}
      </Text>
    </Button>
  );
};

const EditSpaceButton = ({
  text,
  onClickEditSpace,
  isSmallDevice,
}: {
  text: string;
  onClickEditSpace: () => void;
  isSmallDevice: boolean;
}) => {
  return (
    <Button
      iconLeft={isSmallDevice ? null : "EditPencilIcon"}
      variant="ghost"
      color="blue"
      size="sm"
      className="h-10 rounded-md hover:bg-opacity-50 font-bold"
      onClick={onClickEditSpace}
    >
      <Text level={1} className="ml-2">
        {text}
      </Text>
    </Button>
  );
};
