import { motion, Variants } from "framer-motion";

const containerVariants: Variants = {
  out: {
    opacity: 0.5,
  },
  in: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

type GridContainerProps = {
  children: React.ReactNode;
};

const GridContainer = ({ children }: GridContainerProps) => {
  return (
    <motion.div
      animate="in"
      initial="out"
      variants={containerVariants}
      className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 lg:gap-5 xl:gap-6"
    >
      {children}
    </motion.div>
  );
};

export default GridContainer;
