import { useRunOnMountUnmount } from "Hooks";
import { useSelectSpaceActions } from "Pages/Spaces/pages/SelectSpace/hooks/useSelectSpaceActions";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { FormElement, Textarea, Button, PageTitle, SuccessModal } from "UIKit";
import { InnerModalWrapper } from "UIKit/Modal/components/InnerModalWrapper";
import { Text } from "UIKit/Typography";

type Props = {
  spaceOrgName: string;
  /** The id of the space if requesting access to space or admin space if requesting access to org */
  spaceId: string;
  onSuccessfulRequest?: () => void;
};

export const RequestAccessModal = ({ spaceId, spaceOrgName, onSuccessfulRequest }: Props) => {
  const { t } = useTranslation();

  const { control, register, handleSubmit, setFocus } = useForm({
    defaultValues: {
      requestText: "",
    },
  });

  const {
    onRequestAccess,
    requestingAccess: isRequestingAccess,
    requestAccessSuccessful: wasRequestAccessSuccessful,
  } = useSelectSpaceActions({ shouldSyncTabs: true });

  useRunOnMountUnmount({ onMount: () => setFocus("requestText") });

  const handleSendAccessRequest = async ({ requestText }: { requestText: string }) =>
    onRequestAccess(spaceId, requestText);

  const onSuccess = () => {
    modalStore.closeModal();
    onSuccessfulRequest?.();
  };

  const onCancel = () => {
    modalStore.closeModal();
  };

  if (wasRequestAccessSuccessful) {
    return <SuccessModal onComplete={onSuccess} text={t("request_access_modal_success")} />;
  }

  return (
    <InnerModalWrapper>
      <div className="flex flex-1 flex-col items-center justify-center p-8 space-y-3">
        <PageTitle title={t("request_access_modal_title")} />
        <Text level={1} className="text-center">
          {t("request_access_modal_subtitle", { spaceOrgName })}
        </Text>
        <form
          onSubmit={handleSubmit(handleSendAccessRequest)}
          className="flex flex-col w-full justify-center items-center"
        >
          <FormElement id="requestText" control={control} className="w-full">
            {(fieldProps) => {
              const { value, ...rest } = fieldProps;
              return (
                <Textarea
                  {...register("requestText")}
                  {...rest}
                  placeholder={t("selectSpace_request_information_placeholder")}
                  value={value}
                  className="resize-none"
                />
              );
            }}
          </FormElement>

          <div className="flex flex-row items-center space-x-4">
            <Button color="blue" type="submit" isLoading={isRequestingAccess}>
              {t("request_access_modal_submit")}
            </Button>
            <Button color="blue" variant="outline" onClick={onCancel}>
              {t("general_cancel")}
            </Button>
          </div>
        </form>
      </div>
    </InnerModalWrapper>
  );
};
