import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider } from "react-hook-form";
import { RouteComponentProps } from "react-router-dom";
import { useLaunchDarkly } from "Contexts";

import { CheckIcon, success } from "Assets";
import {
  Input,
  Modal,
  Button,
  PageTitle,
  NavButton,
  FormError,
  FormElement,
  FormFieldError,
  LottieWithHeader,
  InnerPageWrapper,
  Text,
  Loader,
} from "UIKit";

import { useChecklistBuilder } from "../hooks";
import {
  BuilderSubmitConfirmationModal,
  BuilderSectionList,
  ChecklistOwnerModal,
} from "../components";
import { LDFlagNames } from "Utilities/types";
import OwnerTile from "../components/OwnerTile";

interface ChecklistBuilderRouteParams {
  sectionID: string;
  checklistID?: string;
}

const MAX_OWNERS_ALLOWED = 5;

export const ChecklistBuilder: React.FC<RouteComponentProps<ChecklistBuilderRouteParams>> = ({
  history: { push },
  match: {
    url,
    params: { sectionID: encodedSectionID, checklistID: encodedChecklistID },
  },
}: RouteComponentProps<ChecklistBuilderRouteParams>) => {
  const { flags } = useLaunchDarkly();
  const parentID = decodeURIComponent(encodedSectionID);
  const checklistID = encodedChecklistID ? decodeURIComponent(encodedChecklistID) : undefined;

  const { t } = useTranslation();
  const {
    error,
    isOpen,
    control,
    isLoading,
    formMethods,
    isSuccessful,
    onSubmit,
    onCloseModal,
    submitChecklist,
    isAddingOwners,
    handleCloseAddingOwnersModal,
    handleOpenAddingOwnersModal,
    owners,
    handleAddOwner,
    possibleOwners,
    onRemoveOwner,
    ownerToModify,
    onEditOwner,
    isLoadingSpaceAdmins,
  } = useChecklistBuilder({
    parentID,
    checklistID,
  });

  const returnURL = useMemo(() => {
    if (!checklistID) return url.replace("/builder", "");

    return url.replace("/builder", "/details");
  }, [url, checklistID]);

  const isDisabledAddOwner = isLoadingSpaceAdmins || owners.length === MAX_OWNERS_ALLOWED;

  if (isSuccessful) {
    return (
      <LottieWithHeader
        animation={success}
        animationSize="50%"
        data-testid="success-animation"
        onComplete={() => push(returnURL)}
        text={t(
          checklistID ? "checklistBuilder_edit_success" : "checklistBuilder_creation_success",
        )}
      />
    );
  }

  return (
    <>
      <InnerPageWrapper>
        <PageTitle
          title={t(checklistID ? "checklistBuilder_edit_title" : "checklistBuilder_create_title")}
        />
        <NavButton to={returnURL} />
        {flags[LDFlagNames.CHECKLIST_OWNERS] ? (
          <Button
            iconLeft="AddMultipleUsersIcon"
            size="lg"
            color="grey"
            variant="outline"
            className={"group sm:self-center"}
            onClick={handleOpenAddingOwnersModal}
            data-testid="add-checklist-owner"
            disabled={isDisabledAddOwner}
          >
            <Text level={1} className="ml-2">
              {t("checklistBuilder_add_checklist_owners")}
            </Text>
          </Button>
        ) : null}
        {isLoadingSpaceAdmins ? <Loader /> : null}
        {!isLoadingSpaceAdmins && owners.length ? (
          <div className="px-8">
            {owners.map(({ id, activeNotifications }) => (
              <OwnerTile
                key={id}
                id={id}
                activeNotifications={activeNotifications}
                onRemoveOwner={onRemoveOwner}
                onEditOwner={() => {
                  onEditOwner({ id, activeNotifications });
                }}
              />
            ))}
          </div>
        ) : undefined}

        <FormProvider {...formMethods}>
          <form className="flex flex-col" onSubmit={onSubmit}>
            <div className="px-0 sm:px-10">
              <FormElement
                required
                control={control}
                id="checklistName"
                label={t("checklistBuilder_checklist_name_label")}
              >
                {(field) => (
                  <Input
                    {...field}
                    size="lg"
                    placeholder={t("checklistBuilder_checklist_name_placeholder")}
                    iconRight={field.value ? <CheckIcon className="text-green" /> : undefined}
                  />
                )}
              </FormElement>
            </div>

            <BuilderSectionList />

            {!formMethods.formState.isValid && formMethods.formState.submitCount > 0 ? (
              <FormFieldError className="justify-center mt-4">
                {"checklistBuilder_general_form_error"}
              </FormFieldError>
            ) : null}

            <Button
              size="lg"
              type="submit"
              isLoading={isLoading}
              className="add-file__submit-button"
            >
              {t(
                checklistID
                  ? "checklistBuilder_update_checklist_button"
                  : "checklistBuilder_save_checklist_button",
              )}
            </Button>

            <FormError error={error} />
          </form>
        </FormProvider>
      </InnerPageWrapper>

      <Modal open={isOpen} onClose={onCloseModal}>
        <BuilderSubmitConfirmationModal
          error={error}
          isLoading={isLoading}
          onCancel={onCloseModal}
          onSubmit={() => {
            submitChecklist(formMethods.getValues());
          }}
        />
      </Modal>
      <Modal open={isAddingOwners} onClose={handleCloseAddingOwnersModal}>
        <ChecklistOwnerModal
          onCancel={handleCloseAddingOwnersModal}
          onSubmit={handleAddOwner}
          possibleOwners={possibleOwners}
          owner={ownerToModify}
        />
      </Modal>
    </>
  );
};
