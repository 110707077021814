/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClinicalCalculatorsSubSection } from "@eolas-medical/core";
import localforage from "localforage";
import { action, makeObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

type SeenCalculatorsMap = Record<string, string>;
type RequestedAnalyticsMap = Record<string, string>;
class WebStore {
  constructor() {
    makeObservable(this);
    this.isHydrated = new Promise((resolve, reject) => {
      makePersistable(this, {
        stringify: false,
        debugMode: false,
        name: "webStore",
        storage: localforage,
        properties: [
          "viewedWhatsNew",
          "seenCalculatorsWarning",
          "showPostCognitoModal",
          "showPostSpaceCreationModal",
          "lastAppFetchTimestamp",
          "requestedAnalytics",
        ],
      })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public isHydrated: Promise<boolean>;

  @observable public viewedWhatsNew: any = [];
  @observable public showPostCognitoModal: boolean = false;
  @observable public showPostSpaceCreationModal: boolean = false;
  @observable public seenCalculatorsWarning: SeenCalculatorsMap = {};
  @observable public postLoginRedirect: string = "";
  @observable public shouldReloadWindow = false;
  @observable public lastAppFetchTimestamp: string | null = null;
  @observable public requestedAnalytics: RequestedAnalyticsMap = {};

  @action setPostLoginRedirect(redirect: string) {
    this.postLoginRedirect = redirect;
  }

  @action hasSeenCurrentWhatsNew(url: string) {
    return this.viewedWhatsNew.includes(url);
  }

  @action setWhatsNewSeenStatus(url: string) {
    this.viewedWhatsNew.push(url);
  }

  @action hasSeenCalculatorsWarning(calculatorName: ClinicalCalculatorsSubSection) {
    return this.seenCalculatorsWarning[calculatorName];
  }

  @action setCalculatorsWarningAsSeen(calculatorName: ClinicalCalculatorsSubSection) {
    const newDate = new Date();
    this.seenCalculatorsWarning[calculatorName] = newDate.toISOString();
  }

  @action setShowPostCognitoModal(value: boolean) {
    this.showPostCognitoModal = value;
  }

  @action setShowPostSpaceCreationModal(show: boolean) {
    this.showPostSpaceCreationModal = show;
  }

  @action setShouldReloadWindow(newValue: boolean) {
    this.shouldReloadWindow = newValue;
  }

  @action setAppLastFetchTimestamp(newValue: string | null) {
    this.lastAppFetchTimestamp = newValue;
  }

  @action setRequestedAnalytics(appId: string) {
    const newDate = new Date();
    this.requestedAnalytics[appId] = newDate.toISOString();
  }

  @observable hasRequestedAnalytics(appId: string): boolean {
    return !!this.requestedAnalytics[appId];
  }
}

export let webStore: WebStore;

export const initialiseWebStore = async () => {
  webStore = new WebStore();
  await webStore.isHydrated;
};
