import { CaretDownIcon } from "Assets";
import React, { useState } from "react";
import { overrideTailwindClasses as tw } from "tailwind-override";

interface CollapsingSectionProps {
  children: React.ReactNode;
  title: string;
  shouldMountExpanded?: boolean;
  headerContainerBackgroundColor?: string;
  childrenContainerBackgroundColor?: string;
}

const InteractionsCollapsingSection = ({
  children,
  title,
  shouldMountExpanded = false,
  headerContainerBackgroundColor = "bg-white",
  childrenContainerBackgroundColor = "bg-white",
}: CollapsingSectionProps) => {
  const [isExpanded, setIsExpanded] = useState(shouldMountExpanded);

  const toggleExpansion = () => setIsExpanded(!isExpanded);

  return (
    <div
      className={tw(
        `container mx-auto border rounded-lg border-grey-500 w-full ${headerContainerBackgroundColor}`,
      )}
    >
      <div
        className={tw(
          `header flex items-center cursor-pointer py-1 border-b-[1px] w-full ${
            isExpanded ? "border-grey-400" : "border-transparent"
          }`,
        )}
        onClick={toggleExpansion}
      >
        <CaretDownIcon
          className={tw(`w-6 h-6 ml-4 transition-all ${!isExpanded && "transform rotate-180"}`)}
        />
        <div className={tw("title ml-2 text-lg truncate text-left p-4 w-full")}>{title}</div>
      </div>
      {isExpanded ? (
        <div className={tw(`content p-4 rounded-b-lg ${childrenContainerBackgroundColor}`)}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default InteractionsCollapsingSection;
