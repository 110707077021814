import { useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Button, InnerModalWrapper, ModalBody, ModalHeader, SearchBox, Text, Title } from "UIKit";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import { useLocalSearch } from "shared/hooks/useLocalSearch";
import useSpaceSpecialties from "modules/spaces/data/useSpaceSpecialties";

interface SearchFilterModalProps {
  onApply: (specialties: string[]) => void;
  selectedSpecialties: string[];
  onSelectSpecialties: (specialties: string[]) => void;
}

type ModalFilters = {
  [key: string]: boolean;
};

const SearchFiltersModal = ({
  onApply,
  selectedSpecialties,
  onSelectSpecialties,
}: SearchFilterModalProps) => {
  const { t } = useTranslation();
  const specialties = useSpaceSpecialties();

  const initialFilters: ModalFilters = selectedSpecialties.reduce(
    (acc: ModalFilters, curr: string) => {
      acc[curr] = true;
      return acc;
    },
    {},
  );

  const { searchInput, searchResult, onSetSearchInput } = useLocalSearch({
    data: specialties,
    keysToSearch: ["label"],
  });

  const [selectedFilters, setSelectedFilters] = useState<Record<string, boolean>>(initialFilters);

  const handleFilterClick = (label: string) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[label]) {
        delete updatedFilters[label];
      } else {
        updatedFilters[label] = true;
      }
      return updatedFilters;
    });
  };

  const handleClearAll = () => {
    setSelectedFilters({});
  };

  const handleClickApply = () => {
    const specialties = Object.keys(selectedFilters);
    onApply(specialties);
    onSelectSpecialties(specialties);
  };

  const getFilterClass = (label: string) =>
    selectedFilters[label] ? "bg-blue-500 text-white" : "bg-grey-100 text-black";

  const renderResults = () => {
    if (searchInput.length && !searchResult.length) {
      {
        return (
          <div className="flex flex-col px-4 sm:px-40 py-28 text-center items-center space-y-4">
            <Title level={8} className="text-grey-700">
              {t("selectSpace_no_results_found", { term: searchInput })}
            </Title>

            <Text level={1} className="text-grey-700">
              {t("selectSpace_try_another_search")}
            </Text>
          </div>
        );
      }
    }

    return (
      <div className="flex flex-wrap justify-start items-center gap-2 h-48 xl:h-104 overflow-y-auto">
        {searchResult.map((sp) => {
          return (
            <div key={sp.value} className="flex-shrink-0">
              <Pill
                value={sp.label}
                className={twMerge("text-xs cursor-pointer", getFilterClass(sp.label))}
                size={Sizes.SMALL}
                onClick={() => handleFilterClick(sp.label)}
              />
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <InnerModalWrapper>
      <ModalHeader>
        <Title level={3}>{t("selectSpace_filters")}</Title>
      </ModalHeader>

      <ModalBody>
        <div className="p-2 flex flex-col space-y-6">
          <SearchBox onChangeText={onSetSearchInput} />

          {renderResults()}

          <div className="flex justify-center items-center space-x-4 px-16 sm:px-32">
            <Button className="w-full" color="grey" onClick={handleClearAll}>
              {t("general_clear_all")}
            </Button>
            <Button className="w-full" color="blue" onClick={handleClickApply}>
              {t("general_apply")}
            </Button>
          </div>
        </div>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default SearchFiltersModal;
