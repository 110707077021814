import React from "react";

export interface ControlsWrapperProps {
  children: React.ReactNode;
  direction?: "row" | "column";
}

export const ControlsWrapper: React.FC<ControlsWrapperProps> = ({
  children,
  direction = "column",
  ...props
}: ControlsWrapperProps) => {
  return (
    <div
      className={`
        flex items-center h-full content-between justify-between gap-2
        ${direction === "row" ? "flex-row" : "flex-col"}
        self-start md:self-center pt-1
      `}
      {...props}
    >
      {children}
    </div>
  );
};
