import { Space } from "@eolas-medical/core";
import { useLaunchDarkly } from "Contexts";
import usePublicSpaces from "modules/spaces/data/usePublicSpaces";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Text, Title } from "UIKit";
import { LDFlagNames } from "Utilities/types";
import { DiscoverSpaceItem } from "../components/DiscoverSpaces/components/DiscoverSpaceItem/DiscoverSpaceItem";

export const useSelectSpaceHome = () => {
  const { flags } = useLaunchDarkly();
  const { t } = useTranslation();
  const showSuggestedSpaces = !!flags[LDFlagNames.DISCOVER_SPACES_SUGGESTED];
  const { publicSpacesLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = usePublicSpaces({
    enabled: showSuggestedSpaces,
  });
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState<Space[] | null>(null);

  const handleSearchSuccessful = (searchResults: Space[], input?: string) => {
    setSearchResults(searchResults);
    if (input) {
      setSearchInput(input);
    }
  };

  const handleClearResults = () => {
    setSearchResults(null);
  };

  const handleLoadMore = () => {
    if (hasNextPage && fetchNextPage) {
      fetchNextPage();
    }
  };

  const renderSpaceItem = useCallback((discoveredSpace: Space) => {
    return (
      <div key={discoveredSpace.id}>
        <DiscoverSpaceItem space={discoveredSpace} />
      </div>
    );
  }, []);

  const renderSpacesList = () => {
    if (searchResults) {
      if (searchResults.length === 0) {
        return (
          <div className="flex flex-col text-center items-center space-y-4">
            <Title level={8} className="text-grey-700">
              {t("selectSpace_no_results_found", { term: searchInput })}
            </Title>

            <Text level={1} className="text-grey-700">
              {t("selectSpace_try_another_search")}
            </Text>
          </div>
        );
      }
      return searchResults.map(renderSpaceItem);
    }

    if (publicSpacesLoading) {
      return <Loader className="h-30vh" />;
    }
  };
  return {
    searchResults,
    renderSpacesList,
    handleSearchSuccessful,
    handleClearResults,
    handleLoadMore,
    isFetchingNextPage,
    hasNextPage,
  };
};
