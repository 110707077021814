import {
  EnrichedFavourite,
  EolasFile,
  eolasLogger,
  hasStringProp,
  isEolasShadowCopyFile,
} from "@eolas-medical/core";

import { useTranslation } from "react-i18next";
import { useDeleteParallelDraft } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useDeleteParallelDraft";
import { useUnpublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useUnpublishItem";
import { usePublishItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/usePublishItem";
import { modalStore } from "Stores/ModalStore";
import { ManageContentItemWizardModal } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/ManageContentItemWizardModal";
import { CopyItemsModal } from "Pages/Spaces/components/CopyItemsModal/CopyItemsModal";
import { MoveItemsModal } from "Pages/Spaces/components/MoveItemsModal/MoveItemsModal";
import { useNotifications } from "Components/Notifications";
import { useHistory } from "react-router-dom";
import { getPathToShadowCopyOriginal } from "shared/pages/ContentRepository/ContentItems/functions/getPathToShadowCopyOriginal";
import { useSpacesContext } from "modules/spaces";
import { ShowVersionsModal } from "shared/pages/ContentRepository/ContentItems/components/ShowVersionsModal/ShowVersionsModal";
import { useHandleClickContent } from "shared/pages/ContentRepository/ContentItems/components/ContentItemInstance/hooks/useHandleClickContent";
import { useAdminOperations } from "./useAdminOperations";
import { HandleShareOptions } from "shared/hooks/useShareFile";

import { NonCopilotEntityItem } from "shared/functions/generateLinkToItem";
import { useDownloadFile } from "Components/EolasFileList/hooks";
import { useDeleteItem } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/SpaceOrgContentItem/hooks/useDeleteItem";
import { getPathToItemOrSection } from "shared/pages/ContentRepository/ContentItems/functions/getPathToItemOrSection";

export type UseAdminOperationsSpaceOrgFileArgs = {
  item: EolasFile | null;
  isUnpublished: boolean;
  isShadowCopy: boolean;
  isInViewer: boolean;
  onShare?: (item: NonCopilotEntityItem | EnrichedFavourite, options?: HandleShareOptions) => void;
  isInAdminMode: boolean;
};
export const useAdminOperationsSpaceOrgFile = ({
  item,
  isUnpublished,
  onShare,
  isShadowCopy,
  isInViewer,
  isInAdminMode,
}: UseAdminOperationsSpaceOrgFileArgs) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const handleEdit = () => {
    if (isShadowCopy) {
      eolasLogger.error(
        new Error("Cannot edit shadow copy content item -- this should never happen"),
        { item },
      );
      return;
    }
    if (!item) return;
    modalStore.openModal({
      variant: "component",
      isDismissible: false,
      shouldRenderCloseButton: true,
      name: "editContentItem",
      Component: <ManageContentItemWizardModal sectionId={item.parentID} fileId={item.id} />,
    });
    return;
  };

  const { saveFile } = useDownloadFile(item);
  const handleDeleteItem = () => {
    if (item?.hasDrafts) {
      deleteParallelDraft();
      return;
    }
    deleteItem(isInViewer ? goBack : undefined);
  };

  const { deleteParallelDraft } = useDeleteParallelDraft(item);
  const { deleteItem } = useDeleteItem(item);
  const { unpublishItem } = useUnpublishItem(item);
  const { publishItem } = usePublishItem(item);

  const { showNotification } = useNotifications();
  const { encodedSelectedSpaceName } = useSpacesContext();
  const { push } = useHistory();
  const onPublishDraft = () => {
    publishItem();
  };

  const handleOnClickCopyTo = () => {
    if (!item) return;
    modalStore.openModal({
      name: "selectCopyTargetModal",
      variant: "component",
      Component: (
        <CopyItemsModal initialItems={[item]} onCopySuccess={isInViewer ? goBack : undefined} />
      ),
    });
  };

  const handleOnClickMoveTo = () => {
    if (!item) return;
    modalStore.openModal({
      name: "selectMoveTargetModal",
      variant: "component",
      Component: (
        <MoveItemsModal initialItems={[item]} onMoveSuccess={isInViewer ? goBack : undefined} />
      ),
    });
  };
  const handleGoToOriginal = async () => {
    let result;
    if (!item) return;
    if (isInViewer) {
      result = await getPathToItemOrSection({
        id: item.id,
        isInAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      });
    } else if (!isEolasShadowCopyFile(item)) {
      showNotification({
        type: "error",
        description: t("content_item_go_to_original_error"),
      });
      return;
    } else {
      result = await getPathToShadowCopyOriginal({
        shadowItem: item,
        isInAdminMode,
        encodedSelectedSpaceName: encodedSelectedSpaceName || null,
      });
    }
    if (hasStringProp(result, "error")) {
      eolasLogger.error(new Error("Error getting content item path"), { result });
      showNotification({
        type: "error",
        description: t("content_item_go_to_original_error"),
      });
      return;
    }
    push(result);
  };
  const { handleClickItem } = useHandleClickContent({
    id: item?.id ?? "",
    isAdminMode: isInAdminMode,
  });
  const handleShowVersions = () => {
    if (!item) return;
    modalStore.openModal({
      name: "showVersionsModal",
      variant: "component",
      Component: (
        <ShowVersionsModal contentId={item.id} handleClickVersionPreview={handleClickItem} />
      ),
    });
    // This specifically applies to Document Viewer as it is wrapped in a Modal, and subsequent clicks to preview versions while in the viewer causes confusing behaviour
    if (isInViewer) {
      goBack();
    }
  };

  return useAdminOperations({
    isInViewer,
    isShadowCopy,
    onEdit: handleEdit,
    eolasFile: item,
    isInAdminMode,
    isUnpublished,
    onDelete: handleDeleteItem,
    onPublishDraft,
    onDownload: saveFile,
    onCopy: handleOnClickCopyTo,
    onMove: handleOnClickMoveTo,
    onGoToOriginal: handleGoToOriginal,
    onShowVersions: handleShowVersions,
    onShare: onShare && item ? () => onShare(item) : undefined,
    onUnpublish: unpublishItem,
  });
};
