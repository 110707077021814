import i18n from "i18n";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  AppLevelSection,
  EolasFile,
  eolasLogger,
  getObjectEntries,
  hasStringProp,
  sectionStore,
} from "@eolas-medical/core";
import { generateS3PublicImageURL, useRequestStatus } from "Utilities";
import { useNotifications } from "Components/Notifications";
import { getPreSignUrl } from "Pages/Spaces/functions/helpers";
import {
  BaseContentItemSearchParams,
  ContentItemParams,
} from "shared/pages/ContentRepository/context/types";

export type SelectFileLoadingType = "notification" | "requestStatus";

export const openWindow = (url: string): void => {
  const newWin = window.open(url);
  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    alert(i18n.t("popip_blocked_message"));
  }
};

interface UseSelectFile {
  isLoading: boolean;
  error?: string;
  onSelectFile: (
    { name, key, type, mainSectionID, id }: Partial<EolasFile>,
    shadowCopyContentId?: string,
  ) => Promise<string>;
  openWindow: (url: string) => void;
}

export const useSelectFile = (
  loadingType: SelectFileLoadingType = "notification",
): UseSelectFile => {
  const { isLoading, error, setRequestStatus } = useRequestStatus();
  const { showNotification, hideNotification, updateNotification } = useNotifications();
  const { push } = useHistory();

  const _selectFile = React.useCallback(
    (
      { key, type, mainSectionID, id }: Partial<EolasFile> & { id: string },
      shadowCopyContentId?: string,
    ): Promise<string> => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        switch (type) {
          case "link":
          case "newsFeedLink":
            if (key) {
              openWindow(key);
            }
            resolve("opened");
            break;
          case "ms-office":
          case "mov":
          case "doc":
          case "docx":
          case "ppt":
          case "pptx":
          case "xls":
          case "xlsx":
          case "apng":
          case "png":
          case "jpg":
          case "jpeg":
          case "application/pdf":
          case "pdf":
            try {
              if (id) {
                const params: BaseContentItemSearchParams = {
                  [ContentItemParams.shouldShowBreadcrumbs]: true.toString(),
                  [ContentItemParams.shouldShowAdminOptions]: true.toString(),
                  [ContentItemParams.shadowCopyContentId]: shadowCopyContentId,
                };
                push(`/documents/viewer/${encodeURIComponent(id)}` + addSearchParams(params), {
                  shadowCopyContentId,
                });
              }
              if (key) {
                resolve(key);
              }
            } catch (error) {
              eolasLogger.error(error);
              reject(hasStringProp(error, "message") ? error.message : "unknown error");
            }

            break;
          case "mp4":
            try {
              let mp4URL;
              const mainSection = mainSectionID
                ? sectionStore.getMainSectionTypeFromMainSectionID(mainSectionID)
                : null;
              if (mainSection === AppLevelSection.patientLeaflets) {
                mp4URL = await generateS3PublicImageURL(key!);
              } else {
                mp4URL = await getPreSignUrl({ fileId: id });
              }
              resolve(mp4URL.toString());
            } catch (error) {
              eolasLogger.error(error);
              reject(hasStringProp(error, "message") ? error.message : "unknown error");
            }

            break;
          case "img":
          default:
            reject(i18n.t("selectFile_type_not_supported", { type }));
        }
      });
    },
    [push],
  );

  const onSelectFile = React.useCallback(
    async (
      { name, key, type, mainSectionID, id }: Partial<EolasFile>,
      shadowCopyContentId?: string,
    ): Promise<string> => {
      if (!key) return "";
      if (!id) return "";
      const promise = _selectFile({ key, type, mainSectionID, id }, shadowCopyContentId);

      if (loadingType === "notification") {
        const _notification = showNotification({
          type: "loading",
          title: name as string,
          description: i18n.t("notifications_open_file"),
        });

        return promise
          .then((response) => {
            hideNotification(_notification.id);
            return response;
          })
          .catch(() => {
            updateNotification({
              type: "error",
              title: name as string,
              autoHideTimeout: 5000,
              id: _notification.id,
              description: i18n.t("notifications_open_file_error"),
            });
            throw new Error(i18n.t("notifications_open_file_error"));
          });
      } else {
        setRequestStatus({ status: "pending", error: "" });
        return promise
          .then((response) => {
            setRequestStatus({ status: "success", error: "" });
            return response;
          })
          .catch((error) => {
            setRequestStatus({ status: "error", error });
            throw new Error(i18n.t("notifications_open_file_error"));
          });
      }
    },
    [
      loadingType,
      _selectFile,
      setRequestStatus,
      hideNotification,
      showNotification,
      updateNotification,
    ],
  );

  return { isLoading, error, onSelectFile, openWindow };
};

export const addSearchParams = <T extends { [key: string]: string | undefined }>(params: T) => {
  const searchParams = new URLSearchParams();
  getObjectEntries(params).forEach(([key, value]) => {
    if (typeof key === "string" && value) {
      searchParams.append(key, value);
    }
  });
  if (searchParams.size) {
    return `?${searchParams.toString()}`;
  }
  return "";
};
