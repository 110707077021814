import { useTranslation } from "react-i18next";
import { InnerModalWrapper, ModalBody, Text, Title } from "UIKit";
import { WarningPillProps } from "./WarningPill";
import { ResearchAndAuditIcon, DomainIcon } from "Assets";

export const WarningModal = ({ variant }: Pick<WarningPillProps, "variant">) => {
  const { t } = useTranslation();
  const isWebVariant = variant === "webFallback";

  return (
    <InnerModalWrapper>
      <div className="relative p-8 flex flex-col justify-center items-center max-h-80vh overflow-y-auto">
        <div className="w-20 h-20 my-8">
          {isWebVariant ? <DomainIcon /> : <ResearchAndAuditIcon />}
        </div>
        <Title level={4} className="flex-1 text-center">
          {isWebVariant ? t("ecq_webfallback_pill") : t("ecq_general_pill")}
        </Title>
        <ModalBody>
          <div className="flex text-center items-center justify-center space-x-4 flex-col">
            {isWebVariant ? (
              <Text level={1}>{t("ecq_webfallback_description")}</Text>
            ) : (
              <>
                <Text level={1}>{t("ecq_check_source_description")}</Text>
                <Text className="mt-4" level={1}>
                  {t("ecq_check_source_description_bold")}
                </Text>
              </>
            )}
          </div>
        </ModalBody>
      </div>
    </InnerModalWrapper>
  );
};
