import { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ApolloProvider } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { apolloClient, userStore } from "@eolas-medical/core";
import ScrollToTop from "Components/ScrollTop/ScrollTop";
import { useLaunchDarkly, useSentry } from "Contexts";
import { useAppSync, usePartialMaintenanceModeRedirect } from "Hooks";
import { AutoLogin } from "Pages/Authentication";
import { MaintenancePage } from "Pages/Maintenance";
import { ErrorFallback, LoadingOverlay } from "UIKit";
import { LDFlagNames } from "Utilities/types";
import AppRoutes from "Router";
import useNewAppRedirect from "Hooks/useNewAppRedirect/useNewAppRedirect";
import AccessLink from "Pages/Authentication/pages/Invitations/pages/AccessLink/AccessLink";
import { CheckInvitation } from "Pages/Authentication/pages/Invitations/pages/EmaiInvitation/CheckInvitation";
import NavigationProvider from "Components/Navigation/context/NavigationProvider";
import ChatInvite from "Pages/Chat/pages/ChatInvite";
import useRedirectToStore from "Hooks/useRedirectToStore/useRedirectToStore";
import { smartLookStartRecording } from "Utilities/appStartup";
import { SpacesLandingPage } from "deeplinking/pages/SpacesLandingPage";
import { OrganisationLandingPage } from "deeplinking/pages/OrganisationLandingPage";
import { GlobalFeedLandingPage } from "deeplinking/pages/GlobalFeedLandingPage";
import { KnowledgeLandingPage } from "deeplinking/pages/KnowledgeLandingPage";
import { MobxModal } from "modules/MobxModal/MobxModal";
import { Tooltip } from "react-tooltip";
import { EOLAS_TOOLTIP_ID } from "Hooks/useToolTip";
import { useMobileBrowserState } from "Hooks/useMobileBrowserState";
import { useSignoutSpaceListener } from "Hooks/useSignoutSpace";
import { RouteSync } from "Components/Navigation/components/RouteSync";

declare global {
  interface Document {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    documentMode?: any;
  }
}

const App = observer(() => {
  const { addBreadcrumb } = useSentry();
  const { flags, isLoading: isFlagsLoading } = useLaunchDarkly();
  const { userID } = userStore;
  const { refetch } = useAppSync();

  const { status, prevStatus } = useMobileBrowserState();

  useSignoutSpaceListener();

  useEffect(() => {
    if (status === "visible" && prevStatus === "hidden") {
      refetch();
    }
  }, [status, prevStatus, refetch]);

  useNewAppRedirect();
  useRedirectToStore();

  usePartialMaintenanceModeRedirect();

  useEffect(() => {
    if (process.env.REACT_APP_SENTRY_ENABLED !== "false") {
      smartLookStartRecording();
    }
  }, []);

  if (isFlagsLoading) {
    // TODO make this the same as the initial index.html html in root to reduce load 'flash'
    return <LoadingOverlay />;
  }

  if (userID && flags[LDFlagNames.WEB_MAINTENANCE]) {
    addBreadcrumb({
      category: "EolasAppRouter",
      message: "Rendering <MaintenancePage />",
    });
    return <MaintenancePage />;
  }

  return (
    <>
      <ScrollToTop />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ApolloProvider client={apolloClient}>
          <NavigationProvider>
            <RouteSync />
            <MobxModal />
            <Tooltip
              variant="light"
              float={false}
              className="shadow-md !rounded-sm bg-white !opacity-100 !z-50"
              id={EOLAS_TOOLTIP_ID}
            />
            <Switch>
              <Route exact path="/organisation/landing/:type" component={OrganisationLandingPage} />
              <Route exact path="/spaces/landing/:type" component={SpacesLandingPage} />
              <Route exact path="/globalFeed/landing/:type" component={GlobalFeedLandingPage} />
              <Route exact path="/knowledge/landing/:type" component={KnowledgeLandingPage} />
              <Route exact path="/auto-login/:token" component={AutoLogin} />
              <Route exact path="/invitation/:token" component={CheckInvitation} />
              <Route exact path="/access-link/:token" component={AccessLink} />
              <Route exact path="/chat/invite/group/:groupToken" component={ChatInvite} />
              <Route exact path="/chat/invite/private/:privateToken" component={ChatInvite} />
              <AppRoutes />
            </Switch>
          </NavigationProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </>
  );
});

export default App;
