import { isFlashCardContentItem } from "@eolas-medical/core";
import { mapToContentItem } from "../helpers";
import { useGetFile } from "Pages/Spaces/hooks/useGetFiles";

interface UseContentItem {
  mainSectionId: string;
  flashcardId: string;
  enabled?: boolean;
}
const useFlashcardItem = ({ flashcardId, enabled = true }: UseContentItem) => {
  const { file, isFetching, isError, error } = useGetFile({
    id: flashcardId,
    options: { isEnabled: enabled },
  });

  const flashcardData = file ? mapToContentItem(file) : undefined;
  const flashcard =
    flashcardData && isFlashCardContentItem(flashcardData) ? flashcardData : undefined;

  return {
    flashcard,
    flashcardLoading: isFetching,
    isError,
    error,
  };
};

export default useFlashcardItem;
