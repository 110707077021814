import { Tag, Title, Text } from "UIKit";
import { CaretRight } from "Assets/Icons";
import { LocationDescriptor } from "history";
import { useTranslation } from "react-i18next";
import { MainSectionBaseTile } from "./MainSectionBaseTile";

interface MainSectionTileProps {
  icon?: React.ReactNode;
  to: LocationDescriptor;
  title?: string;
  description?: string;
  tagLabel?: string;
  disabled?: boolean;
  attention?: boolean;
  "data-testid"?: string;
  shouldShowNewTag?: boolean;
  onClick?: () => void;
}

const tileIconClass =
  "flex items-center justify-center rounded-lg h-14 w-14 lg:h-24 lg:w-24 bg-grey-100";

export const MainSectionTile = ({
  icon = null,
  to,
  title = "",
  description = "",
  tagLabel,
  disabled,
  attention,
  "data-testid": dataTestId = "section-tile",
  shouldShowNewTag,
  onClick,
}: MainSectionTileProps) => {
  const { t } = useTranslation();

  return (
    <MainSectionBaseTile
      to={to}
      dataTestId={dataTestId}
      shouldShowAttention={attention}
      isClickDisabled={disabled}
      onClick={onClick}
    >
      <>
        <div className={`${tileIconClass} ${!disabled ? "group-hover:bg-blue-50" : ""}`}>
          {icon}
        </div>
        <div className="hidden lg:flex flex-col items-center mb-4 mt-4 text-center">
          <Title level={5.5} className="line-clamp-2" title={title}>
            {title}
          </Title>
          <Text level={2} className="line-clamp-2" title={description}>
            {description}
          </Text>
        </div>
        <div className="flex lg:hidden flex-col justify-center flex-1 space-y-1">
          <Text level={1} className="line-clamp-1 font-bold">
            {title}
          </Text>

          <Text level={3} className="line-clamp-2 text-grey-700">
            {description}
          </Text>
        </div>
        {shouldShowNewTag ? (
          <Tag className=" bg-yellow-300 text-yellow-700" label={t("general_new")} />
        ) : null}
        {tagLabel ? <Tag label={tagLabel} /> : null}

        <div className="flex lg:hidden justify-center items-center h-7 w-7 rounded-full bg-grey-100">
          <CaretRight className="h-6 w-6 text-blue-500" />
        </div>
      </>
    </MainSectionBaseTile>
  );
};
