import { useTranslation } from "react-i18next";

interface PostCognitoStepItemProps {
  backgroundImg: string;
  phoneImg: string;
  title: {
    text: string;
    color: string;
  };
  content: React.ReactNode;
}

const PostCognitoStepItem = ({
  backgroundImg,
  phoneImg,
  title,
  content,
}: PostCognitoStepItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="cursor-pointer relative flex justify-center items-center "
        style={{ height: "calc(100%)" }}
      >
        <img
          src={backgroundImg}
          className="h-72 absolute"
          style={{
            right: "0",
            top: "0",
            left: "0",
            borderBottomLeftRadius: "50% 15%",
            borderBottomRightRadius: "50% 15%",
            zIndex: "-1",
          }}
          alt=""
        />
        <img
          src={phoneImg}
          className="relative z-10"
          style={{
            top: "50%",
            transform: "translateY(8%)",
            height: "20rem",
          }}
          alt=""
        />
      </div>
      <div className="flex flex-col justify-center items-center mx-auto p-6 mt-4 w-full  md:w-5/6 ">
        <div className={`text-center text-${title.color} text-xl sm:text-3xl`}>{t(title.text)}</div>
        <div className="flex flex-col mx-auto space-y-4 mb-8 sm:mb-0">{content}</div>
      </div>
    </>
  );
};
export default PostCognitoStepItem;
