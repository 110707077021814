import { AdminFullIcon, HomeFullIcon } from "Assets/Icons";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "UIKit";
import { useEolasNavigation, useRoleSwitcher } from "../../hooks";
import { twMerge } from "tailwind-merge";

export const DesktopRoleSwitcher = observer(() => {
  const { t } = useTranslation();
  const { isOnAdminPanel, switchToAdmin, switchToUser } = useRoleSwitcher();

  const { activeTab, isOnSpacesRoot, isOnSelectSpaceRoot, isOnOrganisationRoot } =
    useEolasNavigation();

  const backgroundClass = (selected: boolean) => {
    if (isOnSpacesRoot || isOnSelectSpaceRoot) {
      return selected
        ? "bg-blue-500 bg-opacity-100"
        : "bg-blue-500 bg-opacity-30 hover:bg-opacity-20";
    }

    if (isOnOrganisationRoot) {
      return selected
        ? "bg-orange-400 bg-opacity-100"
        : " bg-orange-400 bg-opacity-30 hover:bg-opacity-20";
    }

    return "bg-white border border-grey-300";
  };

  const iconClass = (selected: boolean) => {
    if (isOnSpacesRoot || isOnSelectSpaceRoot || isOnOrganisationRoot) {
      return "text-white";
    }

    if (activeTab === "spaces" && selected) {
      return "text-blue-500";
    }

    if (activeTab === "organisation" && selected) {
      return "text-orange-400";
    }

    return "text-black";
  };

  return (
    <>
      <button
        onClick={switchToUser}
        data-testid="role-switcher_home-button"
        className={twMerge(
          "flex items-center justify-center h-12 w-12 rounded-full",
          backgroundClass(!isOnAdminPanel),
        )}
      >
        <HomeFullIcon className={twMerge("h-6 w-6 ", iconClass(!isOnAdminPanel))} />
      </button>

      <button
        onClick={switchToAdmin}
        data-testid="role-switcher_admin-button"
        className={twMerge(
          "flex items-center justify-center h-12 w-28 rounded-xl",
          backgroundClass(isOnAdminPanel),
        )}
      >
        <AdminFullIcon className={twMerge("h-6 w-6 ", iconClass(isOnAdminPanel))} />
        <Text level={2} className={twMerge("ml-2", iconClass(isOnAdminPanel))}>
          {t("navigation_admin_panel")}
        </Text>
      </button>
    </>
  );
});
