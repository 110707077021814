import { useRunOnMountUnmount } from "Hooks";
import { useHistory } from "react-router-dom";
import { tabSyncStore } from "Stores/TabSyncStore";

export const RouteSync = () => {
  const { push } = useHistory();

  useRunOnMountUnmount({
    onMount: () => {
      tabSyncStore.updateOnMessageCallback({
        type: "routeUpdate",
        callback: (e) => {
          push(e.data.payload.route);
          window.location.reload();
        },
      });
    },
    onUnmount: () => {
      tabSyncStore.clearOnMessageCallback();
    },
  });

  return null;
};
