import { AdminAnalyticsPayload, AnalyticsEvents } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { observer } from "mobx-react-lite";
import { useRequestAnalytics } from "Pages/Spaces/hooks/useRequestAnalytics";
import { useTranslation } from "react-i18next";
import { modalStore } from "Stores/ModalStore";
import { webStore } from "Stores/WebStore";
import { Button, InnerModalWrapper, Text, Title } from "UIKit";
import { ErrorModal } from "UIKit/Modal/modals/ErrorModal";
import { NotifiedModal } from "UIKit/Modal/modals/NotifiedModal";

export const RequestAnalyticsModal = observer(
  ({
    spaceOrgName,
    spaceOrgId,
    orgAdminSpaceId,
  }: {
    spaceOrgName: string;
    spaceOrgId: string;
    orgAdminSpaceId?: string;
  }) => {
    const { t } = useTranslation();
    const { requestAnalyticsZap, isError, isLoading, isSuccess } = useRequestAnalytics();
    const analyticsRequested = webStore.hasRequestedAnalytics(spaceOrgId);
    if (isSuccess) {
      return <NotifiedModal text={t("request_analytics_success")} />;
    }

    if (isError) {
      return (
        <ErrorModal onClose={() => modalStore.closeModal()} text={t("request_analytics_error")} />
      );
    }
    const handleClickRequest = () => {
      trackEvent<AdminAnalyticsPayload>(AnalyticsEvents.ANALYTICS_REQUESTED, {
        spaceOrgId,
      });
      requestAnalyticsZap({
        spaceOrgId,
        spaceOrgName,
        orgAdminSpaceId,
      });
    };
    return (
      <InnerModalWrapper>
        <div className="flex flex-col space-y-4 p-10 bg-grey-50 max-h-80vh overflow-y-auto">
          <Title className="self-center" level={5}>
            {t("request_analytics_title")}
          </Title>
          {analyticsRequested ? (
            <Text level={1} className="text-grey-900 text-center">
              {t("already_requested_analytics")}
            </Text>
          ) : (
            <>
              <Text level={1} className="text-grey-900">
                {t("request_analytics_desc_one")}
              </Text>
              <ul className="list-disc ml-12">
                <li>
                  <Text level={1} className="text-grey-900">
                    {t("request_analytics_list_one")}
                  </Text>
                </li>
                <li>
                  <Text level={1} className="text-grey-900">
                    {t("request_analytics_list_two")}
                  </Text>
                </li>
                <li>
                  <Text level={1} className="text-grey-900">
                    {t("request_analytics_list_three")}
                  </Text>
                </li>
              </ul>
              <Text level={1} className="text-grey-900">
                {t("request_analytics_desc_two")}
              </Text>
              <Button onClick={handleClickRequest} isLoading={isLoading}>
                <Text level={2}>{t("request_analytics_button")}</Text>
              </Button>
            </>
          )}
        </div>
      </InnerModalWrapper>
    );
  },
);
