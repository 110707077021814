import {
  MaleIcon,
  UrineIcon,
  WeightIcon,
  HeightIcon,
  FemaleIcon,
  CalendarIcon,
  CreatinineClearanceIcon,
} from "Assets";

export interface IResultSummaryProps {
  sex?: "male" | "female";
  age?: number;
  weight?: number;
  height?: number;
  creatinine?: number;
  creatinineClearance?: number;
}

export const ResultSummary = ({
  sex,
  weight,
  creatinine,
  age,
  height,
  creatinineClearance,
}: IResultSummaryProps) => {
  return (
    <div>
      <h2 className="text-lg">Summary</h2>
      <div className="grid px-4 my-2 grid-cols-3 gap-4">
        {sex ? (
          <span className="flex items-center capitalize text-base">
            {sex === "female" ? (
              <FemaleIcon className="w-6 h-6 mr-2" />
            ) : (
              <MaleIcon className="w-6 h-6 mr-2" />
            )}{" "}
            {sex}
          </span>
        ) : null}
        {weight ? (
          <span className="flex items-center text-base">
            <WeightIcon className="w-6 h-6 mr-2" />
            {weight}&nbsp;(kg)
          </span>
        ) : null}
        {creatinine ? (
          <span className="flex items-center text-base">
            <CreatinineClearanceIcon className="w-6 h-6 mr-2" />
            {creatinine}&nbsp;(μmol/L)
          </span>
        ) : null}
        {age ? (
          <span className="flex items-center text-base">
            <CalendarIcon className="w-6 h-6 mr-2" />
            {age} (years)
          </span>
        ) : null}
        {height ? (
          <span className="flex items-center text-base">
            <HeightIcon className="w-6 h-6 mr-2" />
            {height}&nbsp;(cm)
          </span>
        ) : null}
        {creatinineClearance ? (
          <span className="flex items-center text-base">
            <UrineIcon className="w-6 h-6 mr-2" />
            {creatinineClearance}&nbsp;(ml/min)
          </span>
        ) : null}
      </div>
    </div>
  );
};
