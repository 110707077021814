import {
  ContentItem,
  EolasFile,
  EolasShadowCopyContent,
  EolasShadowCopyFile,
  isEolasFile,
  isEolasShadowCopyFile,
} from "@eolas-medical/core";
import { WarningIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { Button, InnerModalWrapper, Text, Title } from "UIKit";
import { EolasVersatileList } from "UIKit/EolasVersatileList/EolasVersatileList";
import { EolasVersatileListRenderItemProps } from "UIKit/EolasVersatileList/EolasVersatileList.types";
import { useTranslation } from "react-i18next";
import { BulkResponses, SelectableItem, ValidItem } from "../types";

export type InvalidItemsModalCommonProps<T extends BulkResponses> = {
  invalidItems: SelectableItem[];
  validItems: ValidItem<T>[];
  onContinue: () => void;
};

export type InvalidItemsModalStrings = {
  invalidFilesTitle: string;
  allInvalidFilesDescription: string;
  invalidFilesDescription: string;
};

export const InvalidItemsModal = <T extends BulkResponses>({
  onContinue,
  invalidFilesTitle,
  allInvalidFilesDescription,
  invalidFilesDescription,
  invalidItems,
  validItems,
}: InvalidItemsModalCommonProps<T> & InvalidItemsModalStrings) => {
  const { t } = useTranslation();
  const areAllInvalid = validItems.length === 0;
  return (
    <InnerModalWrapper>
      <div
        className={`flex flex-col items-center space-y-2 sm:space-y-4 p-6 px-12 sm:p-12 ${
          areAllInvalid ? "h-25vh" : "h-50vh"
        } `}
      >
        <div>
          <WarningIcon className="h-16 w-16" />
        </div>
        <Title level={8} className="mt-4">
          {invalidFilesTitle}
        </Title>
        <Text level={2} className="text-gray-500">
          {areAllInvalid ? allInvalidFilesDescription : invalidFilesDescription}
        </Text>
        {areAllInvalid ? null : (
          <div className="flex flex-col h-full w-full">
            <Text level={2} className="text-black">
              {t("copy_files_invalid_files_shortcut_files") + ` (${invalidItems.length})`}
            </Text>
            <EolasVersatileList
              className="w-full"
              items={invalidItems}
              renderItem={InvalidItems}
              isInModal
              outerContainerClassName="bg-grey-300 rounded-xl py-2 mt-2"
              innerContainerClassName="px-4 first:pt-2 last:pb-2 h-full w-full"
            />
            <Button
              size="lg"
              color="blue"
              variant="solid"
              onClick={() => (areAllInvalid ? modalStore.closeModal() : onContinue())}
              className="w-full self-center sm:w-full mt-4 sm:mt-8"
            >
              {t("general_continue")}
            </Button>
          </div>
        )}
      </div>
    </InnerModalWrapper>
  );
};

const InvalidItems = ({
  item,
}: EolasVersatileListRenderItemProps<
  ContentItem | EolasShadowCopyContent | EolasFile | EolasShadowCopyFile
>) => {
  let fileName = "";
  if (isEolasFile(item) || (isEolasFile(item) && isEolasShadowCopyFile(item))) {
    fileName = item.type ? `${item.name}.${item.type}` : item.name;
  } else {
    fileName = item.media?.type ? `${item.name}.${item.media.type}` : item.name;
  }

  return (
    <div className="pb-2">
      <Text level={1} className="text-grey-700 line-clamp-1">
        {fileName}
      </Text>
    </div>
  );
};
