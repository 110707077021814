import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

import { userStore } from "@eolas-medical/core";

import { MailIcon } from "Assets";
import { Input, Button, FormError, FormElement } from "UIKit";

import { useRequestStatus } from "Utilities";

const sendCodeSchema = yup.object().shape({
  email: yup.string().email().required().label("Email"),
});

export type EditEmailsModalStates = "ADD_ANOTHER_EMAIL" | "VERIFY_CODE" | "SUCCESS";

interface AddAnotherEmailInputProps {
  sendVerificationCode: (email: string) => void;
  sendingCode: boolean;
}

export const AddAnotherEmailInput: React.FC<AddAnotherEmailInputProps> = ({
  sendingCode,
  sendVerificationCode,
}: AddAnotherEmailInputProps) => {
  const { t } = useTranslation();
  const { verifiedEmails, email } = userStore?.userData || {};

  const { control, handleSubmit, formState } = useForm<{ email: string }>({
    defaultValues: { email: "" },
    mode: "onChange",
    resolver: yupResolver(sendCodeSchema),
  });

  const { error, setRequestStatus } = useRequestStatus();

  const onSubmit = handleSubmit((values) => {
    if ([email, ...(verifiedEmails || [])].includes(values.email)) {
      setRequestStatus({
        status: "error",
        error: t("medical_personal_details_emails_already_exists"),
      });
    } else {
      sendVerificationCode(values.email);
    }
  });

  return (
    <div className="mt-8">
      <div className="text-lg">{t("my-medical_personal_details_emails_add_another_email")}</div>

      <form onSubmit={onSubmit} className="flex flex-col self-center w-full mt-4">
        <FormElement id="email" control={control} className="mb-4" required>
          {(field) => (
            <Input {...field} iconLeft={<MailIcon />} placeholder={t("login_enterEmail")} />
          )}
        </FormElement>

        <Button
          type="submit"
          disabled={!formState.isValid}
          className="add-file__submit-button"
          isLoading={formState.isSubmitting || sendingCode}
        >
          {t("my-medical_personal_details_emails_send_ver_code")}
        </Button>

        <FormError error={error} />
      </form>
    </div>
  );
};
