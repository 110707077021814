import React from "react";
import { BaseInteractionsMessage } from "@eolas-medical/core";
import { overrideTailwindClasses as tw } from "tailwind-override";
import BnfContentElement from "../../../../components/BnfContentElement/BnfContentElement";

interface IndividualInteractionProps {
  message: BaseInteractionsMessage;
  index: number;
  arr: BaseInteractionsMessage[];
}

const EVIDENCE_TO_IGNORE = "Normal";

const IndividualInteraction: React.FC<IndividualInteractionProps> = ({ message, index, arr }) => {
  const isLast = index === arr.length - 1;
  const isSevere = message.severityOrder > 3;
  const isNextSevere = (arr[index + 1]?.severityOrder ?? 0) > 3;

  let containerClasses = "border-b border-grey-400 px-2";
  if (isSevere) {
    containerClasses = "bg-red-100 rounded-lg px-2 my-2 py-3";
  } else if (isLast || isNextSevere) {
    containerClasses = "px-2";
  }

  const evidence = `${message.severity}${message.evidence ? ` ${message.evidence}` : ""}`;
  const shouldShowEvidence = evidence !== EVIDENCE_TO_IGNORE;

  return (
    <div className={tw(containerClasses)}>
      <BnfContentElement htmlContent={message.message} />
      {shouldShowEvidence ? <div className="mb-2">{evidence}</div> : null}
    </div>
  );
};

export default IndividualInteraction;
