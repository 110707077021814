import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { AddButton, Button, InnerPageWrapper, Loader, Modal, PageTitle, Text } from "UIKit";
import ContentItemList from "./components/ContentItemList/ContentItemList";
import AddContentItemWizard from "./components/AddContentItemWizard/AddContentItemWizard";
import useContentRepositoryContext from "../context/useContentRepositoryContext";
import AddFlashcardItemWizard from "./components/AddFlashcardItemWizard/AddFlashcardItemWizard";
import PaginatedContentItemList from "./components/PaginatedContentItemList/PaginatedContentItemList";
import { eolasLogger, Maybe, sectionStore, SupportedFileType } from "@eolas-medical/core";
import AddEolasEditorItemWizard from "./components/AddEolasEditorItemWizard/AddEolasEditorItemWizard";
import { NavBackButton } from "shared/components/LogicComponents";
import { DownloadSectionMapModal } from "Pages/Spaces/components/DownloadSectionMapModal/DownloadSectionMapModal";
import { modalStore } from "Stores/ModalStore";
import { ManageContentItemWizardModal } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/components/ManageContentItemWizard/ManageContentItemWizardModal";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";
import { useNotifications } from "Components/Notifications/NotificationsContext";
import { useLaunchDarkly } from "Contexts/LaunchDarklyContext";
import { LDFlagNames } from "Utilities/types";
import { useShareFile } from "shared/hooks/useShareFile";
import { ShareMainSectionButton } from "Pages/Spaces/pages/Space/pages/SpaceContentRepository/pages/SpaceContentSections/components/ShareButton";

interface ContentItemsProps {
  contentRepositoryId: string;
  subSectionId: string;
  isAdmin?: boolean;
  showExpiredItems?: boolean;
  defaultAddItemWizard?: AddItemWizard;
  flashcardsHaveSpecialties?: boolean;
  disableReorder?: boolean;
  isPaginated?: boolean;
  title?: string | null;
  allowedAdminActions?: ("shadowCopy" | "delete")[];
}

type AddItemWizard = "content-item" | "flashcard-item" | "eolas-editor-item" | null;

const ContentItems = ({
  contentRepositoryId,
  subSectionId,
  isAdmin = false,
  showExpiredItems,
  defaultAddItemWizard = "content-item",
  flashcardsHaveSpecialties = false,
  disableReorder = false,
  isPaginated = false,
  title,
  allowedAdminActions,
}: ContentItemsProps) => {
  const { t } = useTranslation();
  const { push, location } = useHistory();
  const { showNotification } = useNotifications();
  const { flags } = useLaunchDarkly();

  const {
    useContentRepositoryHook,
    useContentSectionHook,
    hasCopilotData,
    contentSectionConfig,
    contentItemConfig,
    ...rest
  } = useContentRepositoryContext();
  const { contentRepository, contentRepositoryLoading } = useContentRepositoryHook;
  const { contentSection, contentSectionLoading } = useContentSectionHook;

  const isSponsoredSlots =
    rest.contextDomain === "knowledge" &&
    (rest.knowledgeContentDomain === "sponsoredSlots" ||
      rest.knowledgeContentDomain === "sponsoredSlotsContent");

  const { contextDomain } = rest;

  const isSubSection = !!subSectionId;

  const [openedWizard, setOpenedWizard] = useState<AddItemWizard>(null);

  const isShareSectionEnabled = !!flags[LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS];

  const { isShareFileEnabled, handleOnShareFile } = useShareFile({ shouldUseMobxModal: true });

  const canShareSection = isShareSectionEnabled && isShareFileEnabled;

  const handleShareSection = () => {
    if (contentSection) {
      const section = sectionStore.getSection(contentSection.id);
      if (section?.id) {
        handleOnShareFile(section);
        return;
      }
    }

    eolasLogger.error(new Error("Failed to get section for sharing"), { contentSection });
    showNotification({
      type: "error",
      autoHideTimeout: 3000,
      description: t("share_item_link_failure"),
    });
  };

  const handleDownloadSectionStructure = () => {
    modalStore.openModal({
      name: "downloadSectionMap",
      variant: "component",
      Component: <DownloadSectionMapModal mainSectionId={contentRepositoryId} />,
    });
  };

  const handleAddItem = () => {
    if (contextDomain === "spaceOrg") {
      modalStore.openModal({
        name: "manageContentItemWizard",
        isDismissible: false,
        shouldRenderCloseButton: true,
        variant: "component",
        Component: (
          <ManageContentItemWizardModal
            fileId={null}
            sectionId={contentSection?.id || contentRepositoryId || ""}
          />
        ),
      });
      return;
    }
    setOpenedWizard(defaultAddItemWizard);
  };

  const handleCloseModal = () => {
    setOpenedWizard(null);
  };

  const handleChangeWizard = (editorType: SupportedFileType) => {
    if (editorType === "flashcard") {
      setOpenedWizard("flashcard-item");
    }
    if (editorType === "eolas-editor") {
      setOpenedWizard("eolas-editor-item");
    }
  };

  const handleChangeToContentWizard = () => {
    if (defaultAddItemWizard !== "content-item") {
      handleCloseModal();
      return;
    }

    setOpenedWizard("content-item");
  };

  const handleManageExpired = () => {
    push(`${location.pathname}/expired-items`);
  };

  if (contentSectionLoading || contentRepositoryLoading) {
    return (
      <div className="h-100vh flex justify-center items-center">
        <Loader className="bg-transparent" />
      </div>
    );
  }

  let pageTitle: Maybe<string> = title;
  const pageDescription: Maybe<string> = isSubSection
    ? contentSection?.description
    : contentRepository?.description;

  if (!pageTitle) {
    pageTitle = isSubSection ? contentSection?.name : contentRepository?.name;
  }

  return (
    <>
      <Modal
        open={!!openedWizard}
        onClose={handleCloseModal}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
      >
        {openedWizard === "content-item" ? (
          <AddContentItemWizard onClose={handleCloseModal} onChangeWizard={handleChangeWizard} />
        ) : null}
        {openedWizard === "flashcard-item" ? (
          <AddFlashcardItemWizard
            mainSectionId={contentRepositoryId}
            hasSpecialties={flashcardsHaveSpecialties}
            hasCopilotData={hasCopilotData}
            cancelOnBack={defaultAddItemWizard === "flashcard-item"}
            onClose={handleCloseModal}
            onChangeWizard={handleChangeToContentWizard}
          />
        ) : null}
        {openedWizard === "eolas-editor-item" ? (
          <AddEolasEditorItemWizard
            mainSectionId={contentRepositoryId}
            onClose={handleCloseModal}
            onChangeWizard={handleChangeToContentWizard}
          />
        ) : null}
      </Modal>
      <InnerPageWrapper>
        {contentItemConfig?.shouldShowBreadcrumbs ? (
          <FileBreadcrumbs
            shouldShowSpaceName={true}
            mainSectionId={contentRepositoryId}
            parentId={contentSection?.id}
          />
        ) : null}

        <div className="flex flex-col justify-center">
          <PageTitle title={pageTitle || ""} />
          {isSponsoredSlots && pageDescription ? (
            <div className="text-center text-sm text-grey-500 mb-4">
              <Text level={2} className={"text-black"}>
                {pageDescription}
              </Text>
            </div>
          ) : null}
          <NavBackButton
            modePreference={isSponsoredSlots ? "goBackHistory" : "removeFromPath"}
            segmentsToRemove={isSubSection ? 1 : 2}
          />
        </div>

        {isAdmin ? (
          <div className="flex justify-center items-center space-x-4">
            <AddButton onClick={handleAddItem} data-testid="add-content-item-button">
              {t("Add Item")}
            </AddButton>
            {!isSubSection && showExpiredItems ? (
              <Button variant="outline" color="white" size="lg" onClick={handleManageExpired}>
                {t("repository_manage_expired")}
              </Button>
            ) : null}
            {contentSectionConfig?.canDownloadSectionMap && !isSubSection ? (
              <Button
                onClick={handleDownloadSectionStructure}
                iconLeft="DownloadIcon"
                color="white"
                size="lg"
              >
                {t("repository_content_map")}
              </Button>
            ) : null}
            {contentSectionConfig?.shouldAllowSharing && !isSubSection && canShareSection ? (
              <ShareMainSectionButton
                onClick={handleShareSection}
                text={t("repository_share_main_section")}
              />
            ) : null}
          </div>
        ) : (
          <div className="flex justify-center items-center space-x-4">
            {contentSectionConfig?.shouldAllowSharing && !isSubSection && canShareSection ? (
              <ShareMainSectionButton
                onClick={handleShareSection}
                text={t("repository_share_main_section")}
              />
            ) : null}
          </div>
        )}

        {isPaginated ? (
          <PaginatedContentItemList
            isAdmin={isAdmin}
            flashcardsHaveSpecialties={flashcardsHaveSpecialties}
          />
        ) : (
          <ContentItemList
            contentRepositoryId={contentRepositoryId}
            subSectionId={subSectionId}
            isInAdminMode={isAdmin}
            doFlashcardsHaveSpecialties={flashcardsHaveSpecialties}
            shouldDisableReorder={disableReorder}
            allowedAdminActions={allowedAdminActions}
          />
        )}
      </InnerPageWrapper>
    </>
  );
};

export default ContentItems;
