import { LineChartIcon, PiechartIcon } from "Assets";
import { useMediaQuery } from "Hooks";
import { Button } from "UIKit/Button";
import { Loader } from "UIKit/Loader";
import { ProgressChart } from "UIKit/ProgressChart/ProgressChart";
import { Text, Title } from "UIKit/Typography";

interface TotalOpenRatesProps {
  titleText?: string;
  chartText?: string;
  buttonRateText?: string;
  buttonRateTextSmall?: string;
  average: string;
  isLoading?: boolean;
  onOpenRate?: () => void;
  showOpenRateTrend?: boolean;
  showBoostOpenRate?: boolean;
  onOpenChart?: () => void;
}

const TotalOpenRates = ({
  titleText = "Total open rates",
  chartText = "See chart for your open rate trend",
  buttonRateText = "Boost Open Rate",
  buttonRateTextSmall = "Boost",
  isLoading = false,
  showOpenRateTrend = false,
  showBoostOpenRate = true,
  average,
  onOpenRate,
  onOpenChart,
}: TotalOpenRatesProps) => {
  const media = useMediaQuery();

  const isDestkopDevice = media === "xl" || media === "2xl";

  const isMobile = media === "sm" || media === "xs";

  if (isLoading) {
    return <Loader className="bg-transparent h-30vh" />;
  }

  return (
    <div className="flex flex-col space-y-6 bg-white rounded-lg shadow-lg p-8">
      <div className="flex justify-start items-center">
        <PiechartIcon className="h-18 w-18 mr-2" />
        <Title level={6}>{titleText}</Title>
      </div>
      <div className="flex justify-center items-center space-x-2">
        <ProgressChart average={average} />
        {showBoostOpenRate ? (
          <Button
            iconLeft={!isMobile ? "TrendIcon" : null}
            size="sm"
            className="w-3/12"
            variant="outline"
            onClick={onOpenRate}
            isLoading={isLoading}
          >
            <Text level={1} className="ml-2">
              {isDestkopDevice ? buttonRateText : buttonRateTextSmall}
            </Text>
          </Button>
        ) : null}
      </div>
      {showOpenRateTrend ? (
        <div className="flex justify-center items-center cursor-pointer" onClick={onOpenChart}>
          <Title level={9} className="text-blue-500">
            {chartText}
          </Title>
          <LineChartIcon className="ml-2" />
        </div>
      ) : null}
    </div>
  );
};
export default TotalOpenRates;
