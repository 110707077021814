import { useMediaQuery } from "Hooks";
import { useTranslation } from "react-i18next";
import { Button, Loader, Text, Title } from "UIKit";
import { Space } from "@eolas-medical/core";
import AccessQRCode from "../AccessQRCode/AccessQRCode";
import { modalStore } from "Stores/ModalStore";

interface SpaceDetailsProps {
  onClickViewSpace?: () => void;
  onClickEditSpace: () => void;
  isAdminUser: boolean;
  isLoading?: boolean;
  isPublic: boolean;
  selectedSpace: Space;
  isOrgAdminSpace?: boolean;
}

const SpaceDetailsMobile = ({
  isPublic,
  isOrgAdminSpace = false,
  selectedSpace,
}: SpaceDetailsProps) => {
  const handleClickShareSpace = () =>
    modalStore.openModal({
      variant: "component",
      Component: <AccessQRCode onClose={() => modalStore.closeModal()} />,
      name: "accessQRCode",
    });
  const { t } = useTranslation();
  const entity = isOrgAdminSpace ? "Organisation" : "Space";
  return (
    <>
      <Title level={5.5}>{selectedSpace.name || ""}</Title>
      <div>
        {isPublic ? (
          <ShareSpaceButton
            onClickShareSpace={handleClickShareSpace}
            text={t("space_details_share_space", { entity })}
          />
        ) : null}
      </div>
    </>
  );
};

const SpaceDetailsDesktop = ({
  isLoading = false,
  isPublic,
  selectedSpace,
  isOrgAdminSpace = false,
}: SpaceDetailsProps) => {
  const { t } = useTranslation();
  const entity: "Space" | "Organisation" = isOrgAdminSpace ? "Organisation" : "Space";
  const usersCount = selectedSpace.userCount || 1;
  const handleClickShareSpace = () =>
    modalStore.openModal({
      variant: "component",
      Component: <AccessQRCode onClose={() => modalStore.closeModal()} />,
      name: "accessQRCode",
    });
  return (
    <div className="flex flex-col z-10">
      {isLoading ? (
        <Loader className="bg-transparent" />
      ) : (
        <div className="flex flex-col w-full mt-8 gap-2">
          <Title level={3}>{selectedSpace.name || ""}</Title>
          <Text level={1}>
            {usersCount}
            {usersCount > 1 ? t("space_details_members") : t("space_details_member")}
          </Text>
          <div className="mt-4">
            {isPublic ? (
              <ShareSpaceButton
                onClickShareSpace={handleClickShareSpace}
                text={t("space_details_share_space", { entity })}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

const SpaceDetails = (props: SpaceDetailsProps) => {
  const media = useMediaQuery();
  const isMobile = media === "xs";

  return isMobile ? <SpaceDetailsMobile {...props} /> : <SpaceDetailsDesktop {...props} />;
};

export const ShareSpaceButton = ({
  onClickShareSpace,
  text,
}: {
  onClickShareSpace: () => void;
  text: string;
}) => {
  return (
    <Button
      iconLeft="ShareIcon"
      variant="ghost"
      color="blue"
      size="sm"
      className="h-10 rounded-md hover:bg-opacity-50 font-bold"
      onClick={onClickShareSpace}
    >
      <Text level={1} className="ml-2">
        {text}
      </Text>
    </Button>
  );
};

export default SpaceDetails;
