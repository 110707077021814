import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { webStore } from "Stores/WebStore";
import { ExplanationComponents } from "./ExtraInformation";
import { ClinicalCalculatorsSubSection } from "@eolas-medical/core";
import { Button, InnerModalWrapper, Modal, ModalBody, ModalHeader, Text, Title } from "UIKit";

interface AttentionModalProps {
  calculatorType: ClinicalCalculatorsSubSection;
}

export const AttentionModal: React.FC<AttentionModalProps> = ({
  calculatorType,
}: AttentionModalProps) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(
    !webStore.seenCalculatorsWarning[calculatorType],
  );
  const { t } = useTranslation();

  const consentToWarningMessage = () => {
    webStore.setCalculatorsWarningAsSeen(calculatorType);
    setShowModal(false);
  };

  return (
    <Modal onClose={() => history.push("/knowledge/clinicalCalculators")} open={showModal}>
      <InnerModalWrapper>
        <ModalHeader>
          <Title level={5}>{t("general_attention")}</Title>
        </ModalHeader>

        <ModalBody className="space-y-4 sm:space-y-8 max-h-60vh">
          <Text className="whitespace-pre-line" level={1}>
            {t("clinicalCalculators_attentionModal_subtitle")}
          </Text>
          <div className="bg-grey-200 rounded-md p-6 flex flex-col space-y-2 overflow-auto">
            <Text className="self-center pb-4" level={1}>
              {t("clinicalCalculators_attentionModal_info")}
            </Text>
            <div>{ExplanationComponents[calculatorType]()}</div>
          </div>

          <Text className="whitespace-pre-line my-8" level={1}>
            {t("clinicalCalculators_attentionModal_comment")}
          </Text>
          <Button
            className="w-36 lg:w-56 rounded-xl self-center py-3"
            onClick={consentToWarningMessage}
          >
            {t("clinicalCalculators_attentionModal_confirm")}
          </Button>
        </ModalBody>
      </InnerModalWrapper>
    </Modal>
  );
};
