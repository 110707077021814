import { Interactants } from "@eolas-medical/core";
import { Text } from "UIKit";
import { makeBnfRoute } from "../../../routes/BnfRoutes";
import { useHistory } from "react-router";
import { capitalCase } from "change-case";
import { useMonographContext } from "../../../contexts/ MonographTypeContext";
import { Strings } from "./InteractantsContent.strings";
import { LINK_CLASS } from "../../../styles/stylesClasses";

interface InteractantsProps {
  section: Interactants;
}

const InteractantsContent = ({ section }: InteractantsProps) => {
  const { push } = useHistory();
  const { type } = useMonographContext();
  return (
    <div className="flex flex-col">
      {section.length > 0 ? (
        section.map((interactor) => {
          const formattedTitle = capitalCase(interactor.title);
          return (
            <div key={interactor.sid} className="px-3">
              <div className="mt-4">
                <Text level={2}>{interactor.title}</Text>
              </div>
              <div className="mt-3">
                <a
                  onClick={() =>
                    push(
                      makeBnfRoute({
                        routeKey:
                          type === "bnf"
                            ? "singleDrugInteractionsBnf"
                            : "singleDrugInteractionsBnfc",
                        idOrSid: interactor.sid,
                        title: formattedTitle,
                      }),
                    )
                  }
                  className={`${LINK_CLASS} text-sm`}
                >
                  {Strings.viewInteractions}
                </a>
              </div>
            </div>
          );
        })
      ) : (
        <Text level={2} className="px-4 mt-4">
          {Strings.noInteractions}
        </Text>
      )}
    </div>
  );
};

export default InteractantsContent;
