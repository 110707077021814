import {
  AdminAnalyticsPayload,
  AnalyticsEvents,
  eolasLogger,
  sectionStore,
  spacesClient,
  userStore,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useRoleSwitcher } from "Components/Navigation/hooks";
import { AdminDashboardIconType } from "Hooks";
import { RequestAnalyticsModal } from "Pages/Spaces/components/RequestAnalyticsModal/RequestAnalyticsModal";
import {
  isAccessLevelInAdminGroup,
  useGetAdminStatus,
} from "Pages/Spaces/pages/hooks/useGetAdminStatus";
import { useEffect } from "react";
import { modalStore } from "Stores/ModalStore";
import { sanitiseUrl } from "Utilities/helpers";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

type AdminDashboardSection = {
  title: string;
  route: string;
  description: string;
  id: AdminDashboardIconType;
  disabled?: boolean;
};

const limitedAdminSections: AdminDashboardSection[] = [
  {
    id: "contentManagement",
    title: "adminHome_cm_card_title",
    description: "adminHome_cm_card_description",
    route: "content-management",
  },
];

const adminSections: AdminDashboardSection[] = [
  ...limitedAdminSections,
  {
    id: "manageUsers",
    title: "adminHome_manage_users_card_title",
    description: "adminHome_manage_users_card_description",
    route: "manageUsers",
  },
  {
    id: "adminAnalytics",
    title: "Analytics",
    description: "adminHome_analytics_organisation_description",
    route: "analytics",
  },
];

const useOrganisationAdminSections = () => {
  const fullAccessLevel = useGetAdminStatus({ activeTab: "organisation" });
  const { switchToUser } = useRoleSwitcher();
  const { showNotification } = useNotifications();
  const { t } = useTranslation();

  const { mutateAsync: getSpace, isLoading } = useMutation({
    mutationFn: (spaceId: string) => spacesClient.getSpace(spaceId),
    mutationKey: ["getOrgAdminSpace"],
  });

  const handleAnalyticsClick = async () => {
    try {
      if (!userStore.orgUserData?.spaceId) {
        throw new Error("No admin org space found");
      }
      const orgAdminSpace = await getSpace(userStore.orgUserData.spaceId);
      if (!orgAdminSpace.analyticsUrl) {
        trackEvent<AdminAnalyticsPayload>(
          AnalyticsEvents.ANALYTICS_TILE_CLICKED_BEFORE_ACCESS_GRANTED,
          {
            spaceOrgId: sectionStore.organisationID,
          },
        );
        modalStore.openModal({
          name: "analyticsModal",
          variant: "component",
          Component: (
            <RequestAnalyticsModal
              spaceOrgName={sectionStore.data.organisation?.name ?? ""}
              spaceOrgId={sectionStore.organisationID}
              orgAdminSpaceId={userStore.orgUserData.spaceId}
            />
          ),
          isDismissible: false,
        });
        return;
      }
      const sanitisedUrl = sanitiseUrl(orgAdminSpace.analyticsUrl);
      if (!sanitisedUrl) {
        throw new Error(`Invalid analytics URL: ${orgAdminSpace.analyticsUrl}`);
      }
      window.open(sanitisedUrl, "_blank");
      trackEvent<AdminAnalyticsPayload>(AnalyticsEvents.ANALYTICS_ACCESSED, {
        spaceOrgId: sectionStore.appID,
      });
    } catch (e) {
      eolasLogger.error(e, { orgUserData: userStore.orgUserData });
      showNotification({
        type: "error",
        description: t("request_analytics_error"),
        autoHideTimeout: 5000,
      });
    }
  };

  useEffect(() => {
    if (!isAccessLevelInAdminGroup(fullAccessLevel)) {
      switchToUser();
    }
  }, [fullAccessLevel, switchToUser]);

  if (fullAccessLevel === "admin") {
    return { activeAdminMainSections: adminSections, handleAnalyticsClick, isLoading };
  }

  if (fullAccessLevel === "limitedAdmin") {
    return { activeAdminMainSections: limitedAdminSections };
  }

  return { activeAdminMainSections: [] };
};

export default useOrganisationAdminSections;
