import { contentRepository, miniApps, spaceBackground } from "Assets";
import { LottieWithHeader } from "UIKit";
import { Pill } from "UIKit/Pill";
import { Sizes } from "UIKit/types";
import { useTranslation } from "react-i18next";

const usePostSpaceCreationSteps = () => {
  const { t } = useTranslation();

  const postSpaceCreationSteps = [
    {
      backgroundImg: spaceBackground,
      animation: (
        <LottieWithHeader
          animationSize="50%"
          animation={contentRepository}
          lottieOptions={{ loop: true }}
          text={""}
        />
      ),
      pill: (
        <Pill
          value={t("general_feature_guide")}
          className="text-xs bg-blue-50 text-blue-500"
          size={Sizes.SMALL}
        />
      ),
      title: { text: t("post_space_content_repository_title"), color: "black" },
      content: [
        {
          text: t("post_space_content_repository_description"),
        },
      ],
    },
    {
      backgroundImg: spaceBackground,
      animation: (
        <LottieWithHeader
          animationSize="76.5%"
          animation={miniApps}
          lottieOptions={{ loop: true }}
          text={""}
        />
      ),
      pill: (
        <Pill
          value={t("general_feature_guide")}
          className="text-xs bg-blue-50 text-blue-500"
          size={Sizes.SMALL}
        />
      ),
      title: { text: t("post_space_mini_apps_title"), color: "black" },
      content: [
        {
          text: t("post_space_mini_apps_description"),
        },
      ],
    },
  ];

  return { postSpaceCreationSteps };
};

export default usePostSpaceCreationSteps;
