import { EolasTile } from "UIKit/EolasTile/EolasTile";
import { BasicFileTile as BasicFileTileProps } from "../EolasFileTile.types";
import { Title } from "UIKit/Typography/Title";
import { LinkIcon } from "Assets/Icons/colored";
import { useTranslation } from "react-i18next";
import { useToolTip } from "Hooks/useToolTip";

export const BasicFileTile = ({
  isDisabled,
  isDraggable,
  isShadowCopy,
  iconComponent,
  title,
  description,
  onClick,
  rightAction,
}: BasicFileTileProps) => {
  return (
    <EolasTile
      shouldShowDragHandle={isDraggable}
      isDisabled={isDisabled}
      variant="rectangular"
      primaryText={<PrimaryText title={title} isShadowCopy={isShadowCopy} />}
      secondaryText={description}
      rightAction={rightAction}
      icon={iconComponent}
      onClick={onClick}
    />
  );
};

const PrimaryText = ({
  title,
  isShadowCopy = false,
}: {
  title: string;
  isShadowCopy?: boolean;
}) => {
  const { t } = useTranslation();
  const { makeToolTipDataAttr } = useToolTip();

  const tooltipDataAttr = makeToolTipDataAttr({
    text: t("component_admin_file_tile_shadow_copy_tooltip"),
  });
  return (
    <div className="flex flex-row items-center gap-2">
      <Title level={8} className="line-clamp-2 font-semibold">
        {title}
      </Title>
      {isShadowCopy ? (
        <>
          <LinkIcon className="h-5 w-5 text-grey-600" {...tooltipDataAttr} />
        </>
      ) : null}
    </div>
  );
};
